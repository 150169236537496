var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "media align-items-center" },
    [
      _c("app-avatar", {
        attrs: {
          title: _vm.mediaTitle,
          "avatar-class": "avatars-w-50",
          img: _vm.imgUrl,
          "alt-text": _vm.altText,
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "media-body ml-3" }, [
        _vm._v("\n        " + _vm._s(_vm.mediaTitle) + "\n        "),
        _c("p", { staticClass: "text-muted font-size-90 mb-0" }, [
          _vm._v(_vm._s(_vm.mediaSubtitle)),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }