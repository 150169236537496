var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "select",
    _vm._g(
      {
        ref: "select",
        class: "custom-select" + _vm.data.inputClass,
        style: "backgroundImage: url(" + _vm.downArrow() + ")",
        attrs: { id: _vm.inputFieldId, disabled: _vm.data.disabled },
        domProps: { value: _vm.value },
      },
      _vm.listeners
    ),
    _vm._l(_vm.data.list, function (item) {
      return _c(
        "option",
        {
          key: item.id,
          attrs: { disabled: item.disabled },
          domProps: { value: item.id, selected: item.id === _vm.value },
        },
        [
          _vm._v(
            "\n        " + _vm._s(item[_vm.data.listValueField]) + "\n    "
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }