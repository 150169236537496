var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.preloader
        ? _c("app-overlay-loader")
        : _c("div", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12 col-md-6 col-lg-3" }, [
                _c("div", { staticClass: "card card-with-shadow border-0" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-body d-flex justifycontent-center align-items-center",
                    },
                    [
                      _c("div", { staticClass: "text-center w-100" }, [
                        _c("div", { staticClass: "text-muted" }, [
                          _vm._v(_vm._s(_vm.$t("new_candidates_this_week"))),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "h1" }, [
                          _vm._v(_vm._s(_vm.overview.new_candidates)),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-md-6 col-lg-3" }, [
                _c("div", { staticClass: "card card-with-shadow border-0" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-body d-flex justifycontent-center align-items-center",
                    },
                    [
                      _c("div", { staticClass: "text-center w-100" }, [
                        _c("div", { staticClass: "text-muted" }, [
                          _vm._v(_vm._s(_vm.$t("move_forward_this_week"))),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "h1" }, [
                          _vm._v(_vm._s(_vm.overview.moved_forward)),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-md-6 col-lg-3" }, [
                _c("div", { staticClass: "card card-with-shadow border-0" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-body d-flex justifycontent-center align-items-center",
                    },
                    [
                      _c("div", { staticClass: "text-center w-100" }, [
                        _c("div", { staticClass: "text-muted" }, [
                          _vm._v(
                            _vm._s(_vm.$t("total_candidates_hired")) +
                              " (" +
                              _vm._s(_vm.$t("all_time")) +
                              ")"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "h1" }, [
                          _vm._v(_vm._s(_vm.overview.hired)),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-md-6 col-lg-3" }, [
                _c("div", { staticClass: "card card-with-shadow border-0" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-body d-flex justifycontent-center align-items-center",
                    },
                    [
                      _c("div", { staticClass: "text-center w-100" }, [
                        _c("div", { staticClass: "text-muted" }, [
                          _vm._v(_vm._s(_vm.$t("active_jobs"))),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "h1" }, [
                          _vm._v(_vm._s(_vm.overview.active_jobs)),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row mt-primary" }, [
              _c("div", { staticClass: "col" }, [
                _c("div", { staticClass: "card card-with-shadow border-0" }, [
                  _c(
                    "div",
                    { staticClass: "card-body" },
                    [
                      _c("h4", [
                        _vm._v(_vm._s(_vm.$t("performance_overview"))),
                      ]),
                      _vm._v(" "),
                      _c("app-chart", {
                        staticClass: "mb-primary",
                        attrs: {
                          type: "line-chart",
                          height: 230,
                          labels: _vm.performance.labels,
                          "data-sets": _vm.performance.dataSet,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row mt-primary" }, [
              _c("div", { staticClass: "col-12 col-md-6" }, [
                _c("div", { staticClass: "card card-with-shadow border-0" }, [
                  _c(
                    "div",
                    { staticClass: "card-body" },
                    [
                      _c("h4", [
                        _vm._v(_vm._s(_vm.$t("top_candidates_source"))),
                      ]),
                      _vm._v(" "),
                      _c("app-chart", {
                        staticClass: "mb-primary",
                        attrs: {
                          type: "bar-chart",
                          height: 230,
                          labels: _vm.topCandidates.labels,
                          "data-sets": _vm.topCandidates.dataSet,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-md-6" }, [
                _c("div", { staticClass: "card card-with-shadow border-0" }, [
                  _c(
                    "div",
                    { staticClass: "card-body" },
                    [
                      _c("h4", [
                        _vm._v(_vm._s(_vm.$t("new_candidates_by_source"))),
                      ]),
                      _vm._v(" "),
                      _c("app-chart", {
                        staticClass: "mb-primary",
                        attrs: {
                          type: "dough-chart",
                          height: 230,
                          labels: _vm.newCandidates.labels,
                          "data-sets": _vm.newCandidates.dataSet,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }