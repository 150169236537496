var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.icon() && _vm.icon().iconType === "fontawesome"
    ? _c("i", { class: _vm.icon().iconName })
    : _c("i", { attrs: { "data-feather": _vm.name } })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }