var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("app-breadcrumb", { attrs: { "page-title": _vm.$t("feeds") } }),
      _vm._v(" "),
      _c("div", { staticClass: "row pt-3 p-md-3 justify-content-center" }, [
        _c("div", { staticClass: "col-12 col-md-8" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 col-lg-9" }, [
              _c(
                "div",
                { staticClass: "d-flex flex-column gap-y-1" },
                [
                  !_vm.readonly
                    ? _c("new-post", { attrs: { nofetch: "" } })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "d-flex flex-column gap-y-1" },
                    [
                      _vm._l(_vm.posts, function (post, index) {
                        return _c("post-view", {
                          key: `post-items-${post.id}`,
                          attrs: {
                            post: post,
                            preloader: _vm.preloader,
                            recent: post.recent,
                          },
                          on: {
                            edit: _vm.edit,
                            del: _vm.del,
                            report: _vm.reportModalOpen,
                          },
                        })
                      }),
                      _vm._v(" "),
                      _vm.loading ? _c("app-pre-loader") : _vm._e(),
                      _vm._v(" "),
                      !_vm.posts.length && !_vm.loading
                        ? _c("app-empty-data-block", {
                            attrs: { message: _vm.$t("empty_data_block_post") },
                          })
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "col-12 d-none d-lg-flex flex-column gap-y-1 col-lg-3 p-lg-0",
              },
              [
                _c("profile-widget"),
                _vm._v(" "),
                _c("teammate-widget"),
                _vm._v(" "),
                _c("notification-widget"),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }