var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content-wrapper" }, [
    _c(
      "div",
      {
        staticClass: "position-relative",
        class: { "loading-opacity": _vm.preloader },
      },
      [
        _vm.preloader ? _c("app-overlay-loader") : _vm._e(),
        _vm._v(" "),
        !_vm.isAddTemplate
          ? _c("template-list", {
              attrs: {
                list: _vm.templateList,
                "name-key": "template_name",
                actions: _vm.actions,
              },
              on: { "add-new": _vm.addNew },
            })
          : _c("add-edit-form", {
              attrs: { selectedTemplate: _vm.selectedTemplate },
              on: { success: _vm.afterSuccess, cancel: _vm.cancelAddEditForm },
            }),
        _vm._v(" "),
        _vm.isDeleteConfirmationModal
          ? _c("app-confirmation-modal", {
              attrs: {
                icon: "trash-2",
                "modal-id": "team-delete-confirmation-modal",
              },
              on: { confirmed: _vm.deleteTemplate, cancelled: _vm.close },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }