var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "position-relative" }, [
    _vm.countCreatedResponse < 3
      ? _c(
          "div",
          {
            staticClass:
              "root-preloader position-absolute overlay-loader-wrapper",
          },
          [_vm._m(0)]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content-wrapper" },
      [
        _c("app-breadcrumb", {
          attrs: {
            "page-title": _vm.$t("hospital"),
            directory: _vm.$t("dashboard"),
            icon: "pie-chart",
          },
        }),
        _vm._v(" "),
        !_vm.isActivedDefaultInfo && !_vm.mainPreloader
          ? _c("app-overlay-loader")
          : _vm._e(),
        _vm._v(" "),
        _vm.isActivedDefaultInfo
          ? _c(
              "div",
              { staticClass: "row" },
              _vm._l(_vm.hospital.defaultData, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "col-12 col-sm-6 col-xl-3" },
                  [
                    _c("app-widget", {
                      staticClass: "mb-primary",
                      attrs: {
                        type: "app-widget-with-icon",
                        label: item.label,
                        number: _vm.numberFormat(item.number),
                        icon: item.icon,
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12 col-md-9 mb-primary" },
            [
              !_vm.isActiveHospitalActivity && !_vm.mainPreloader
                ? _c("app-overlay-loader")
                : _vm._e(),
              _vm._v(" "),
              _vm.isActiveHospitalActivity
                ? _c(
                    "div",
                    { staticClass: "card card-with-shadow border-0 h-100" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "card-header bg-transparent p-primary d-flex justify-content-between align-items-center",
                        },
                        [
                          _c("h5", { staticClass: "card-title mb-0" }, [
                            _vm._v(_vm._s(_vm.$t("hospital_activity"))),
                          ]),
                          _vm._v(" "),
                          _c(
                            "ul",
                            {
                              staticClass:
                                "nav tab-filter-menu justify-content-flex-end",
                            },
                            _vm._l(
                              _vm.hospital.hospitalActivities
                                .chartFilterOptions,
                              function (item, index) {
                                return _c(
                                  "li",
                                  { key: index, staticClass: "nav-item" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "nav-link py-0",
                                        class: [
                                          _vm.value == item.id
                                            ? "active"
                                            : index === 0 && _vm.value === ""
                                            ? "active"
                                            : "",
                                        ],
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.getFilterValue(item.id)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(item.value) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "card-body" },
                        [
                          _c("app-chart", {
                            staticClass: "mb-primary",
                            attrs: {
                              type: "line-chart",
                              height: 270,
                              labels: _vm.hospital.hospitalActivities.labels,
                              "data-sets":
                                _vm.hospital.hospitalActivities.chartData,
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "chart-data-list d-flex flex-wrap justify-content-center",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "data-group-item",
                                  staticStyle: { color: "rgb(240, 84, 84)" },
                                },
                                [
                                  _c("span", {
                                    staticClass: "square",
                                    staticStyle: {
                                      "background-color": "rgb(240, 84, 84)",
                                    },
                                  }),
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.$t("consultation")) +
                                      "\n                                "
                                  ),
                                  _c("span", { staticClass: "value" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.hospital.hospitalActivities
                                          .consultation
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "data-group-item",
                                  staticStyle: { color: "rgb(14, 73, 181)" },
                                },
                                [
                                  _c("span", {
                                    staticClass: "square",
                                    staticStyle: {
                                      "background-color": "rgb(14, 73, 181)",
                                    },
                                  }),
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.$t("patients")) +
                                      "\n                                "
                                  ),
                                  _c("span", { staticClass: "value" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.hospital.hospitalActivities.patients
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12 col-md-3 mb-primary" },
            [
              !_vm.isActivePatientStatistics && !_vm.mainPreloader
                ? _c("app-overlay-loader")
                : _vm._e(),
              _vm._v(" "),
              _vm.isActivePatientStatistics
                ? _c(
                    "div",
                    { staticClass: "card card-with-shadow border-0 h-100" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "card-header bg-transparent p-primary d-flex justify-content-between align-items-center",
                        },
                        [
                          _c("h5", { staticClass: "card-title mb-0" }, [
                            _vm._v(_vm._s(_vm.$t("patient_by_gender"))),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "card-body" },
                        [
                          _c("app-chart", {
                            staticClass: "mb-primary",
                            attrs: {
                              type: "pie-chart",
                              height: 230,
                              labels: _vm.hospital.patientStatistics.labels,
                              "data-sets":
                                _vm.hospital.patientStatistics.dataSet,
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "chart-data-list" }, [
                            _c(
                              "div",
                              { staticClass: "row" },
                              _vm._l(
                                _vm.hospital.patientStatistics.chartElement,
                                function (item, index) {
                                  return _c("div", { staticClass: "col-12" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "data-group-item",
                                        style: item.color,
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "square",
                                          style: item.background_color,
                                        }),
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(item.key) +
                                            "\n                                        "
                                        ),
                                        _c("span", { staticClass: "value" }, [
                                          _vm._v(_vm._s(item.value)),
                                        ]),
                                      ]
                                    ),
                                  ])
                                }
                              ),
                              0
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        !_vm.mainPreloader
          ? _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12 col-sm-12 col-md-6 mb-4 mb-md-0" },
                [
                  _c("div", { staticClass: "card card-with-shadow border-0" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-header d-flex align-items-center justify-content-between p-primary primary-card-color",
                      },
                      [
                        _c(
                          "h5",
                          { staticClass: "card-title d-inline-block mb-0" },
                          [_vm._v(_vm._s(_vm.$t("doctors_list")))]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "card-body p-0" },
                      [
                        _c("app-table", {
                          attrs: {
                            id: "test-table-profile",
                            options: _vm.availableDoctors,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-12 col-md-6" }, [
                _c("div", { staticClass: "card card-with-shadow border-0" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-header d-flex align-items-center justify-content-between p-primary primary-card-color",
                    },
                    [
                      _c(
                        "h5",
                        { staticClass: "card-title d-inline-block mb-0" },
                        [_vm._v(_vm._s(_vm.$t("upcoming_appointments")))]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "card-body p-0" },
                    [
                      _c("app-table", {
                        attrs: {
                          id: "test-table-profile",
                          options: _vm.appointmentList,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "spinner-bounce d-flex align-items-center dashboard-preloader justify-content-center",
      },
      [
        _c("span", { staticClass: "bounce1 mr-1" }),
        _vm._v(" "),
        _c("span", { staticClass: "bounce2 mr-1" }),
        _vm._v(" "),
        _c("span", { staticClass: "bounce3 mr-1" }),
        _vm._v(" "),
        _c("span", { staticClass: "bounce4" }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }