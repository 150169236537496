var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "btn btn-link text-left px-0 w-100",
      class: _vm.isActive(_vm.url),
      attrs: { href: _vm.url, target: _vm.target },
    },
    [
      _c(
        "div",
        { staticClass: "d-flex align-items-center gap-x-1" },
        [
          _vm.icon
            ? _c("app-icon", { attrs: { name: _vm.icon, size: _vm.fontSize } })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "text-capitalize",
              style: `font-size: ${_vm.fontSize}px !important`,
            },
            [_vm._v(_vm._s(_vm.title))]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }