var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.type === "vertical"
    ? _c("vertical-tab", {
        attrs: {
          tabs: _vm.tabs,
          icon: _vm.icon,
          "query-string": _vm.queryString,
          elevated: _vm.elevated,
        },
      })
    : _c("horizontal-tab", {
        attrs: {
          tabs: _vm.tabs,
          "query-string": _vm.queryString,
          bodyClass: _vm.bodyClass,
          tabClass: _vm.tabClass,
          queryStringKey: _vm.tabName,
          elevated: _vm.elevated,
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }