var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-wrapper p-4" },
    [
      _c("app-breadcrumb", {
        attrs: {
          "page-title": _vm.$t("nothing_to_show"),
          directory: _vm.$t("ui_elements"),
          icon: "trello",
        },
      }),
      _vm._v(" "),
      _c("app-note", {
        staticClass: "mb-4",
        attrs: { title: "Note", notes: _vm.notFoundInstruction },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            { staticClass: "card card-with-search border-0 card-with-shadow" },
            [
              _c("div", { staticClass: "card-body" }, [
                _c(
                  "div",
                  {
                    staticClass: "cursor-pointer",
                    attrs: {
                      "data-toggle": "tooltip",
                      "data-placement": "top",
                      title: _vm.$t("click_to_copy"),
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.copyNothingToShow.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("app-empty-data-block", {
                      attrs: { message: _vm.$t("nothing_to_show_message") },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "textarea",
          {
            staticClass: "d-none",
            attrs: {
              type: "text",
              id: "copy-nothing-to-show",
              readonly: "readonly",
            },
          },
          [
            _vm._v(
              "            " + _vm._s(_vm.nothingToShowValue) + "\n        "
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }