var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "--content-wrapper" }, [
    _c("div", { staticClass: "card card-with-shadow border-0 h-100" }, [
      _c(
        "div",
        {
          staticClass:
            "card-header bg-transparent d-flex justify-content-end align-items-center p-primary",
        },
        [
          _c("p", { staticClass: "my-0 mr-2 p-0" }, [
            _vm._v(_vm._s(_vm.$t("order_report_by"))),
          ]),
          _vm._v(" "),
          _c("app-input", {
            attrs: { type: "radio-buttons", list: _vm.unitList },
            model: {
              value: _vm.reportUnit,
              callback: function ($$v) {
                _vm.reportUnit = $$v
              },
              expression: "reportUnit",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-body pt-primary" },
        [
          _c(
            "div",
            {
              staticClass: "chart-container position-relative min-height-380",
              class: { "loading-opacity": _vm.preloader },
            },
            [
              _vm.preloader
                ? _c("app-overlay-loader")
                : _c("app-chart", {
                    attrs: {
                      type: "horizontal-line-chart",
                      height: 380,
                      labels: _vm.reportChart.labels,
                      "data-sets": _vm.reportChart.dataSets,
                    },
                  }),
            ],
            1
          ),
          _vm._v(" "),
          _c("hr", { staticClass: "mx-minus-primary my-primary" }),
          _vm._v(" "),
          _c("app-table", {
            staticClass: "remove-datatable-x-padding",
            attrs: { id: _vm.reportTableId, options: _vm.options },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }