var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        "modal-id": _vm.modalId,
        title: _vm.modalTitle,
        preloader: _vm.preloader,
      },
      on: { submit: _vm.submit, "close-modal": _vm.closeModal },
    },
    [
      _c(
        "template",
        { slot: "body" },
        [
          _vm.preloader ? _c("app-overlay-loader") : _vm._e(),
          _vm._v(" "),
          _c(
            "form",
            {
              ref: "form",
              staticClass: "mb-0",
              class: { "loading-opacity": _vm.preloader },
              attrs: {
                "data-url": _vm.selectedUrl ? `crud/${_vm.inputs.id}` : `crud`,
              },
            },
            [
              _c(
                "div",
                { staticClass: "form-group row align-items-center" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 mb-0",
                      attrs: { for: "inputs_name" },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("name")) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("app-input", {
                    staticClass: "col-sm-9",
                    attrs: {
                      id: "inputs_name",
                      type: "text",
                      placeholder: _vm.$t("text_type_input"),
                      required: true,
                    },
                    model: {
                      value: _vm.inputs.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "name", $$v)
                      },
                      expression: "inputs.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group row align-items-center" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 mb-0",
                      attrs: { for: "inputs_email" },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("email")) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("app-input", {
                    staticClass: "col-sm-9",
                    attrs: {
                      id: "inputs_email",
                      type: "email",
                      placeholder: _vm.$t("email_type_input"),
                      required: true,
                    },
                    model: {
                      value: _vm.inputs.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "email", $$v)
                      },
                      expression: "inputs.email",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group row align-items-center" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 mb-0",
                      attrs: { for: "inputs_phone" },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("contact_number")) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("app-input", {
                    staticClass: "col-sm-9",
                    attrs: {
                      id: "inputs_phone",
                      type: "tel-input",
                      placeholder: _vm.$t("type_contact_number"),
                    },
                    model: {
                      value: _vm.phone,
                      callback: function ($$v) {
                        _vm.phone = $$v
                      },
                      expression: "phone",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group row align-items-center" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 mb-0",
                      attrs: { for: "inputs_status" },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("status")) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("app-input", {
                    staticClass: "col-sm-9",
                    attrs: {
                      id: "inputs_status",
                      type: "select",
                      required: true,
                      list: _vm.statusLists,
                    },
                    model: {
                      value: _vm.inputs.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "status", $$v)
                      },
                      expression: "inputs.status",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group row align-items-center" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 mb-0",
                      attrs: { for: "inputs_age" },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("age")) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("app-input", {
                    staticClass: "col-sm-9",
                    attrs: {
                      id: "inputs_age",
                      type: "number",
                      "max-number": 100,
                      placeholder: _vm.$t("type_age"),
                    },
                    model: {
                      value: _vm.inputs.age,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "age", $$v)
                      },
                      expression: "inputs.age",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group row align-items-center mb-0" },
                [
                  _c("label", { staticClass: "col-sm-3 mb-0" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$t("gender")) +
                        "\n                "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("app-input", {
                    staticClass: "col-sm-9",
                    attrs: { type: "radio", list: _vm.genderLists },
                    model: {
                      value: _vm.inputs.gender,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "gender", $$v)
                      },
                      expression: "inputs.gender",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }