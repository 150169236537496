var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("app-breadcrumb", {
        attrs: {
          "page-title": _vm.$t("form_layouts"),
          directory: _vm.$t("forms_and_fields"),
          icon: "sidebar",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("form-grid"),
          _vm._v(" "),
          _c("form-row"),
          _vm._v(" "),
          _c("form-horizontal"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }