var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("app-breadcrumb", {
        attrs: {
          "page-title": _vm.$t("modals"),
          directory: _vm.$t("ui_elements"),
          icon: "trello",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "card card-with-shadow border-0" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header p-primary bg-transparent d-flex justify-content-between align-items-center",
          },
          [
            _c("h5", { staticClass: "card-title m-0" }, [
              _vm._v(_vm._s(_vm.$t("centered_modal"))),
            ]),
            _vm._v(" "),
            _c("copy-button", {
              attrs: { id: "centered-modal", value: _vm.centeredModalValue },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card-body d-flex justify-content-start" },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-primary mr-2",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    _vm.smallModalCentered = true
                  },
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("small")) +
                    "\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _vm.smallModalCentered
              ? _c(
                  "app-modal",
                  {
                    attrs: {
                      "modal-id": "small-modal-centered",
                      "modal-alignment": "center",
                      "modal-size": "small",
                    },
                    on: { "close-modal": _vm.closeModal },
                  },
                  [
                    _c("template", { slot: "header" }, [
                      _c("h5", { staticClass: "modal-title" }, [
                        _vm._v(_vm._s(_vm.$t("small"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "close outline-none",
                          attrs: {
                            type: "button",
                            "data-dismiss": "modal",
                            "aria-label": "Close",
                          },
                        },
                        [
                          _c(
                            "span",
                            [_c("app-icon", { attrs: { name: "x" } })],
                            1
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "body" }, [
                      _c("p", { staticClass: "text-justify" }, [
                        _vm._v(_vm._s(_vm.$t("modal_content_2"))),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "footer" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary mr-2",
                          attrs: { type: "button", "data-dismiss": "modal" },
                          on: { click: _vm.closeModal },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$t("close")) +
                              "\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "button" },
                        },
                        [_vm._v(_vm._s(_vm.$t("save_changes")))]
                      ),
                    ]),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary mr-2",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    _vm.defaultModalCentered = true
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("medium")) + "\n            ")]
            ),
            _vm._v(" "),
            _vm.defaultModalCentered
              ? _c(
                  "app-modal",
                  {
                    attrs: {
                      "modal-id": "default-modal-centered",
                      "modal-alignment": "center",
                      "modal-size": "default",
                    },
                    on: { "close-modal": _vm.closeModal },
                  },
                  [
                    _c("template", { slot: "header" }, [
                      _c("h5", { staticClass: "modal-title" }, [
                        _vm._v(_vm._s(_vm.$t("medium"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "close outline-none",
                          attrs: {
                            type: "button",
                            "data-dismiss": "modal",
                            "aria-label": "Close",
                          },
                        },
                        [
                          _c(
                            "span",
                            [_c("app-icon", { attrs: { name: "x" } })],
                            1
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "body" }, [
                      _c("p", { staticClass: "text-justify" }, [
                        _vm._v(_vm._s(_vm.$t("modal_content"))),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "footer" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary mr-2",
                          attrs: { type: "button", "data-dismiss": "modal" },
                          on: { click: _vm.closeModal },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$t("close")) +
                              "\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "button" },
                        },
                        [_vm._v(_vm._s(_vm.$t("save_changes")))]
                      ),
                    ]),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary mr-2",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    _vm.largeModalCentered = true
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("large")) + "\n            ")]
            ),
            _vm._v(" "),
            _vm.largeModalCentered
              ? _c(
                  "app-modal",
                  {
                    attrs: {
                      "modal-id": "large-modal-centered",
                      "modal-alignment": "center",
                      "modal-size": "large",
                    },
                    on: { "close-modal": _vm.closeModal },
                  },
                  [
                    _c("template", { slot: "header" }, [
                      _c("h5", { staticClass: "modal-title" }, [
                        _vm._v(_vm._s(_vm.$t("large"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "close outline-none",
                          attrs: {
                            type: "button",
                            "data-dismiss": "modal",
                            "aria-label": "Close",
                          },
                        },
                        [
                          _c(
                            "span",
                            [_c("app-icon", { attrs: { name: "x" } })],
                            1
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "body" }, [
                      _c("p", { staticClass: "text-justify" }, [
                        _vm._v(_vm._s(_vm.$t("modal_content"))),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "footer" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary mr-2",
                          attrs: { type: "button", "data-dismiss": "modal" },
                          on: { click: _vm.closeModal },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$t("close")) +
                              "\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "button" },
                        },
                        [_vm._v(_vm._s(_vm.$t("save_changes")))]
                      ),
                    ]),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary mr-2",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    _vm.xlModalCentered = true
                  },
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("extra_large")) +
                    "\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _vm.xlModalCentered
              ? _c(
                  "app-modal",
                  {
                    attrs: {
                      "modal-id": "xl-modal-centered",
                      "modal-alignment": "center",
                      "modal-size": "extra-large",
                    },
                    on: { "close-modal": _vm.closeModal },
                  },
                  [
                    _c("template", { slot: "header" }, [
                      _c("h5", { staticClass: "modal-title" }, [
                        _vm._v(_vm._s(_vm.$t("extra_large"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "close outline-none",
                          attrs: {
                            type: "button",
                            "data-dismiss": "modal",
                            "aria-label": "Close",
                          },
                        },
                        [
                          _c(
                            "span",
                            [_c("app-icon", { attrs: { name: "x" } })],
                            1
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "body" }, [
                      _c("p", { staticClass: "text-justify" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$t("modal_content")) +
                            "\n                    "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "footer" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary mr-2",
                          attrs: { type: "button", "data-dismiss": "modal" },
                          on: { click: _vm.closeModal },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$t("close")) +
                              "\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "button" },
                        },
                        [_vm._v(_vm._s(_vm.$t("save_changes")))]
                      ),
                    ]),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary mr-2",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    _vm.fullWidthModalCentered = true
                  },
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("full_width")) +
                    "\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _vm.fullWidthModalCentered
              ? _c(
                  "app-modal",
                  {
                    attrs: {
                      "modal-id": "full-width-modal-centered",
                      "modal-alignment": "center",
                      "modal-size": "full-width",
                    },
                    on: { "close-modal": _vm.closeModal },
                  },
                  [
                    _c("template", { slot: "header" }, [
                      _c("h5", { staticClass: "modal-title" }, [
                        _vm._v(_vm._s(_vm.$t("full_width"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "close outline-none",
                          attrs: {
                            type: "button",
                            "data-dismiss": "modal",
                            "aria-label": "Close",
                          },
                        },
                        [
                          _c(
                            "span",
                            [_c("app-icon", { attrs: { name: "x" } })],
                            1
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "body" }, [
                      _c("p", { staticClass: "text-justify" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$t("modal_content")) +
                            "\n                    "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "footer" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary mr-2",
                          attrs: { type: "button", "data-dismiss": "modal" },
                          on: { click: _vm.closeModal },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$t("close")) +
                              "\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "button" },
                        },
                        [_vm._v(_vm._s(_vm.$t("save_changes")))]
                      ),
                    ]),
                  ],
                  2
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card mt-4 card-with-shadow border-0" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header p-primary bg-transparent d-flex justify-content-between align-items-center",
          },
          [
            _c("h5", { staticClass: "card-title m-0" }, [
              _vm._v(_vm._s(_vm.$t("top_centered_modal"))),
            ]),
            _vm._v(" "),
            _c("copy-button", {
              attrs: {
                id: "top-centered-modal",
                value: _vm.topCenteredModalValue,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card-body d-flex justify-content-start" },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-primary mr-2",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    _vm.smallModal = true
                  },
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("small")) +
                    "\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _vm.smallModal
              ? _c(
                  "app-modal",
                  {
                    attrs: {
                      "modal-id": "small-modal",
                      "modal-size": "small",
                      "modal-alignment": "top",
                    },
                    on: { "close-modal": _vm.closeModal },
                  },
                  [
                    _c("template", { slot: "header" }, [
                      _c("h5", { staticClass: "modal-title" }, [
                        _vm._v(_vm._s(_vm.$t("small"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "close outline-none",
                          attrs: {
                            type: "button",
                            "data-dismiss": "modal",
                            "aria-label": "Close",
                          },
                        },
                        [
                          _c(
                            "span",
                            [_c("app-icon", { attrs: { name: "x" } })],
                            1
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "body" }, [
                      _c("p", { staticClass: "text-justify" }, [
                        _vm._v(_vm._s(_vm.$t("modal_content_2"))),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "footer" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary mr-2",
                          attrs: { type: "button", "data-dismiss": "modal" },
                          on: { click: _vm.closeModal },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$t("close")) +
                              "\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "button" },
                        },
                        [_vm._v(_vm._s(_vm.$t("save_changes")))]
                      ),
                    ]),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary mr-2",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    _vm.defaultModal = true
                  },
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("medium")) +
                    "\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _vm.defaultModal
              ? _c(
                  "app-modal",
                  {
                    attrs: {
                      "modal-id": "default-modal",
                      "modal-size": "default",
                      "modal-alignment": "top",
                    },
                    on: { "close-modal": _vm.closeModal },
                  },
                  [
                    _c("template", { slot: "header" }, [
                      _c("h5", { staticClass: "modal-title" }, [
                        _vm._v(_vm._s(_vm.$t("medium"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "close outline-none",
                          attrs: {
                            type: "button",
                            "data-dismiss": "modal",
                            "aria-label": "Close",
                          },
                        },
                        [
                          _c(
                            "span",
                            [_c("app-icon", { attrs: { name: "x" } })],
                            1
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "body" }, [
                      _c("p", { staticClass: "text-justify" }, [
                        _vm._v(_vm._s(_vm.$t("modal_content"))),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "footer" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary mr-2",
                          attrs: { type: "button", "data-dismiss": "modal" },
                          on: { click: _vm.closeModal },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$t("close")) +
                              "\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "button" },
                        },
                        [_vm._v(_vm._s(_vm.$t("save_changes")))]
                      ),
                    ]),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary mr-2",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    _vm.largeModal = true
                  },
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("large")) +
                    "\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _vm.largeModal
              ? _c(
                  "app-modal",
                  {
                    attrs: {
                      "modal-id": "large-modal",
                      "modal-size": "large",
                      "modal-alignment": "top",
                    },
                    on: { "close-modal": _vm.closeModal },
                  },
                  [
                    _c("template", { slot: "header" }, [
                      _c("h5", { staticClass: "modal-title" }, [
                        _vm._v(_vm._s(_vm.$t("large"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "close outline-none",
                          attrs: {
                            type: "button",
                            "data-dismiss": "modal",
                            "aria-label": "Close",
                          },
                        },
                        [
                          _c(
                            "span",
                            [_c("app-icon", { attrs: { name: "x" } })],
                            1
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "body" }, [
                      _c("p", { staticClass: "text-justify" }, [
                        _vm._v(_vm._s(_vm.$t("modal_content"))),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "footer" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary mr-2",
                          attrs: { type: "button", "data-dismiss": "modal" },
                          on: { click: _vm.closeModal },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$t("close")) +
                              "\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "button" },
                        },
                        [_vm._v(_vm._s(_vm.$t("save_changes")))]
                      ),
                    ]),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary mr-2",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    _vm.xlModal = true
                  },
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("extra_large")) +
                    "\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _vm.xlModal
              ? _c(
                  "app-modal",
                  {
                    attrs: {
                      "modal-id": "xl-modal",
                      "modal-size": "extra-large",
                      "modal-alignment": "top",
                    },
                    on: { "close-modal": _vm.closeModal },
                  },
                  [
                    _c("template", { slot: "header" }, [
                      _c("h5", { staticClass: "modal-title" }, [
                        _vm._v(_vm._s(_vm.$t("extra_large"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "close outline-none",
                          attrs: {
                            type: "button",
                            "data-dismiss": "modal",
                            "aria-label": "Close",
                          },
                        },
                        [
                          _c(
                            "span",
                            [_c("app-icon", { attrs: { name: "x" } })],
                            1
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "body" }, [
                      _c("p", { staticClass: "text-justify" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$t("modal_content")) +
                            "\n                    "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "footer" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary mr-2",
                          attrs: { type: "button", "data-dismiss": "modal" },
                          on: { click: _vm.closeModal },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$t("close")) +
                              "\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "button" },
                        },
                        [_vm._v(_vm._s(_vm.$t("save_changes")))]
                      ),
                    ]),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary mr-2",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    _vm.fullHeight = true
                  },
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("full_height")) +
                    "\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _vm.fullHeight
              ? _c(
                  "app-modal",
                  {
                    attrs: {
                      "modal-id": "full-height-modal",
                      "modal-size": "default",
                      "modal-alignment": "top",
                    },
                    on: { "close-modal": _vm.closeModal },
                  },
                  [
                    _c("template", { slot: "header" }, [
                      _c("h5", { staticClass: "modal-title" }, [
                        _vm._v(_vm._s(_vm.$t("full_height"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "close outline-none",
                          attrs: {
                            type: "button",
                            "data-dismiss": "modal",
                            "aria-label": "Close",
                          },
                        },
                        [
                          _c(
                            "span",
                            [_c("app-icon", { attrs: { name: "x" } })],
                            1
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "body" }, [
                      _c("p", { staticClass: "text-justify" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.$t("modal_content") +
                                _vm.$t("modal_content") +
                                _vm.$t("modal_content")
                            ) +
                            "\n                    "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "footer" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary mr-2",
                          attrs: { type: "button", "data-dismiss": "modal" },
                          on: { click: _vm.closeModal },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$t("close")) +
                              "\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "button" },
                        },
                        [_vm._v(_vm._s(_vm.$t("save_changes")))]
                      ),
                    ]),
                  ],
                  2
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card mt-4 card-with-shadow border-0" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header p-primary bg-transparent d-flex justify-content-between align-items-center",
          },
          [
            _c("h5", { staticClass: "card-title m-0" }, [
              _vm._v(_vm._s(_vm.$t("special_modal"))),
            ]),
            _vm._v(" "),
            _c("copy-button", {
              attrs: { id: "special-modal", value: _vm.specialModalValue },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card-body d-flex justify-content-start" },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-primary mr-2",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    _vm.fullScreen = true
                  },
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("full_screen")) +
                    "\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _vm.fullScreen
              ? _c(
                  "app-modal",
                  {
                    attrs: {
                      "modal-id": "full-screen-modal",
                      "modal-size": "fullscreen",
                    },
                    on: { "close-modal": _vm.closeModal },
                  },
                  [
                    _c("template", { slot: "header" }, [
                      _c("h5", { staticClass: "modal-title" }, [
                        _vm._v(_vm._s(_vm.$t("full_screen"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "close outline-none",
                          attrs: {
                            type: "button",
                            "data-dismiss": "modal",
                            "aria-label": "Close",
                          },
                        },
                        [
                          _c(
                            "span",
                            [_c("app-icon", { attrs: { name: "x" } })],
                            1
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "body" }, [
                      _c("p", { staticClass: "text-justify" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$t("modal_content")) +
                            "\n                    "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "footer" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary mr-2",
                          attrs: { type: "button", "data-dismiss": "modal" },
                          on: { click: _vm.closeModal },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$t("close")) +
                              "\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "button" },
                        },
                        [_vm._v(_vm._s(_vm.$t("save_changes")))]
                      ),
                    ]),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-success mr-2",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    _vm.confirmedModal = true
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("confirm")) + "\n            ")]
            ),
            _vm._v(" "),
            _vm.confirmedModal
              ? _c(
                  "app-modal",
                  {
                    attrs: {
                      "modal-id": "confirm-modal",
                      "modal-alignment": "center",
                      "modal-size": "md",
                    },
                    on: { "close-modal": _vm.closeModal },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "modal-body d-flex flex-column justify-content-center modal-alert",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "text-center" },
                          [
                            _c("app-icon", {
                              staticClass: "text-success text-center",
                              attrs: { name: "check-circle" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "h5",
                          { staticClass: "text-center font-weight-bold mt-4" },
                          [_vm._v(_vm._s(_vm.$t("congratulation")))]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass:
                              "text-center mb-primary p-0 font-size-90",
                          },
                          [_vm._v(_vm._s(_vm.$t("confirm_text")))]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "text-center" }, [
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-success",
                              attrs: { href: "#", "data-dismiss": "modal" },
                              on: { click: _vm.closeModal },
                            },
                            [_vm._v(_vm._s(_vm.$t("continue")))]
                          ),
                        ]),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-warning mr-2",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    _vm.alertModal = true
                  },
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("alert")) +
                    "\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _vm.alertModal
              ? _c(
                  "app-modal",
                  {
                    attrs: {
                      "modal-id": "alert-modal",
                      "modal-alignment": "center",
                      "modal-size": "md",
                    },
                    on: { "close-modal": _vm.closeModal },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "modal-body d-flex flex-column justify-content-center modal-alert",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "text-center" },
                          [
                            _c("app-icon", {
                              staticClass: "text-warning",
                              attrs: { name: "alert-triangle" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "h5",
                          { staticClass: "text-center font-weight-bold mt-4" },
                          [_vm._v(_vm._s(_vm.$t("alert") + "!"))]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass:
                              "text-center mb-primary p-0 font-size-90",
                          },
                          [_vm._v(_vm._s(_vm.$t("alert_text")))]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "text-center" }, [
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-warning",
                              attrs: { href: "#", "data-dismiss": "modal" },
                              on: { click: _vm.closeModal },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("continue")) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-danger mr-2",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    _vm.deleteModal = true
                  },
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("delete")) +
                    "\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _vm.deleteModal
              ? _c("app-confirmation-modal", {
                  attrs: { "modal-id": "delete" },
                  on: { confirmed: _vm.confirmed, cancelled: _vm.cancelled },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }