var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card rounded-lg", staticStyle: { overflow: "hidden" } },
    [
      _c("div", { staticClass: "card-header" }, [
        _c(
          "div",
          { staticClass: "d-flex justify-content-between align-items-center" },
          [
            _c("div", [_vm._v(_vm._s(_vm.$t("friends")))]),
            _vm._v(" "),
            _c("div", {}, [_vm._v(_vm._s(_vm.teammates.length))]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-body p-0" },
        [
          _vm.teammatesLoading
            ? _c("app-pre-loader")
            : [
                _c(
                  "div",
                  {
                    staticClass: "d-flex flex-column scroll-y custom-scrollbar",
                    staticStyle: { "max-height": "380px" },
                  },
                  [
                    _vm._l(_vm.teammates, function (mate, index) {
                      return _c(
                        "a",
                        {
                          key: `teammates-item-${index}`,
                          staticClass:
                            "d-flex align-items-center gap-x-1 py-2 px-3 text-dark teammate",
                          attrs: { href: _vm.urlGenerator("social-profile/") },
                        },
                        [
                          _c("img", {
                            staticClass: "rounded-circle",
                            attrs: {
                              width: "30",
                              height: "30",
                              src:
                                mate?.profile_picture?.full_url ||
                                _vm.urlGenerator("/images/avatar-demo.jpg"),
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "text-left" }, [
                            _vm._v(_vm._s(mate.full_name)),
                          ]),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    !_vm.teammates.length && !_vm.teammatesLoading
                      ? _c("app-empty-data-block", {
                          staticStyle: { padding: "0 !important" },
                          attrs: { message: _vm.$t("empty_data_block_team") },
                        })
                      : _vm._e(),
                  ],
                  2
                ),
              ],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }