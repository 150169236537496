var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("smart-select", {
    key: "smart-select-filter",
    attrs: {
      data: { ..._vm.$props, list: _vm.list, loader: _vm.settings.loader },
      isLoading: _vm.isLoading,
      loader: _vm.loader,
      searchAndSelect: "",
      multiple: _vm.settings.multiple,
    },
    on: {
      input: _vm.changed,
      search: _vm.searchAndSet,
      "add-more": _vm.addMore,
    },
    model: {
      value: _vm.searchAndSelect,
      callback: function ($$v) {
        _vm.searchAndSelect = $$v
      },
      expression: "searchAndSelect",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }