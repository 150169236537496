var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-column", staticStyle: { gap: "5px" } },
    [
      _vm._l(_vm.documents, function (document, index) {
        return [
          _vm.showMoreDocument || (!_vm.showMoreDocument && index < 4)
            ? _c("documents-item", {
                key: index,
                attrs: { document: document },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.documents.length > 4 && !_vm.showMoreDocument && index === 3
            ? _c(
                "button",
                {
                  staticClass: "btn btn-link text-primary",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.showMoreDocument = !_vm.showMoreDocument
                    },
                  },
                },
                [_vm._v("+" + _vm._s(_vm.documents.length - 4) + " more")]
              )
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }