var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("form", { ref: "form", attrs: { "data-url": "#" } }, [
      _c(
        "div",
        { staticClass: "form-group row align-items-center" },
        [
          _c(
            "label",
            { staticClass: "col-sm-2 mb-0", attrs: { for: "inputs_status" } },
            [_vm._v(_vm._s(_vm.$t("status")))]
          ),
          _vm._v(" "),
          _c("app-input", {
            staticClass: "col-sm-7",
            attrs: {
              id: "inputs_status",
              type: "select",
              required: true,
              list: _vm.statusLists,
            },
            model: {
              value: _vm.inputs.status,
              callback: function ($$v) {
                _vm.$set(_vm.inputs, "status", $$v)
              },
              expression: "inputs.status",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group row align-items-center" },
        [
          _c("label", { staticClass: "col-sm-2 mb-0" }, [
            _vm._v(_vm._s(_vm.$t("gender"))),
          ]),
          _vm._v(" "),
          _c("app-input", {
            staticClass: "col-sm-7",
            attrs: {
              id: "inputs_gender",
              type: "radio",
              list: _vm.genderLists,
            },
            model: {
              value: _vm.inputs.gender,
              callback: function ($$v) {
                _vm.$set(_vm.inputs, "gender", $$v)
              },
              expression: "inputs.gender",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("div", { staticClass: "form-group mt-3" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary mr-2",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.goBack.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.$t("back")) +
                    "\n                    "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary width-100 height-34",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.saveData.apply(null, arguments)
                  },
                },
              },
              [
                _vm.preloader
                  ? _c(
                      "span",
                      [
                        _c("app-pre-loader", {
                          attrs: { "spinner-class": "width-19 height-19" },
                        }),
                      ],
                      1
                    )
                  : _c("span", [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("submit")) +
                          "\n                        "
                      ),
                    ]),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }