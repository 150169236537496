var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("app-tab", {
    attrs: {
      tabs: _vm.tabs,
      type: "horizontal",
      elevated: false,
      tabClass: "px-4 py-3",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }