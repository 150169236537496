var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("app-breadcrumb", {
        attrs: {
          "page-title": _vm.$t("payment_method"),
          directory: _vm.$t("sample_pages"),
          icon: "copy",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "card card-with-shadow border-0" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex align-items-center justify-content-between mb-3",
            },
            [
              _c("p", { staticClass: "mb-0" }, [_vm._v("Stripe")]),
              _vm._v(" "),
              _vm.isSetStripe
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { href: "/stripe" },
                    },
                    [_vm._v("Pay")]
                  )
                : _c("app-note", {
                    attrs: {
                      "content-type": "html",
                      "show-title": false,
                      notes: "Configure your stripe setting first!",
                    },
                  }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "d-flex align-items-center justify-content-between mb-3",
            },
            [
              _c("p", { staticClass: "mb-0" }, [_vm._v("Paypal")]),
              _vm._v(" "),
              _vm.isSetPaypal
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { href: "/create-payment" },
                    },
                    [_vm._v("Pay")]
                  )
                : _c("app-note", {
                    attrs: {
                      "content-type": "html",
                      "show-title": false,
                      notes: "Configure your paypal setting first!",
                    },
                  }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "d-flex align-items-center justify-content-between mb-3",
            },
            [
              _c("p", { staticClass: "mb-0" }, [_vm._v("Razorpay")]),
              _vm._v(" "),
              _vm.isSetRazorpay
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { href: "" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.razorpayPay.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Pay")]
                  )
                : _c("app-note", {
                    attrs: {
                      "content-type": "html",
                      "show-title": false,
                      notes: "Configure your razorpay setting first!",
                    },
                  }),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header p-primary bg-transparent" }, [
      _c("h5", { staticClass: "mb-0" }, [_vm._v(" Payment methods")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }