var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card rounded-lg", staticStyle: { overflow: "hidden" } },
    [
      _c("div", { staticClass: "card-img-top profile__cover bg-info" }, [
        _vm.user?.cover_picture
          ? _c("img", {
              attrs: {
                src:
                  _vm.user?.cover_picture?.full_url ||
                  _vm.urlGenerator("/images/default-cover.png"),
                alt: "cover image",
              },
            })
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card-body p-3" }, [
        _c(
          "div",
          {
            staticClass: "d-flex flex-column align-items-center profile__body",
          },
          [
            _c("img", {
              staticClass: "profile__picture",
              attrs: {
                src:
                  _vm.user.profile_picture?.full_url ||
                  _vm.urlGenerator("/images/avatar-demo.jpg"),
                alt: "pp",
              },
            }),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn btn-link",
                staticStyle: { "font-size": "16px" },
                attrs: { href: _vm.urlGenerator("social-profile/") },
              },
              [_vm._v(_vm._s(_vm.user?.full_name))]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card-footer bg-transparent" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex align-items-center justify-content-center gap-x-2 w-100",
          },
          [
            _c(
              "div",
              [
                _c("app-icon", {
                  staticClass: "mr-1",
                  attrs: { name: "rss", size: 16 },
                }),
                _vm._v(
                  " " + _vm._s(_vm.user.posts_count || 0) + "\n            "
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("app-icon", {
                  staticClass: "mr-1",
                  attrs: { name: "users", size: 16 },
                }),
                _vm._v(
                  " " + _vm._s(_vm.user.teammates_count || 0) + "\n            "
                ),
              ],
              1
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }