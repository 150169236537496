var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "custom-image-upload-wrapper",
      class: _vm.data.wrapperClass,
    },
    [
      _c("div", { staticClass: "image-area d-flex" }, [
        _vm.imageUrl
          ? _c("img", {
              staticClass: "img-fluid mx-auto my-auto",
              attrs: { src: _vm.imageUrl, alt: "" },
            })
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "input-area" }, [
        _c("label", { attrs: { id: "upload-label", for: _vm.inputFieldId } }, [
          _vm._v("\n            " + _vm._s(_vm.data.label) + "\n        "),
        ]),
        _vm._v(" "),
        _c(
          "input",
          _vm._g(
            {
              staticClass: "form-control d-none",
              attrs: {
                id: _vm.inputFieldId,
                type: "file",
                disabled: _vm.data.disabled,
              },
            },
            _vm.listeners
          )
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }