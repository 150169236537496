var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dropdown-menu-filter mr-2" }, [
    _c("div", { staticClass: "dropdown keep-inside-clicks-open" }, [
      _c(
        "button",
        {
          staticClass: "btn menu-filter-btn",
          class: !_vm.isUndefined(_vm.activeOption) ? "text-primary" : "",
          attrs: {
            type: "button",
            id: "dropdownMenuButton-segments-" + _vm.filterId,
            "data-toggle": "dropdown",
          },
        },
        [
          _vm._v(
            "\n            " +
              _vm._s(
                !_vm.isUndefined(_vm.activeOption)
                  ? _vm.activeOption[_vm.listValueField]
                  : _vm.label
              ) +
              "\n            "
          ),
          _c("img", {
            attrs: { src: _vm.getAppUrl("images/chevron-down.svg"), alt: "" },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: "dropdown-menu " + _vm.filterId,
          attrs: {
            "aria-labelledby": "dropdownMenuButton-segments-" + _vm.filterId,
          },
        },
        [
          _c("div", { staticClass: "btn-dropdown-close d-sm-none" }, [
            _c("span", { staticClass: "title" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(
                    !_vm.isUndefined(_vm.activeOption)
                      ? _vm.activeOption[_vm.listValueField]
                      : _vm.label
                  ) +
                  "\n                "
              ),
            ]),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "back float-right",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.closeDropDown.apply(null, arguments)
                  },
                },
              },
              [_c("app-icon", { attrs: { name: "x" } })],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { ref: "optionList", staticClass: "custom-scrollbar" },
            _vm._l(_vm.list, function (item, index) {
              return _c(
                "a",
                {
                  key: index + (item.icon ? item.icon : ""),
                  staticClass: "dropdown-item",
                  class: { "active disabled": item.id == _vm.activeId },
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.selectedItem(item.id, $event)
                    },
                  },
                },
                [
                  item.icon
                    ? _c("app-icon", {
                        staticClass: "mr-2 pb-1 size-20",
                        attrs: { name: item.icon },
                      })
                    : _vm._e(),
                  _vm._v(
                    "\n                    " +
                      _vm._s(item[_vm.listValueField]) +
                      "\n                "
                  ),
                ],
                1
              )
            }),
            0
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }