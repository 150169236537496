var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.preloader
    ? _c("app-overlay-loader")
    : _c(
        "form",
        {
          ref: "form",
          staticClass: "mb-0",
          class: { "loading-opacity": _vm.preloader },
          attrs: { "data-url": "/re-captcha-setting" },
        },
        [
          _c("div", { staticClass: "form-group row align-items-center" }, [
            _c("label", { staticClass: "col-lg-3 col-xl-3 mb-lg-0" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("enable_recaptcha")) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-lg-8 col-xl-8" },
              [
                _c("app-input", {
                  attrs: {
                    type: "radio",
                    list: _vm.list,
                    placeholder: _vm.$t("type_secret_key"),
                    required: true,
                  },
                  model: {
                    value: _vm.formData.recaptcha_enable,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "recaptcha_enable", $$v)
                    },
                    expression: "formData.recaptcha_enable",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group row align-items-center" }, [
            _c(
              "label",
              {
                staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                attrs: { for: "recaptcha_site_key" },
              },
              [
                _vm._v(
                  "\n            " + _vm._s(_vm.$t("site_key")) + "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-lg-8 col-xl-8" },
              [
                _c("app-input", {
                  attrs: {
                    id: "recaptcha_site_key",
                    type: "text",
                    placeholder: _vm.$t("type_site_key"),
                    required: true,
                  },
                  model: {
                    value: _vm.formData.site_key,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "site_key", $$v)
                    },
                    expression: "formData.site_key",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group row align-items-center" }, [
            _c(
              "label",
              {
                staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                attrs: { for: "recaptcha_secret_key" },
              },
              [
                _vm._v(
                  "\n            " + _vm._s(_vm.$t("secret_key")) + "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-lg-8 col-xl-8" },
              [
                _c("app-input", {
                  attrs: {
                    id: "recaptcha_secret_key",
                    type: "text",
                    placeholder: _vm.$t("type_secret_key"),
                    required: true,
                  },
                  model: {
                    value: _vm.formData.secret_key,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "secret_key", $$v)
                    },
                    expression: "formData.secret_key",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt-5 action-buttons" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary mr-2",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("save")))]
            ),
          ]),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }