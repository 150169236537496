var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("app-breadcrumb", { attrs: { "page-title": _vm.$t("map") } }),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "800px" } }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header bg-transparent border-0" }, [
            _c("h5", { staticClass: "m-0 p-0" }, [
              _vm._v(_vm._s(_vm.$t("current_location"))),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "card-body px-3 pt-0 pb-3",
              staticStyle: { height: "600px" },
            },
            [_c("map-component", { attrs: { token: _vm.token } })],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }