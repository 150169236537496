var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "saved-filter-wrapper mr-2" }, [
    _c("div", { staticClass: "dropdown keep-inside-clicks-open" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-filter",
          attrs: {
            type: "button",
            id: "dropdownMenuButton",
            "data-toggle": "dropdown",
            title: _vm.$t("saved_filter"),
            "aria-haspopup": "true",
            "aria-expanded": "false",
          },
        },
        [
          _c("app-icon", {
            staticClass: "size-18",
            attrs: { name: "archive" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "dropdown-menu saved-filter-dropdown-menu" }, [
        _c("div", { staticClass: "px-primary pt-primary" }, [
          _c("h6", [_vm._v(_vm._s(_vm.$t("select_form_previous_state")))]),
          _vm._v(" "),
          _c("p", { staticClass: "font-size-90 text-muted text-justify" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.$t("filter_saved_instruction")) +
                "\n                "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "dropdown-search-result-wrapper custom-scrollbar" },
          _vm._l(_vm.tableFilterData, function (item, index) {
            return _c(
              "a",
              {
                staticClass:
                  "dropdown-item saved-filter-item d-flex justify-content-between font-size-90",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.selectFilter(item)
                  },
                },
              },
              [
                _c("span", [_vm._v(_vm._s(item.filter_name))]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.deleteFilter($event, item)
                      },
                    },
                  },
                  [
                    _c("app-icon", {
                      staticClass: "size-12",
                      attrs: { name: "trash-2" },
                    }),
                  ],
                  1
                ),
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "dropdown-item saved-filter-item font-size-90",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.openSaveFilter.apply(null, arguments)
              },
            },
          },
          [
            _c("app-icon", { staticClass: "size-14", attrs: { name: "plus" } }),
            _vm._v(
              "\n                " +
                _vm._s(_vm.$t("save_current_filter_state")) +
                "\n            "
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "p-primary border-top",
            class: { "d-none": !_vm.isSaveFormActive },
          },
          [
            _c("label", { attrs: { for: "filterName" } }, [
              _vm._v(_vm._s(_vm.$t("filter_name")) + ":"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.filterName,
                  expression: "filterName",
                },
              ],
              staticClass: "form-control",
              attrs: { type: "text", id: "filterName", autocomplete: "off" },
              domProps: { value: _vm.filterName },
              on: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  $event.preventDefault()
                  return _vm.filterSaved.apply(null, arguments)
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.filterName = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _vm.errorFrontend
              ? _c("small", { staticClass: "text-danger validation-error" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.errorFrontend) +
                      "\n                "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex justify-content-end mt-2" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.isSaveFormActive = false
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.$t("cancel")) +
                      "\n                    "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary ml-2",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.filterSaved.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.$t("save")) +
                      "\n                    "
                  ),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }