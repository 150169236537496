import { render, staticRenderFns } from "./SavedFilterList.vue?vue&type=template&id=15451171&"
import script from "./SavedFilterList.vue?vue&type=script&lang=js&"
export * from "./SavedFilterList.vue?vue&type=script&lang=js&"
import style0 from "./SavedFilterList.vue?vue&type=style&index=0&id=15451171&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("G:\\laragon\\www\\bestpris\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('15451171')) {
      api.createRecord('15451171', component.options)
    } else {
      api.reload('15451171', component.options)
    }
    module.hot.accept("./SavedFilterList.vue?vue&type=template&id=15451171&", function () {
      api.rerender('15451171', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/core/components/datatable/helpers/SavedFilterList.vue"
export default component.exports