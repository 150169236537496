var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "container-fluid message-container p-0 d-flex flex-column align-items-center justify-content-center text-center",
    },
    [
      _c(
        "div",
        { staticClass: "text-success mb-5" },
        [_c("app-icon", { staticClass: "size-100", attrs: { name: "mail" } })],
        1
      ),
      _vm._v(" "),
      _c("h1", { staticClass: "text-muted" }, [
        _vm._v(
          "\n        " + _vm._s(_vm.$t("user_verified_successfully")) + "\n    "
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }