var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("form", { ref: "form", attrs: { "data-url": "#" } }, [
      _c(
        "div",
        { staticClass: "form-group row align-items-center" },
        [
          _c(
            "label",
            { staticClass: "col-sm-2 mb-0", attrs: { for: "inputs_name" } },
            [_vm._v(_vm._s(_vm.$t("name")))]
          ),
          _vm._v(" "),
          _c("app-input", {
            staticClass: "col-sm-7",
            attrs: {
              id: "inputs_name",
              type: "text",
              placeholder: _vm.$t("text_type_input"),
              required: true,
            },
            model: {
              value: _vm.inputs.name,
              callback: function ($$v) {
                _vm.$set(_vm.inputs, "name", $$v)
              },
              expression: "inputs.name",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group row align-items-center" },
        [
          _c("label", { staticClass: "col-sm-2 mb-0" }, [
            _vm._v(_vm._s(_vm.$t("age"))),
          ]),
          _vm._v(" "),
          _c("app-input", {
            staticClass: "col-sm-7",
            attrs: {
              id: "inputs_age",
              type: "number",
              "max-number": 100,
              placeholder: _vm.$t("type_age"),
            },
            model: {
              value: _vm.inputs.age,
              callback: function ($$v) {
                _vm.$set(_vm.inputs, "age", $$v)
              },
              expression: "inputs.age",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("div", { staticClass: "form-group mt-3" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.goNext.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.$t("next")) +
                    "\n                    "
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }