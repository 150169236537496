var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("date-range-picker", {
    attrs: {
      label: _vm.label,
      "preset-ranges": _vm.presetRanges,
      "visible-value": _vm.visibleValue,
      "table-id": _vm.tableId,
      active: _vm.active,
    },
    on: { changed: _vm.changed },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }