var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    {
      staticClass:
        "navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row",
    },
    [
      _c(
        "div",
        { staticClass: "navbar-menu-wrapper d-flex align-items-stretch" },
        [
          _c(
            "a",
            {
              staticClass: "align-self-center d-lg-none pl-0 navbar-brand",
              attrs: { href: "" },
            },
            [_c("img", { attrs: { src: _vm.logoUrl, alt: "logo" } })]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex justify-content-between w-100" }, [
            _c(
              "div",
              { staticClass: "d-flex justify-content-start" },
              [
                _c(
                  "button",
                  {
                    staticClass:
                      "navbar-toggler align-self-center d-none d-lg-block pl-0",
                    attrs: { type: "button" },
                  },
                  [
                    _vm.leftMenuType === "normal"
                      ? _c(
                          "span",
                          {
                            key: "align-left",
                            attrs: { title: _vm.$t("collapse_sidebar") },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.togglingLeftMenu(
                                  "active-icon-only-menu"
                                )
                              },
                            },
                          },
                          [_c("app-icon", { attrs: { name: "align-left" } })],
                          1
                        )
                      : _vm.leftMenuType === "icon-only"
                      ? _c(
                          "span",
                          {
                            key: "align-center",
                            attrs: { title: _vm.$t("floating_sidebar") },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.togglingLeftMenu(
                                  "active-floating-menu"
                                )
                              },
                            },
                          },
                          [_c("app-icon", { attrs: { name: "align-center" } })],
                          1
                        )
                      : _vm.leftMenuType === "floating"
                      ? _c(
                          "span",
                          {
                            key: "align-justify",
                            attrs: { title: _vm.$t("full_sidebar") },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.togglingLeftMenu(
                                  "active-normal-menu"
                                )
                              },
                            },
                          },
                          [
                            _c("app-icon", {
                              attrs: { name: "align-justify" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "navbar-toggler align-self-center d-lg-none pl-0",
                    attrs: { type: "button", "data-toggle": "offcanvas" },
                    on: { click: _vm.sidebarOffcanvas },
                  },
                  [_c("app-icon", { attrs: { name: "align-left" } })],
                  1
                ),
                _vm._v(" "),
                _vm._t("left-option"),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex justify-content-center" },
              [_vm._t("center-option")],
              2
            ),
            _vm._v(" "),
            _c("div", [
              _c(
                "ul",
                { staticClass: "navbar-nav navbar-nav-right ml-auto" },
                [
                  _c("li", { staticClass: "nav-item" }, [
                    _c(
                      "button",
                      {
                        staticClass: "navbar-toggler align-self-center",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.toggleDarkMode.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm.darkMode
                          ? _c(
                              "span",
                              {
                                key: "sun",
                                attrs: { title: _vm.$t("light_mood") },
                              },
                              [_c("app-icon", { attrs: { name: "sun" } })],
                              1
                            )
                          : _c(
                              "span",
                              {
                                key: "moon",
                                attrs: { title: _vm.$t("dark_mood") },
                              },
                              [_c("app-icon", { attrs: { name: "moon" } })],
                              1
                            ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "nav-item d-none d-lg-block" }, [
                    _vm.fullScreen
                      ? _c(
                          "a",
                          {
                            key: "maximize",
                            staticClass: "d-flex align-items-center nav-link",
                            attrs: {
                              href: "#",
                              title: _vm.$t("exit_fullscreen"),
                            },
                            on: { click: _vm.fullscreen },
                          },
                          [_c("app-icon", { attrs: { name: "minimize" } })],
                          1
                        )
                      : _c(
                          "a",
                          {
                            key: "minimize",
                            staticClass: "d-flex align-items-center nav-link",
                            attrs: { href: "#", title: _vm.$t("fullscreen") },
                            on: { click: _vm.fullscreen },
                          },
                          [_c("app-icon", { attrs: { name: "maximize" } })],
                          1
                        ),
                  ]),
                  _vm._v(" "),
                  _c("app-navbar-language-dropdown", {
                    attrs: {
                      "selected-language": _vm.selectedLanguage,
                      data: _vm.languageData,
                    },
                  }),
                  _vm._v(" "),
                  _c(_vm.notificationComponent, {
                    tag: "component",
                    attrs: {
                      "all-notification-url": _vm.allNotificationUrl,
                      data: _vm.notificationData,
                      "show-identifier": _vm.showIdentifier,
                      "total-unread": _vm.totalUnread,
                    },
                    on: {
                      clicked: _vm.sendNotification,
                      loadNotifications: function ($event) {
                        return _vm.$emit("loadNotifications")
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "topbar-divider d-none d-sm-block",
                  }),
                  _vm._v(" "),
                  _c("app-navbar-profile-dropdown", {
                    attrs: { user: _vm.user, data: _vm.profileData },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }