var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card card-with-shadow border-0 pb-primary" },
    [
      _c(
        "div",
        {
          staticClass:
            "card-header d-flex align-items-center p-primary primary-card-color",
        },
        [
          _c("h5", { staticClass: "card-title d-inline-block mb-0" }, [
            _vm._v(_vm._s(_vm.$t("users"))),
          ]),
          _vm._v(" "),
          _c("app-search", { on: { input: _vm.getSearchValue } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "p-primary d-flex align-items-center primary-card-color",
        },
        [
          _c(
            "ul",
            { staticClass: "nav tab-filter-menu justify-content-flex-end" },
            _vm._l(_vm.userFilterOptions, function (item, index) {
              return _c("li", { key: index, staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link py-0 font-size-default",
                    class: [
                      _vm.value == item.id
                        ? "active"
                        : index === 0 && _vm.value === ""
                        ? "active"
                        : "",
                    ],
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        return _vm.getFilterValue(item.id)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(item.translated_name) +
                        "\n                "
                    ),
                  ]
                ),
              ])
            }),
            0
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-body px-primary" },
        [
          _c("app-table", {
            staticClass: "remove-datatable-x-padding",
            attrs: {
              id: _vm.data.tableId,
              options: _vm.userTableOptions,
              "filtered-data": _vm.filteredData,
              search: _vm.search,
            },
            on: { action: _vm.action },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }