var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("app-breadcrumb", {
        attrs: {
          "page-title": _vm.$t("progress_bars"),
          directory: _vm.$t("ui_elements"),
          icon: "bar-chart",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex mb-3", staticStyle: { gap: "1rem" } }, [
        _c("div", { staticClass: "card card-with-shadow px-4 pt-3 w-50" }, [
          _c("p", { staticClass: "lead mb-5" }, [
            _vm._v(_vm._s(_vm.$t("basic_progress_bars"))),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb-2" },
            [
              _c("ProgressBar", {
                attrs: { "parent-class": "mb-4", percentage: 0 },
              }),
              _vm._v(" "),
              _c("ProgressBar", {
                attrs: { "parent-class": "mb-4", percentage: 25 },
              }),
              _vm._v(" "),
              _c("ProgressBar", {
                attrs: { "parent-class": "mb-4", percentage: 50 },
              }),
              _vm._v(" "),
              _c("ProgressBar", {
                attrs: {
                  "parent-class": "mb-4",
                  percentage: 75,
                  "show-percentage": true,
                },
              }),
              _vm._v(" "),
              _c("ProgressBar", {
                attrs: { "parent-class": "mb-4", percentage: 100 },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "w-50 card card-with-shadow px-4 pt-3" }, [
          _c("p", { staticClass: "lead mb-5" }, [
            _vm._v(_vm._s(_vm.$t("colored_progress_bars"))),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb-2" },
            [
              _c("ProgressBar", {
                attrs: {
                  "parent-class": "mb-4",
                  "bg-class": "bg-secondary",
                  percentage: 25,
                },
              }),
              _vm._v(" "),
              _c("ProgressBar", {
                attrs: {
                  "parent-class": "mb-4",
                  "bg-class": "bg-warning",
                  percentage: 50,
                },
              }),
              _vm._v(" "),
              _c("ProgressBar", {
                attrs: {
                  "parent-class": "mb-4",
                  "bg-class": "bg-info",
                  percentage: 75,
                },
              }),
              _vm._v(" "),
              _c("ProgressBar", {
                attrs: {
                  "parent-class": "mb-4",
                  "bg-class": "bg-success",
                  percentage: 100,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex mb-3", staticStyle: { gap: "1rem" } }, [
        _c("div", { staticClass: "w-50 card card-with-shadow px-4 pt-3" }, [
          _c("p", { staticClass: "lead mb-5" }, [
            _vm._v(_vm._s(_vm.$t("striped_progress_bars"))),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb-2" },
            [
              _c("ProgressBar", {
                attrs: {
                  "parent-class": "mb-4",
                  "bg-class": "bg-secondary progress-bar-striped",
                  percentage: 25,
                },
              }),
              _vm._v(" "),
              _c("ProgressBar", {
                attrs: {
                  "parent-class": "mb-4",
                  "bg-class": "bg-warning progress-bar-striped",
                  percentage: 50,
                },
              }),
              _vm._v(" "),
              _c("ProgressBar", {
                attrs: {
                  "parent-class": "mb-4",
                  "bg-class": "bg-info progress-bar-striped",
                  percentage: 75,
                },
              }),
              _vm._v(" "),
              _c("ProgressBar", {
                attrs: {
                  "parent-class": "mb-4",
                  "bg-class": "bg-success progress-bar-striped",
                  percentage: 100,
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "w-50 card card-with-shadow px-4 pt-3" }, [
          _c("p", { staticClass: "lead mb-5" }, [
            _vm._v(_vm._s(_vm.$t("animated_progress_bars"))),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb-2" },
            [
              _c("ProgressBar", {
                attrs: {
                  "parent-class": "mb-4",
                  "bg-class":
                    "bg-secondary progress-bar-striped progress-bar-animated",
                  percentage: 25,
                },
              }),
              _vm._v(" "),
              _c("ProgressBar", {
                attrs: {
                  "parent-class": "mb-4",
                  "bg-class":
                    "bg-warning progress-bar-striped progress-bar-animated",
                  percentage: 50,
                },
              }),
              _vm._v(" "),
              _c("ProgressBar", {
                attrs: {
                  "parent-class": "mb-4",
                  "bg-class":
                    "bg-info progress-bar-striped progress-bar-animated",
                  percentage: 75,
                },
              }),
              _vm._v(" "),
              _c("ProgressBar", {
                attrs: {
                  "parent-class": "mb-4",
                  "bg-class":
                    "bg-success progress-bar-striped progress-bar-animated",
                  percentage: 100,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "d-flex align-content-start",
          staticStyle: { gap: "1rem" },
        },
        [
          _c("div", { staticClass: "w-50 card card-with-shadow px-4 pt-3" }, [
            _c("p", { staticClass: "lead mb-5" }, [
              _vm._v(_vm._s(_vm.$t("different_size_progress_bars"))),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mb-2" },
              [
                _c("ProgressBar", {
                  attrs: {
                    "parent-class": "mb-4 height-1",
                    "bg-class": "bg-success",
                    percentage: 25,
                  },
                }),
                _vm._v(" "),
                _c("ProgressBar", {
                  attrs: {
                    "parent-class": "mb-4 height-25",
                    "bg-class": "bg-danger",
                    percentage: 50,
                  },
                }),
                _vm._v(" "),
                _c("ProgressBar", {
                  attrs: {
                    "parent-class": "mb-4 height-20",
                    "bg-class": "bg-accent",
                    percentage: 75,
                  },
                }),
                _vm._v(" "),
                _c("ProgressBar", {
                  attrs: {
                    "parent-class": "mb-4 height-20",
                    "bg-class": "bg-accent",
                    percentage: 100,
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "w-50 card card-with-shadow px-4 pt-3" }, [
            _c("p", { staticClass: "lead mb-5" }, [
              _vm._v(_vm._s(_vm.$t("vertical_progress_bars"))),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "mb-2 d-flex flex-wrap",
                staticStyle: { gap: "2.5rem" },
              },
              [
                _c("ProgressBar", {
                  attrs: {
                    type: "vertical",
                    "parent-class": "mb-4",
                    percentage: 25,
                  },
                }),
                _vm._v(" "),
                _c("ProgressBar", {
                  attrs: {
                    type: "vertical",
                    "parent-class": "mb-4",
                    "bg-class": "bg-secondary",
                    percentage: 50,
                  },
                }),
                _vm._v(" "),
                _c("ProgressBar", {
                  attrs: {
                    type: "vertical",
                    "parent-class": "mb-4",
                    "bg-class": "bg-warning",
                    percentage: 75,
                  },
                }),
                _vm._v(" "),
                _c("ProgressBar", {
                  attrs: {
                    type: "vertical",
                    "parent-class": "mb-4",
                    "bg-class": "bg-danger progress-bar-striped",
                    percentage: 100,
                  },
                }),
                _vm._v(" "),
                _c("ProgressBar", {
                  attrs: {
                    type: "vertical",
                    "parent-class": "mb-4",
                    "bg-class": "bg-info",
                    "show-percentage": true,
                    percentage: 25,
                  },
                }),
                _vm._v(" "),
                _c("ProgressBar", {
                  attrs: {
                    type: "vertical",
                    "parent-class": "mb-4",
                    "bg-class": "bg-success",
                    percentage: 50,
                  },
                }),
                _vm._v(" "),
                _c("ProgressBar", {
                  attrs: {
                    type: "vertical",
                    "parent-class": "mb-4",
                    "bg-class": "bg-light",
                    percentage: 75,
                  },
                }),
                _vm._v(" "),
                _c("ProgressBar", {
                  attrs: {
                    type: "vertical",
                    "parent-class": "mb-4",
                    "bg-class":
                      "bg-dark progress-bar-striped progress-bar-animated",
                    percentage: 100,
                  },
                }),
                _vm._v(" "),
                _c("ProgressBar", {
                  attrs: {
                    type: "vertical",
                    "parent-class": "mb-4",
                    "bg-class": "bg-info",
                    percentage: 25,
                  },
                }),
                _vm._v(" "),
                _c("ProgressBar", {
                  attrs: {
                    type: "vertical",
                    "parent-class": "mb-4",
                    "bg-class": "bg-success",
                    percentage: 50,
                  },
                }),
                _vm._v(" "),
                _c("ProgressBar", {
                  attrs: {
                    type: "vertical",
                    "parent-class": "mb-4",
                    "bg-class": "bg-light",
                    percentage: 75,
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }