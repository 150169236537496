var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("app-breadcrumb", {
        attrs: {
          "page-title": _vm.$t("charts"),
          directory: _vm.$t("ui_elements"),
          icon: "bar-chart",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "charts-wrapper" }, [
        _c(
          "div",
          { staticClass: "card card-with-shadow p-3 span-full" },
          [
            _c("app-chart", {
              staticClass: "mb-primary",
              attrs: {
                type: "bar-chart",
                height: 210,
                "data-sets": _vm.barChartProps.dataSets,
                labels: _vm.barChartProps.labels,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card card-with-shadow p-3 pi-chart span-half" },
          [
            _c("app-chart", {
              staticClass: "mb-primary",
              attrs: {
                type: "pie-chart",
                height: 210,
                "data-sets": _vm.pieChartProps.dataSets,
                labels: _vm.pieChartProps.labels,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card card-with-shadow p-3 span-half" },
          [
            _c("app-chart", {
              staticClass: "mb-primary",
              attrs: {
                type: "dough-chart",
                height: 210,
                "data-sets": _vm.doughnutChartProps.dataSets,
                labels: _vm.doughnutChartProps.labels,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card card-with-shadow p-3 span-full" },
          [
            _c("app-chart", {
              staticClass: "mb-primary",
              attrs: {
                type: "horizontal-line-chart",
                height: 210,
                "data-sets": _vm.horizontalChartProps.dataSets,
                labels: _vm.horizontalChartProps.labels,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }