var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.preloader
        ? _c("app-overlay-loader")
        : _c("div", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12 col-md-4" }, [
                _c("div", { staticClass: "card card-with-shadow border-0" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-body d-flex justifycontent-center align-items-center",
                    },
                    [
                      _c("div", { staticClass: "text-center w-100" }, [
                        _c("div", { staticClass: "text-muted" }, [
                          _vm._v(
                            _vm._s(_vm.$t("total_jobs")) +
                              "(" +
                              _vm._s(_vm.$t("all_time")) +
                              ")"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "h1" }, [
                          _vm._v(_vm._s(_vm.total_job)),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-md-4" }, [
                _c("div", { staticClass: "card card-with-shadow border-0" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-body d-flex justifycontent-center align-items-center",
                    },
                    [
                      _c("div", { staticClass: "text-center w-100" }, [
                        _c("div", { staticClass: "text-muted" }, [
                          _vm._v(_vm._s(_vm.$t("live_job"))),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "h1" }, [
                          _vm._v(_vm._s(_vm.live_job)),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-md-4" }, [
                _c("div", { staticClass: "card card-with-shadow border-0" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-body d-flex justifycontent-center align-items-center",
                    },
                    [
                      _c("div", { staticClass: "text-center w-100" }, [
                        _c("div", { staticClass: "text-muted" }, [
                          _vm._v(_vm._s(_vm.$t("avg_candidates_per_job"))),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "h1" }, [
                          _vm._v(_vm._s(_vm.avg_candidate_per_job)),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row mt-primary" }, [
              _c("div", { staticClass: "col" }, [
                _c("div", { staticClass: "card card-with-shadow border-0" }, [
                  _c(
                    "div",
                    { staticClass: "card-body" },
                    [
                      _c("h4", [_vm._v("New Candidates By Job")]),
                      _vm._v(" "),
                      _c("app-chart", {
                        staticClass: "mb-primary",
                        attrs: {
                          type: "horizontal-line-chart",
                          height: 230,
                          labels: _vm.newCandidates.labels,
                          "data-sets": _vm.newCandidates.dataSet,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row mt-primary" }, [
              _c("div", { staticClass: "col" }, [
                _c("div", { staticClass: "card card-with-shadow border-0" }, [
                  _c(
                    "div",
                    { staticClass: "card-body" },
                    [
                      _c("h4", [_vm._v(_vm._s(_vm.$t("active_job_by_month")))]),
                      _vm._v(" "),
                      _c("app-chart", {
                        staticClass: "mb-primary",
                        attrs: {
                          type: "bar-chart",
                          height: 230,
                          labels: _vm.activeJobByMonth.labels,
                          "data-sets": _vm.activeJobByMonth.dataSet,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }