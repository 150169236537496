<template>
    <div class="text-justify">
        {{ $t('card_content')}}
    </div>
</template>

<script>
    export default {
        name: "DeliverySetting"
    }
</script>
