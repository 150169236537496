var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "table",
    { staticClass: "table" },
    [
      _vm.data.length
        ? [
            _c("thead", [
              _c(
                "tr",
                { staticClass: "datatable-th" },
                _vm._l(_vm.options.cols, function (head, index) {
                  return _c(
                    "th",
                    {
                      key: `headers-item-${index}`,
                      class: `datatable-th pt-0 ${
                        head.alignment === "right" ? "text-right" : ""
                      }`,
                      attrs: { scope: "col" },
                    },
                    [
                      _c("span", { staticClass: "text-capitalize" }, [
                        _vm._v(_vm._s(head.name)),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.data, function (row, row_index) {
                return _c(
                  "tr",
                  { key: `row-items-${row_index}` },
                  _vm._l(_vm.options.cols, function (col, col_index) {
                    return _c(
                      "td",
                      {
                        key: `col-item-${row_index}-${col_index}`,
                        class: `datatable-td ${
                          col.alignment === "right" ? "text-right" : ""
                        }`,
                      },
                      [
                        col.key === "customer"
                          ? _c("div", {
                              class: `${col.class} cursor-pointer`,
                              domProps: { innerHTML: _vm._s(row[col.key]) },
                            })
                          : col.type === "modal"
                          ? _c("div", {
                              class: `${col.class} cursor-pointer`,
                              domProps: { innerHTML: _vm._s(row[col.key]) },
                            })
                          : _c("div", {
                              class: col.class,
                              domProps: { innerHTML: _vm._s(row[col.key]) },
                            }),
                      ]
                    )
                  }),
                  0
                )
              }),
              0
            ),
          ]
        : _c("app-empty-data-block", {
            attrs: { message: _vm.$t("empty_data_block_dummy_message") },
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }