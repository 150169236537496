var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("app-breadcrumb", {
        attrs: {
          "page-title": _vm.$t("checkboxes_and_radios"),
          directory: _vm.$t("ui_elements"),
          icon: "trello",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "card card-with-shadow border-0" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header bg-transparent p-primary d-flex justify-content-between align-items-center",
          },
          [
            _c("h5", { staticClass: "card-title mb-0" }, [
              _vm._v(_vm._s(_vm.$t("radios"))),
            ]),
            _vm._v(" "),
            _c("copy-button", {
              attrs: { id: "ui-radios", value: _vm.uiRadiosValue },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("form", { ref: "form", attrs: { "data-url": "" } }, [
            _c(
              "div",
              { staticClass: "form-group row align-items-center" },
              [
                _c("label", { staticClass: "col-sm-3 mb-sm-0" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.$t("gender")) +
                      "\n                    "
                  ),
                ]),
                _vm._v(" "),
                _c("app-input", {
                  staticClass: "col-sm-9",
                  attrs: { type: "radio", list: _vm.listGender },
                  model: {
                    value: _vm.radioInputs.gender,
                    callback: function ($$v) {
                      _vm.$set(_vm.radioInputs, "gender", $$v)
                    },
                    expression: "radioInputs.gender",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group row align-items-center" },
              [
                _c("label", { staticClass: "col-sm-3 mb-sm-0" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.$t("job_area")) +
                      "\n                    "
                  ),
                ]),
                _vm._v(" "),
                _c("app-input", {
                  staticClass: "col-sm-9",
                  attrs: { type: "radio", list: _vm.listJobPost },
                  model: {
                    value: _vm.radioInputs.selectedPost,
                    callback: function ($$v) {
                      _vm.$set(_vm.radioInputs, "selectedPost", $$v)
                    },
                    expression: "radioInputs.selectedPost",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group row align-items-center" },
              [
                _c("label", { staticClass: "col-sm-3 mb-sm-0" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.$t("expertise_level")) +
                      "\n                    "
                  ),
                ]),
                _vm._v(" "),
                _c("app-input", {
                  staticClass: "col-sm-9",
                  attrs: { type: "radio", list: _vm.listLevel },
                  model: {
                    value: _vm.radioInputs.ExpertLevel,
                    callback: function ($$v) {
                      _vm.$set(_vm.radioInputs, "ExpertLevel", $$v)
                    },
                    expression: "radioInputs.ExpertLevel",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card card-with-shadow border-0 mt-4" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header bg-transparent p-primary d-flex justify-content-between align-items-center",
          },
          [
            _c("h5", { staticClass: "card-title mb-0" }, [
              _vm._v(_vm._s(_vm.$t("radio_button"))),
            ]),
            _vm._v(" "),
            _c("copy-button", {
              attrs: { id: "ui-radio-button", value: _vm.uiRadioButtonValue },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("form", { ref: "form", attrs: { "data-url": "" } }, [
            _c(
              "div",
              { staticClass: "form-group row align-items-center" },
              [
                _c("label", { staticClass: "col-sm-3 mb-sm-0" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.$t("gender")) +
                      "\n                    "
                  ),
                ]),
                _vm._v(" "),
                _c("app-input", {
                  staticClass: "col-sm-9",
                  attrs: { type: "radio-buttons", list: _vm.listGender },
                  model: {
                    value: _vm.radioBtnInputs.gender,
                    callback: function ($$v) {
                      _vm.$set(_vm.radioBtnInputs, "gender", $$v)
                    },
                    expression: "radioBtnInputs.gender",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group row align-items-center" },
              [
                _c("label", { staticClass: "col-sm-3 mb-sm-0" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.$t("job_area")) +
                      "\n                    "
                  ),
                ]),
                _vm._v(" "),
                _c("app-input", {
                  staticClass: "col-sm-9",
                  attrs: { type: "radio-buttons", list: _vm.listJobPost },
                  model: {
                    value: _vm.radioBtnInputs.selectedPost,
                    callback: function ($$v) {
                      _vm.$set(_vm.radioBtnInputs, "selectedPost", $$v)
                    },
                    expression: "radioBtnInputs.selectedPost",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group row align-items-center" },
              [
                _c("label", { staticClass: "col-sm-3 mb-sm-0" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.$t("expertise_level")) +
                      "\n                    "
                  ),
                ]),
                _vm._v(" "),
                _c("app-input", {
                  staticClass: "col-sm-9",
                  attrs: { type: "radio-buttons", list: _vm.listLevel },
                  model: {
                    value: _vm.radioBtnInputs.ExpertLevel,
                    callback: function ($$v) {
                      _vm.$set(_vm.radioBtnInputs, "ExpertLevel", $$v)
                    },
                    expression: "radioBtnInputs.ExpertLevel",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card card-with-shadow border-0 mt-4" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header bg-transparent p-primary d-flex justify-content-between align-items-center",
          },
          [
            _c("h5", { staticClass: "card-title mb-0" }, [
              _vm._v(_vm._s(_vm.$t("check_boxes"))),
            ]),
            _vm._v(" "),
            _c("copy-button", {
              attrs: { id: "ui-checkboxes", value: _vm.uiCheckboxesValue },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("form", { ref: "form", attrs: { "data-url": "" } }, [
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-sm-3" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.$t("frontend_skills")) +
                    "\n                    "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-sm-9" },
                [
                  _c("app-input", {
                    attrs: {
                      type: "checkbox",
                      required: true,
                      list: _vm.listFrontendSkill,
                    },
                    model: {
                      value: _vm.checkboxInputs.frontend,
                      callback: function ($$v) {
                        _vm.$set(_vm.checkboxInputs, "frontend", $$v)
                      },
                      expression: "checkboxInputs.frontend",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-sm-3" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.$t("backend_skills")) +
                    "\n                    "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-sm-9" },
                [
                  _c("app-input", {
                    attrs: {
                      type: "checkbox",
                      required: true,
                      list: _vm.listBackendSkills,
                    },
                    model: {
                      value: _vm.checkboxInputs.backend,
                      callback: function ($$v) {
                        _vm.$set(_vm.checkboxInputs, "backend", $$v)
                      },
                      expression: "checkboxInputs.backend",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-sm-3" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.$t("job_area")) +
                    "\n                    "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-sm-9" },
                [
                  _c("app-input", {
                    attrs: {
                      type: "checkbox",
                      required: true,
                      list: _vm.listLevel,
                    },
                    model: {
                      value: _vm.checkboxInputs.ExpertLevel,
                      callback: function ($$v) {
                        _vm.$set(_vm.checkboxInputs, "ExpertLevel", $$v)
                      },
                      expression: "checkboxInputs.ExpertLevel",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }