var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card card-with-shadow border-0 mb-primary" },
    [
      _c("div", { staticClass: "card-header p-primary bg-transparent" }, [
        _c("h5", { staticClass: "card-title m-0" }, [
          _vm._v(_vm._s(_vm.$t("checkbox_and_radios"))),
        ]),
      ]),
      _vm._v(" "),
      _vm.preloader ? _c("app-overlay-loader") : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c(
          "form",
          {
            ref: "form",
            staticClass: "mb-0",
            class: { "loading-opacity": _vm.preloader },
            attrs: { "data-url": "/form/radio" },
          },
          [
            _c(
              "div",
              { staticClass: "form-group row align-items-center" },
              [
                _c("label", { staticClass: "col-sm-2" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("radio")) +
                      "\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("app-input", {
                  staticClass: "col-sm-8",
                  attrs: { type: "radio", list: _vm.list },
                  model: {
                    value: _vm.inputs.radio,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputs, "radio", $$v)
                    },
                    expression: "inputs.radio",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group row align-items-center" },
              [
                _c("label", { staticClass: "col-sm-2 pt-2" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("radio_buttons")) +
                      "\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("app-input", {
                  staticClass: "col-sm-8",
                  attrs: { type: "radio-buttons", list: _vm.list },
                  model: {
                    value: _vm.inputs.radio_buttons_group,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputs, "radio_buttons_group", $$v)
                    },
                    expression: "inputs.radio_buttons_group",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "form-group row mb-0" }, [
              _c("label", { staticClass: "col-sm-2" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.$t("checkboxes")) +
                    "\n                "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-sm-8" },
                [
                  _c("app-input", {
                    attrs: { type: "checkbox", list: _vm.list },
                    model: {
                      value: _vm.inputs.checkbox,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "checkbox", $$v)
                      },
                      expression: "inputs.checkbox",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mt-5 action-buttons" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary mr-2",
                  attrs: { type: "submit" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("submit")) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  attrs: { type: "submit" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.clear.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("clear")) +
                      "\n                "
                  ),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }