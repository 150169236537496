var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", { staticClass: "nav-item nav-profile dropdown" }, [
    _c(
      "a",
      {
        staticClass: "d-flex align-items-center nav-link dropdown-toggle mr-0",
        attrs: {
          id: "profileDropdown",
          href: "#",
          "data-toggle": "dropdown",
          "aria-expanded": "false",
        },
      },
      [
        _c("div", { staticClass: "nav-profile-text" }, [
          _c("p", { staticClass: "mb-0 text-black text-right" }, [
            _vm._v("\n                " + _vm._s(_vm.user.full_name) + " "),
            _c("br"),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "text-secondary font-size-90 d-block mt-1" },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.user.role) +
                    "\n                "
                ),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("app-avatar", {
          attrs: {
            title: _vm.user.full_name,
            "avatar-class": "avatars-w-40",
            img: _vm.user.img,
            "alt-text": "image",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "dropdown-menu dropdown-menu-right navbar-dropdown",
        attrs: { "aria-labelledby": "profileDropdown" },
      },
      [
        _c(
          "div",
          { staticClass: "dropdown-item profile" },
          [
            _c("app-avatar", {
              attrs: {
                title: _vm.user.full_name,
                "avatar-class": "avatars-w-50",
                img: _vm.user.img,
                "alt-text": "image",
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "nav-profile-text font-size-default ml-2" },
              [
                _c("p", { staticClass: "my-0 text-black" }, [
                  _vm._v(_vm._s(_vm.user.full_name)),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "text-secondary font-size-90" }, [
                  _vm._v(_vm._s(_vm.user.role)),
                ]),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "dropdown-divider" }),
        _vm._v(" "),
        _vm._l(_vm.data, function (item, index) {
          return _c(
            "a",
            {
              key: index,
              staticClass: "dropdown-item",
              attrs: { href: item.url },
            },
            [
              _c("app-icon", {
                staticClass: "mr-3",
                attrs: { name: item.optionIcon },
              }),
              _vm._v("\n            " + _vm._s(item.optionName) + "\n        "),
            ],
            1
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }