var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("app-breadcrumb", { attrs: { "page-title": _vm.$t("profile") } }),
      _vm._v(" "),
      _c("div", { staticClass: "row pt-3 p-md-3 justify-content-center" }, [
        _c("div", { staticClass: "col-12 col-md-8" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "pt-3 p-md-3" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col p-lg-0" }, [
                  _c(
                    "div",
                    { staticClass: "card rounded-lg position-relative" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "card-img-top profile-cover bg-info cover",
                          staticStyle: { "min-height": "200px" },
                        },
                        [
                          _vm.profileUser?.coverPicture
                            ? _c("img", {
                                attrs: {
                                  src:
                                    _vm.profileUser?.coverPicture ||
                                    _vm.urlGenerator(
                                      "/images/default-cover.png"
                                    ),
                                  alt: "cover image",
                                },
                              })
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "avatar d-flex flex-column flex-md-row align-items-center gap-x-1",
                          class: _vm.isRtl ? "r-20" : "",
                          staticStyle: { "pointer-events": "none" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "rounded-circle profile-uploader position-relative",
                              staticStyle: { "pointer-events": "auto" },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: _vm.getImg(_vm.profileUser),
                                  alt: "pp",
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "text-white text-center text-md-left",
                            },
                            [
                              _c("h4", { staticClass: "p-0 m-0" }, [
                                _vm._v(_vm._s(_vm.profileUser.full_name)),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "card-footer bg-transparent" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-center justify-content-md-end",
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "btn btn-link py-0 text-primary",
                                attrs: { href: "#" },
                              },
                              [
                                _c("app-icon", {
                                  staticClass: "mr-1",
                                  attrs: { name: "user" },
                                }),
                                _vm._v(
                                  "\n                                            Profile"
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "btn btn-link py-0",
                                attrs: { href: "#" },
                              },
                              [
                                _c("app-icon", {
                                  staticClass: "mr-1",
                                  attrs: { name: "image" },
                                }),
                                _vm._v(
                                  "\n                                            Gallery"
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row mt-3" }, [
                _c("div", { staticClass: "col-12 col-md-5 col-lg-4 p-lg-0" }, [
                  _c("div", { staticClass: "card rounded-lg" }, [
                    _c("div", { staticClass: "card-body p-4" }, [
                      _c("h3", [_vm._v(_vm._s(_vm.$t("about")))]),
                      _vm._v(" "),
                      _vm._m(0),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "d-flex flex-column gap-y-1" },
                        [
                          _c("div", { staticClass: "d-flex" }, [
                            _c(
                              "div",
                              { staticClass: "avatar-sm" },
                              [
                                _c("app-icon", {
                                  staticClass: "mr-1 mr-md-2",
                                  attrs: { size: 20, name: "shuffle" },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "bold" }, [
                              _vm._v("Male"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "d-flex" }, [
                            _c(
                              "div",
                              { staticClass: "avatar-sm" },
                              [
                                _c("app-icon", {
                                  staticClass: "mr-1 mr-md-2",
                                  attrs: { size: 20, name: "map-pin" },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "bold" }, [
                              _vm._v(
                                "Live at 2554 Connelly Mews Hegmannview, NC 00110-4201"
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm.profileUser.email
                            ? _c("div", { staticClass: "d-flex" }, [
                                _c(
                                  "div",
                                  { staticClass: "avatar-sm" },
                                  [
                                    _c("app-icon", {
                                      staticClass: "mr-1 mr-md-2",
                                      attrs: { size: 20, name: "mail" },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: "mailto:" + _vm.profileUser.email,
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.profileUser.email))]
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "d-flex" }, [
                            _c(
                              "div",
                              { staticClass: "avatar-sm" },
                              [
                                _c("app-icon", {
                                  staticClass: "mr-1 mr-md-2",
                                  attrs: { size: 20, name: "phone" },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "bold",
                                attrs: { href: "tel:+1 (870) 620-7201" },
                              },
                              [_vm._v("+1 (870) 620-7201")]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "d-flex" }, [
                            _c(
                              "div",
                              { staticClass: "avatar-sm" },
                              [
                                _c("app-icon", {
                                  staticClass: "mr-1 mr-md-2",
                                  attrs: { size: 20, name: "gift" },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "bold" }, [
                              _vm._v("26-02-1998"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm._l(
                            _vm.visibleSocialLinks,
                            function (social, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "d-flex align-items-center",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "avatar-sm" },
                                    [
                                      _c("app-icon", {
                                        staticClass: "mr-1 mr-md-2",
                                        attrs: { size: 20, name: social.icon },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "text-truncate",
                                      attrs: {
                                        href: social.link,
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v(_vm._s(social.link))]
                                  ),
                                ]
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "col-12 col-md-7 col-lg-8 mt-3 mt-md-0",
                    class: _vm.isRtl ? "pl-lg-0" : "pr-lg-0",
                  },
                  [
                    _c("div", { staticClass: "d-flex flex-column gap-y-1" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex flex-column gap-y-1" },
                        [
                          _vm.loading
                            ? _c("app-pre-loader")
                            : _vm._l(_vm.posts, function (post, index) {
                                return _c("post-view", {
                                  key: `post-items-${post.id}-${index}`,
                                  attrs: { post: post, recent: post.recent },
                                  on: { edit: _vm.edit, del: _vm.del },
                                })
                              }),
                          _vm._v(" "),
                          !_vm.posts.length && !_vm.loading
                            ? _c("app-empty-data-block", {
                                attrs: {
                                  message: _vm.$t("empty_data_block_post"),
                                },
                              })
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", [
        _vm._v(
          "Excepturi sint eos accusantium hic voluptatem. Itaque praesentium in adipisci dolor cupiditate. Ea dignissimos ut velit."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }