var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "textarea",
      {
        staticClass: "d-none",
        attrs: { type: "text", id: _vm.id, readonly: "readonly" },
      },
      [_vm._v("        " + _vm._s(_vm.value) + "\n    ")]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-link p-1 btn-sm",
        attrs: {
          "data-toggle": "tooltip",
          "data-placement": "top",
          title: _vm.$t("click_to_copy"),
        },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.btnClicked.apply(null, arguments)
          },
          mouseleave: function ($event) {
            _vm.copied = false
          },
        },
      },
      [
        !_vm.copied
          ? _c(
              "span",
              { key: "copy" },
              [
                _c("app-icon", {
                  attrs: { name: "copy", width: "20", height: "20" },
                }),
              ],
              1
            )
          : _c(
              "span",
              { key: "check" },
              [
                _c("app-icon", {
                  attrs: { name: "check", width: "20", height: "20" },
                }),
              ],
              1
            ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }