var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.type === "bar-chart"
        ? _c("bar-chart", { attrs: { data: _vm.$props } })
        : _vm.type === "line-chart"
        ? _c("line-chart", { attrs: { data: _vm.$props } })
        : _vm.type === "pie-chart"
        ? _c("pie-chart", { attrs: { data: _vm.$props } })
        : _vm.type === "dough-chart"
        ? _c("dough-chart", { attrs: { data: _vm.$props } })
        : _vm.type === "reader-chart"
        ? _c("reader-chart", { attrs: { data: _vm.$props } })
        : _vm.type === "polar-chart"
        ? _c("polar-chart", { attrs: { data: _vm.$props } })
        : _vm.type === "bubble-chart"
        ? _c("bubble-chart", { attrs: { data: _vm.$props } })
        : _vm.type === "scatter-chart"
        ? _c("scatter-chart", { attrs: { data: _vm.$props } })
        : _vm.type === "horizontal-line-chart"
        ? _c("horizontal-line-chart", { attrs: { data: _vm.$props } })
        : _vm.type === "custom-line-chart"
        ? _c("custom-line-chart", { attrs: { data: _vm.$props } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }