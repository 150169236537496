var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visibleActions.length > 0
    ? _c(
        "div",
        {
          staticClass: "btn-group btn-group-action",
          attrs: { role: "group", "aria-label": "Default action" },
        },
        _vm._l(_vm.visibleActions, function (action, index) {
          return _c(
            "button",
            {
              key: index,
              staticClass: "btn",
              class: action.className,
              attrs: {
                type: "button",
                "data-toggle": "tooltip",
                "data-placement": "top",
                title: action.title,
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.callMethod("", action)
                },
              },
            },
            [
              action.icon
                ? _c("app-icon", { attrs: { name: action.icon } })
                : _c("span", [
                    _vm._v(
                      "\n            " + _vm._s(action.title) + "\n        "
                    ),
                  ]),
            ],
            1
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }