var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("editor", {
    ref: "toastUiEditor",
    attrs: {
      "initial-value": _vm.initialValue,
      options: _vm.editorOptions,
      height: `${_vm.data.height}px`,
      initialEditType: "markdown",
    },
    on: { change: _vm.onEditorChange },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }