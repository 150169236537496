var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav", [
    _c(
      "ul",
      {
        staticClass:
          "pagination justify-content-center justify-content-md-end mb-0",
      },
      [
        _c("li", { staticClass: "d-flex align-items-center mr-3" }, [
          _c("p", { staticClass: "text-muted mb-0 mr-2" }, [
            _vm._v(_vm._s(_vm.$t("go_to_page"))),
          ]),
          _vm._v(" "),
          _c("input", {
            staticClass: "form-control width-50",
            attrs: { type: "text" },
            domProps: { value: _vm.activePage },
            on: {
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                $event.preventDefault()
                return _vm.directGoToPage($event)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c(
          "li",
          {
            staticClass: "page-item",
            class: { disabled: _vm.activePage <= 1 },
          },
          [
            _c(
              "a",
              {
                staticClass: "page-link border-0",
                attrs: { href: "#", "aria-label": "Previous" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.prevArrow.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "span",
                  { key: "arrow-left" },
                  [
                    _c("app-icon", {
                      staticClass: "menu-arrow",
                      attrs: { name: "arrow-left" },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _vm.isExtendedPagination
          ? [
              _vm.activePage <= 4
                ? [
                    _vm._l(4, function (page, index) {
                      return _c(
                        "li",
                        { key: index, staticClass: "page-item" },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "page-link border-0",
                              class: {
                                "active disabled": _vm.activePage === page,
                              },
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.activated(page)
                                },
                              },
                            },
                            [_vm._v(_vm._s(page))]
                          ),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c("li", { staticClass: "page-item" }, [
                      _c(
                        "a",
                        {
                          staticClass: "page-link border-0 jumping-step",
                          attrs: {
                            href: "#",
                            title: _vm.$t("next_five_pages"),
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.nextFiveStep.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            { key: "more-horizontal-start" },
                            [
                              _c("app-icon", {
                                attrs: { name: "more-horizontal" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            { key: "chevrons-right-start" },
                            [
                              _c("app-icon", {
                                staticClass: "jump-icon",
                                attrs: { name: "chevrons-right" },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "page-item" }, [
                      _c(
                        "a",
                        {
                          staticClass: "page-link border-0",
                          class: {
                            "active disabled": _vm.activePage === _vm.totalPage,
                          },
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.activated(_vm.totalPage)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.totalPage) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ]),
                  ]
                : _vm.activePage > _vm.totalPage - 4
                ? [
                    _c("li", { staticClass: "page-item" }, [
                      _c(
                        "a",
                        {
                          staticClass: "page-link border-0",
                          class: { "active disabled": _vm.activePage === 1 },
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.activated(1)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(1) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "page-item" }, [
                      _c(
                        "a",
                        {
                          staticClass: "page-link border-0 jumping-step",
                          attrs: {
                            href: "#",
                            title: _vm.$t("previous_five_pages"),
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.prevFiveStep.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            { key: "more-horizontal-end" },
                            [
                              _c("app-icon", {
                                attrs: { name: "more-horizontal" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            { key: "chevrons-left-end" },
                            [
                              _c("app-icon", {
                                staticClass: "jump-icon",
                                attrs: { name: "chevrons-left" },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._l(4, function (page, index) {
                      return _c(
                        "li",
                        { key: index, staticClass: "page-item" },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "page-link border-0",
                              class: {
                                "active disabled":
                                  _vm.activePage === page + _vm.endOffset,
                              },
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.activated(page + _vm.endOffset)
                                },
                              },
                            },
                            [_vm._v(_vm._s(page + _vm.endOffset))]
                          ),
                        ]
                      )
                    }),
                  ]
                : [
                    _c("li", { staticClass: "page-item" }, [
                      _c(
                        "a",
                        {
                          staticClass: "page-link border-0",
                          class: { "active disabled": _vm.activePage === 1 },
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.activated(1)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(1) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "page-item" }, [
                      _c(
                        "a",
                        {
                          staticClass: "page-link border-0 jumping-step",
                          attrs: {
                            href: "#",
                            title: _vm.$t("previous_five_pages"),
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.prevFiveStep.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            { key: "more-horizontal-middle-start" },
                            [
                              _c("app-icon", {
                                attrs: { name: "more-horizontal" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            { key: "chevrons-left-middle" },
                            [
                              _c("app-icon", {
                                staticClass: "jump-icon",
                                attrs: { name: "chevrons-left" },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._l(5, function (page, index) {
                      return _c(
                        "li",
                        { key: index, staticClass: "page-item" },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "page-link border-0",
                              class: {
                                "active disabled":
                                  _vm.activePage === page + _vm.middleOffset,
                              },
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.activated(page + _vm.middleOffset)
                                },
                              },
                            },
                            [_vm._v(_vm._s(page + _vm.middleOffset))]
                          ),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c("li", { staticClass: "page-item" }, [
                      _c(
                        "a",
                        {
                          staticClass: "page-link border-0 jumping-step",
                          attrs: {
                            href: "#",
                            title: _vm.$t("next_five_pages"),
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.nextFiveStep.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            { key: "more-horizontal-middle-end" },
                            [
                              _c("app-icon", {
                                attrs: { name: "more-horizontal" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            { key: "chevrons-right-middle" },
                            [
                              _c("app-icon", {
                                staticClass: "jump-icon",
                                attrs: { name: "chevrons-right" },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "page-item" }, [
                      _c(
                        "a",
                        {
                          staticClass: "page-link border-0",
                          class: {
                            "active disabled": _vm.activePage === _vm.totalPage,
                          },
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.activated(_vm.totalPage)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.totalPage) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ]),
                  ],
            ]
          : _vm._l(_vm.paginationPages, function (page, index) {
              return _c("li", { key: index, staticClass: "page-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "page-link border-0",
                    class: { "active disabled": _vm.activePage === page },
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.activated(page)
                      },
                    },
                  },
                  [_vm._v(_vm._s(page))]
                ),
              ])
            }),
        _vm._v(" "),
        _c(
          "li",
          {
            staticClass: "page-item",
            class: { disabled: _vm.activePage >= _vm.totalPage },
          },
          [
            _c(
              "a",
              {
                staticClass: "page-link border-0 align-content-center",
                attrs: { href: "#", "aria-label": "Next" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.nextArrow.apply(null, arguments)
                  },
                },
              },
              [
                _c("app-icon", {
                  staticClass: "menu-arrow",
                  attrs: { name: "arrow-right" },
                }),
              ],
              1
            ),
          ]
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }