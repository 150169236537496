var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("app-breadcrumb", {
        attrs: {
          "page-title": _vm.$t("Badges_and_pills"),
          directory: _vm.$t("ui_elements"),
          icon: "trello",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "card card-with-shadow border-0" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header bg-transparent p-primary d-flex justify-content-between align-items-center",
          },
          [
            _c("h5", { staticClass: "card-title mb-0" }, [
              _vm._v(_vm._s(_vm.$t("badges_contextual"))),
            ]),
            _vm._v(" "),
            _c("copy-button", {
              attrs: {
                id: "badges-contextual",
                value: _vm.badgesContextualValue,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "mb-primary" }, [
            _c("h6", [_vm._v(_vm._s(_vm.$t("large")))]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ui-elements-container" },
              [
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("primary"),
                    className: "badge badge-primary badge-lg mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("secondary"),
                    className: "badge badge-secondary badge-lg mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("success"),
                    className: "badge badge-success badge-lg mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("danger"),
                    className: "badge badge-danger badge-lg mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("warning"),
                    className: "badge badge-warning badge-lg mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("info"),
                    className: "badge badge-info badge-lg mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("dark"),
                    className: "badge badge-dark badge-lg mr-2",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mb-primary" }, [
            _c("h6", [_vm._v(_vm._s(_vm.$t("medium")))]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ui-elements-container" },
              [
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("primary"),
                    className: "badge badge-primary mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("secondary"),
                    className: "badge badge-secondary mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("success"),
                    className: "badge badge-success mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("danger"),
                    className: "badge badge-danger mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("warning"),
                    className: "badge badge-warning mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("info"),
                    className: "badge badge-info mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("dark"),
                    className: "badge badge-dark mr-2",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", {}, [
            _c("h6", [_vm._v(_vm._s(_vm.$t("small")))]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ui-elements-container" },
              [
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("primary"),
                    className: "badge badge-primary badge-sm mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("secondary"),
                    className: "badge badge-secondary badge-sm mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("success"),
                    className: "badge badge-success badge-sm mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("danger"),
                    className: "badge badge-danger badge-sm mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("warning"),
                    className: "badge badge-warning badge-sm mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("info"),
                    className: "badge badge-info badge-sm mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("dark"),
                    className: "badge badge-dark badge-sm mr-2",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card card-with-shadow border-0 mt-4" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header bg-transparent p-primary d-flex justify-content-between align-items-center",
          },
          [
            _c("h5", { staticClass: "card-title mb-0" }, [
              _vm._v(_vm._s(_vm.$t("badges_numeric"))),
            ]),
            _vm._v(" "),
            _c("copy-button", {
              attrs: { id: "badges-numeric", value: _vm.badgesNumericValue },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "mb-primary" }, [
            _c("h6", [_vm._v(_vm._s(_vm.$t("large")))]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ui-elements-container" },
              [
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_one"),
                    className: "badge badge-primary badge-lg mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_two"),
                    className: "badge badge-secondary badge-lg mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_three"),
                    className: "badge badge-success badge-lg mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_four"),
                    className: "badge badge-danger badge-lg mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_five"),
                    className: "badge badge-warning badge-lg mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_six"),
                    className: "badge badge-info badge-lg mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_seven"),
                    className: "badge badge-dark badge-lg mr-2",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mb-primary" }, [
            _c("h6", [_vm._v(_vm._s(_vm.$t("medium")))]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ui-elements-container" },
              [
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_one"),
                    className: "badge badge-primary mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_two"),
                    className: "badge badge-secondary mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_three"),
                    className: "badge badge-success mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_four"),
                    className: "badge badge-danger mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_five"),
                    className: "badge badge-warning mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_six"),
                    className: "badge badge-info mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_seven"),
                    className: "badge badge-dark mr-2",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", {}, [
            _c("h6", [_vm._v(_vm._s(_vm.$t("small")))]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ui-elements-container" },
              [
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_one"),
                    className: "badge badge-primary badge-sm mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_two"),
                    className: "badge badge-secondary badge-sm mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_three"),
                    className: "badge badge-success badge-sm mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_four"),
                    className: "badge badge-danger badge-sm mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_five"),
                    className: "badge badge-warning badge-sm mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_six"),
                    className: "badge badge-info badge-sm mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_seven"),
                    className: "badge badge-dark badge-sm mr-2",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card card-with-shadow border-0 mt-4" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header bg-transparent p-primary d-flex justify-content-between align-items-center",
          },
          [
            _c("h5", { staticClass: "card-title mb-0" }, [
              _vm._v(_vm._s(_vm.$t("pills_contextual"))),
            ]),
            _vm._v(" "),
            _c("copy-button", {
              attrs: {
                id: "pills-contextual",
                value: _vm.pillsContextualValue,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "mb-primary" }, [
            _c("h6", [_vm._v(_vm._s(_vm.$t("large")))]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ui-elements-container" },
              [
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("primary"),
                    className: "badge badge-primary badge-lg badge-pill mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("secondary"),
                    className: "badge badge-secondary badge-lg badge-pill mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("success"),
                    className: "badge badge-success badge-lg badge-pill mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("danger"),
                    className: "badge badge-danger badge-lg badge-pill mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("warning"),
                    className: "badge badge-warning badge-lg badge-pill mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("info"),
                    className: "badge badge-info badge-lg badge-pill mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("dark"),
                    className: "badge badge-dark badge-lg badge-pill mr-2",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mb-primary" }, [
            _c("h6", [_vm._v(_vm._s(_vm.$t("medium")))]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ui-elements-container" },
              [
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("primary"),
                    className: "badge badge-primary badge-pill mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("secondary"),
                    className: "badge badge-secondary badge-pill mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("success"),
                    className: "badge badge-success badge-pill mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("danger"),
                    className: "badge badge-danger badge-pill mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("warning"),
                    className: "badge badge-warning badge-pill mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("info"),
                    className: "badge badge-info badge-pill mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("dark"),
                    className: "badge badge-dark badge-pill mr-2",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", {}, [
            _c("h6", [_vm._v(_vm._s(_vm.$t("small")))]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ui-elements-container" },
              [
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("primary"),
                    className: "badge badge-primary badge-sm badge-pill mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("secondary"),
                    className: "badge badge-secondary badge-sm badge-pill mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("success"),
                    className: "badge badge-success badge-sm badge-pill mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("danger"),
                    className: "badge badge-danger badge-sm badge-pill mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("warning"),
                    className: "badge badge-warning badge-sm badge-pill mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("info"),
                    className: "badge badge-info badge-sm badge-pill mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("dark"),
                    className: "badge badge-dark badge-sm badge-pill mr-2",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card card-with-shadow border-0 mt-4" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header bg-transparent p-primary d-flex justify-content-between align-items-center",
          },
          [
            _c("h5", { staticClass: "card-title mb-0" }, [
              _vm._v(_vm._s(_vm.$t("pills_numeric"))),
            ]),
            _vm._v(" "),
            _c("copy-button", {
              attrs: { id: "pills-numeric", value: _vm.pillsNumericValue },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "mb-primary" }, [
            _c("h6", [_vm._v(_vm._s(_vm.$t("large")))]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ui-elements-container" },
              [
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_one"),
                    className: "badge badge-primary badge-lg badge-pill mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_two"),
                    className: "badge badge-secondary badge-lg badge-pill mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_three"),
                    className: "badge badge-success badge-lg badge-pill mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_four"),
                    className: "badge badge-danger badge-lg badge-pill mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_five"),
                    className: "badge badge-warning badge-lg badge-pill mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_six"),
                    className: "badge badge-info badge-lg badge-pill mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_seven"),
                    className: "badge badge-dark badge-lg badge-pill mr-2",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mb-primary" }, [
            _c("h6", [_vm._v(_vm._s(_vm.$t("medium")))]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ui-elements-container" },
              [
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_one"),
                    className: "badge badge-primary badge-pill mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_two"),
                    className: "badge badge-secondary badge-pill mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_three"),
                    className: "badge badge-success badge-pill mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_four"),
                    className: "badge badge-danger badge-pill mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_five"),
                    className: "badge badge-warning badge-pill mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_six"),
                    className: "badge badge-info badge-pill mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_seven"),
                    className: "badge badge-dark badge-pill mr-2",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", {}, [
            _c("h6", [_vm._v(_vm._s(_vm.$t("small")))]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ui-elements-container" },
              [
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_one"),
                    className: "badge badge-primary badge-sm badge-pill mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_two"),
                    className: "badge badge-secondary badge-sm badge-pill mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_three"),
                    className: "badge badge-success badge-sm badge-pill mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_four"),
                    className: "badge badge-danger badge-sm badge-pill mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_five"),
                    className: "badge badge-warning badge-sm badge-pill mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_six"),
                    className: "badge badge-info badge-sm badge-pill mr-2",
                  },
                }),
                _vm._v(" "),
                _c("app-badge", {
                  attrs: {
                    label: _vm.$t("num_seven"),
                    className: "badge badge-dark badge-sm badge-pill mr-2",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }