var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        "modal-id": _vm.modalId,
        title:
          (this.selectedUrl ? _vm.$t("edit") : _vm.$t("add")) +
          " " +
          _vm.$t("task"),
        preloader: _vm.preloader,
      },
      on: { submit: _vm.submit, "close-modal": _vm.closeModal },
    },
    [
      _c(
        "template",
        { slot: "body" },
        [
          _vm.preloader ? _c("app-overlay-loader") : _vm._e(),
          _vm._v(" "),
          _c(
            "form",
            {
              ref: "form",
              staticClass: "mb-0",
              class: { "loading-opacity": _vm.preloader },
              attrs: {
                "data-url": _vm.selectedUrl ? _vm.selectedUrl : `tasks`,
              },
            },
            [
              _c(
                "div",
                { staticClass: "form-group row align-items-center" },
                [
                  _c(
                    "label",
                    { staticClass: "col-sm-3", attrs: { for: "title" } },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("title")) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("app-input", {
                    staticClass: "col-sm-9",
                    attrs: {
                      id: "title",
                      type: "text",
                      placeholder: _vm.$t("enter_title"),
                      required: true,
                    },
                    model: {
                      value: _vm.formData.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "title", $$v)
                      },
                      expression: "formData.title",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }