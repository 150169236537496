var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.componentLoader
    ? _c(
        "div",
        { staticClass: "position-relative h-100" },
        [_c("app-overlay-loader")],
        1
      )
    : _c(
        "div",
        [
          _vm.preloader
            ? _c("app-overlay-loader")
            : _c(
                "form",
                {
                  ref: "form",
                  staticClass: "mb-0",
                  class: { "loading-opacity": _vm.preloader },
                  attrs: { "data-url": "admin/auth/users/change-settings" },
                },
                [
                  _c("div", { staticClass: "form-group" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-lg-3 col-xl-3 d-flex align-items-center",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "text-left d-block mb-lg-2 mb-xl-0",
                              attrs: { for: "user_first_name" },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.$t("first_name")) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-lg-8 col-xl-8" },
                        [
                          _c("app-input", {
                            attrs: {
                              id: "user_first_name",
                              type: "text",
                              placeholder: _vm.$t("type_first_name"),
                              required: true,
                            },
                            model: {
                              value: _vm.user.loggedInUser.first_name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.user.loggedInUser,
                                  "first_name",
                                  $$v
                                )
                              },
                              expression: "user.loggedInUser.first_name",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-lg-3 col-xl-3 d-flex align-items-center",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "text-left d-block mb-lg-2 mb-xl-0",
                              attrs: { for: "user_last_name" },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.$t("last_name")) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-lg-8 col-xl-8" },
                        [
                          _c("app-input", {
                            attrs: {
                              id: "user_last_name",
                              type: "text",
                              placeholder: _vm.$t("type_last_name"),
                              required: true,
                            },
                            model: {
                              value: _vm.user.loggedInUser.last_name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.user.loggedInUser,
                                  "last_name",
                                  $$v
                                )
                              },
                              expression: "user.loggedInUser.last_name",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-lg-3 col-xl-3 d-flex align-items-center",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "text-left d-block mb-lg-2 mb-xl-0",
                              attrs: { for: "user_email" },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.$t("email")) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-lg-8 col-xl-8" },
                        [
                          _c("app-input", {
                            attrs: {
                              id: "user_email",
                              type: "email",
                              placeholder: _vm.$t("type_your_email"),
                            },
                            model: {
                              value: _vm.user.loggedInUser.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.user.loggedInUser, "email", $$v)
                              },
                              expression: "user.loggedInUser.email",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-lg-3 col-xl-3 d-flex align-items-center",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "text-left d-block mb-lg-2 mb-xl-0",
                            },
                            [_vm._v(_vm._s(_vm.$t("gender")))]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-lg-8 col-xl-8" },
                        [
                          _c("app-input", {
                            attrs: { type: "radio", list: _vm.genderList },
                            model: {
                              value: _vm.user.loggedInUser.gender,
                              callback: function ($$v) {
                                _vm.$set(_vm.user.loggedInUser, "gender", $$v)
                              },
                              expression: "user.loggedInUser.gender",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-lg-3 col-xl-3 d-flex align-items-center",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "text-left d-block mb-lg-2 mb-xl-0",
                              attrs: { for: "user_contact" },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.$t("contact_number")) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-lg-8 col-xl-8" },
                        [
                          _c("app-input", {
                            attrs: {
                              id: "user_contact",
                              type: "tel-input",
                              placeholder: _vm.$t("type_contact_number"),
                            },
                            model: {
                              value: _vm.user.loggedInUser.contact,
                              callback: function ($$v) {
                                _vm.$set(_vm.user.loggedInUser, "contact", $$v)
                              },
                              expression: "user.loggedInUser.contact",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-lg-3 col-xl-3 d-flex align-items-center",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "text-left d-block mb-lg-2 mb-xl-0",
                              attrs: { for: "user_address" },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.$t("address")) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-lg-8 col-xl-8" },
                        [
                          _c("app-input", {
                            attrs: {
                              id: "user_address",
                              type: "textarea",
                              placeholder: _vm.$t("type_address"),
                            },
                            model: {
                              value: _vm.user.loggedInUser.address,
                              callback: function ($$v) {
                                _vm.$set(_vm.user.loggedInUser, "address", $$v)
                              },
                              expression: "user.loggedInUser.address",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-lg-3 col-xl-3 d-flex align-items-center",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "text-left d-block mb-lg-2 mb-xl-0",
                              attrs: { for: "user_date_of_birth" },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.$t("date_of_birth")) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-lg-8 col-xl-8" },
                        [
                          _c("app-input", {
                            attrs: { id: "user_date_of_birth", type: "date" },
                            model: {
                              value: _vm.user.loggedInUser.date_of_birth,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.user.loggedInUser,
                                  "date_of_birth",
                                  $$v
                                )
                              },
                              expression: "user.loggedInUser.date_of_birth",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group mb-0 mt-5" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12 action-buttons" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary mr-2",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.submit.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.$t("save")) +
                                "\n                    "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }