var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("app-breadcrumb", { attrs: { "page-title": _vm.$t("chat") } }),
      _vm._v(" "),
      _c("div", { staticClass: "chat-wrapper" }, [
        _c("div", { staticClass: "chat-contacts" }, [
          _c("div", { staticClass: "search-contact" }, [
            _c(
              "div",
              { staticClass: "search-input-group" },
              [
                _c("app-icon", {
                  staticClass: "size-17 search-icon",
                  attrs: { name: "search" },
                }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchContact,
                      expression: "searchContact",
                    },
                  ],
                  staticClass: "form-control search-contact-input",
                  attrs: { type: "text", placeholder: "Search contact" },
                  domProps: { value: _vm.searchContact },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.searchContact = $event.target.value
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "contact-list" },
            [
              _vm._l(_vm.visibleContacts, function (contact, index) {
                return [
                  _c(
                    "a",
                    {
                      staticClass: "contact",
                      class: { active: _vm.activeIndex === index },
                      attrs: {
                        role: "tab",
                        "data-toggle": "pill",
                        id: `v-pills-tab-${index}`,
                        href: `#v-pills-${index}`,
                        "aria-controls": `v-pills-${index}`,
                        "aria-selected":
                          _vm.activeIndex === index ? "true" : "false",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.changeActive(index, contact)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "contact-icon" }, [
                        _c(
                          "div",
                          { staticClass: "avatars-w-40" },
                          [
                            _c("app-avatar", {
                              attrs: {
                                "alt-text": contact.full_name,
                                img: contact.profile_picture
                                  ? _vm.urlGenerator(
                                      contact.profile_picture.path
                                    )
                                  : contact.profile_picture,
                                shadow: true,
                                title: contact.full_name,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "contact-info" }, [
                        _c("p", { staticClass: "mb-0" }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(contact.full_name) +
                              "\n                            "
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]
              }),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "chat-messages" }, [
          _c(
            "div",
            { staticClass: "tab-content" },
            [
              _vm._l(_vm.contactList, function (contact, index) {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "tab-pane fade",
                      class: { "show active": _vm.activeIndex === index },
                      attrs: {
                        id: `#v-pills-${index}`,
                        role: "tabpanel",
                        "aria-labelledby": `v-pills-tab-${index}`,
                      },
                    },
                    [
                      _c("div", { staticClass: "message-header" }, [
                        _c("div", { staticClass: "contact-title" }, [
                          _c("h4", { staticClass: "mb-0" }, [
                            _vm._v(_vm._s(contact.full_name)),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "contact-user" }, [
                          _c(
                            "div",
                            { staticClass: "avatars-w-40" },
                            [
                              _c("app-avatar", {
                                attrs: {
                                  "alt-text": contact.full_name,
                                  img: contact.profile_picture
                                    ? _vm.urlGenerator(
                                        contact.profile_picture.path
                                      )
                                    : contact.profile_picture,
                                  shadow: true,
                                  title: contact.full_name,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            { name: "chat-scroll", rawName: "v-chat-scroll" },
                          ],
                          staticClass: "message-body",
                        },
                        [
                          _vm._l(_vm.userMessageLists, function (userMessage) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "message",
                                  class: {
                                    "reply-message":
                                      contact.id === userMessage.user.id,
                                  },
                                },
                                [
                                  userMessage.user
                                    ? _c(
                                        "div",
                                        { staticClass: "avatars-w-40" },
                                        [
                                          _c("app-avatar", {
                                            attrs: {
                                              "alt-text":
                                                userMessage.user.full_name,
                                              img: userMessage.user
                                                .profile_picture
                                                ? _vm.urlGenerator(
                                                    userMessage.user
                                                      .profile_picture.path
                                                  )
                                                : userMessage.user
                                                    .profile_picture,
                                              shadow: true,
                                              title: userMessage.user.full_name,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  userMessage.attachments.length
                                    ? _vm._l(
                                        userMessage.attachments,
                                        function (attachment) {
                                          return _c("img", {
                                            staticClass:
                                              "chat-message-image img-thumbnail",
                                            attrs: {
                                              src: `${_vm.urlGenerator(
                                                attachment.path
                                              )}`,
                                              alt: "Not found",
                                            },
                                          })
                                        }
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  userMessage.message
                                    ? _c("div", { staticClass: "text" }, [
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              userMessage.message
                                            ),
                                          },
                                        }),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "chat-date" }, [
                                    _c("span", { staticClass: "text-muted" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm
                                            .moment(userMessage.created_at)
                                            .format(_vm.momentFormattingString)
                                        )
                                      ),
                                    ]),
                                  ]),
                                ],
                                2
                              ),
                            ]
                          }),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "message-editor" }, [
                        _c("div", { staticClass: "attached-file-options" }, [
                          _c("input", {
                            staticClass: "image-input cursor-pointer",
                            attrs: {
                              type: "file",
                              id: `chat-image-upload-${contact.id}`,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.sendImage($event)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "option p-1 text-primary" },
                            [
                              _c("app-icon", {
                                staticClass: "size-18",
                                attrs: { name: "paperclip" },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "message-input" }, [
                          _c("div", { staticClass: "message-input-group" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.messageText,
                                  expression: "messageText",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                "data-emojiable": "true",
                                placeholder: "Type something here...",
                              },
                              domProps: { value: _vm.messageText },
                              on: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.sendMessage()
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.messageText = $event.target.value
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "message-input-append" }, [
                              _c(
                                "div",
                                { staticClass: "btn-group dropdown-emoji" },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn",
                                      attrs: {
                                        type: "button",
                                        "data-toggle": "dropdown",
                                        "aria-haspopup": "true",
                                        "aria-expanded": "false",
                                      },
                                    },
                                    [
                                      _c("app-icon", {
                                        staticClass: "size-15",
                                        attrs: { name: "smile" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "dropdown-menu dropdown-menu-right",
                                    },
                                    _vm._l(_vm.emojiList, function (emoji) {
                                      return _c("a", {
                                        staticClass: "emoji",
                                        attrs: { href: "#" },
                                        domProps: {
                                          innerHTML: _vm._s(emoji.code),
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.selectEmoji(
                                              $event,
                                              emoji
                                            )
                                          },
                                        },
                                      })
                                    }),
                                    0
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "btn btn-send",
                                  class: {
                                    disabled: !_vm.messageText,
                                    "text-primary": _vm.messageText,
                                  },
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.sendMessage()
                                    },
                                  },
                                },
                                [
                                  _c("app-icon", {
                                    staticClass: "size-15",
                                    attrs: { name: "send" },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("a", {
                            staticClass: "text-size-20",
                            attrs: { href: "#" },
                            domProps: { innerHTML: _vm._s(_vm.activeEmoji) },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.sendActiveEmoji()
                              },
                            },
                          }),
                        ]),
                      ]),
                    ]
                  ),
                ]
              }),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "chat-contact-details" },
          [
            _vm._l(_vm.contactList, function (contact, index) {
              return [
                _vm.activeIndex === index
                  ? _c(
                      "div",
                      { staticClass: "d-flex flex-column align-items-center" },
                      [
                        _c(
                          "div",
                          { staticClass: "avatars-w-40" },
                          [
                            _c("app-avatar", {
                              attrs: {
                                "alt-text": contact.full_name,
                                img: contact.profile_picture
                                  ? _vm.urlGenerator(
                                      contact.profile_picture.path
                                    )
                                  : contact.profile_picture,
                                shadow: true,
                                title: contact.full_name,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("p", { staticClass: "mt-2 mb-0" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(contact.full_name) +
                              "\n                    "
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]
            }),
            _vm._v(" "),
            _c("div", { staticClass: "my-4 call-actions" }, [
              _c(
                "button",
                { staticClass: "btn mr-2", attrs: { type: "button" } },
                [
                  _c("app-icon", {
                    staticClass: "size-17 mr-2",
                    attrs: { name: "phone" },
                  }),
                  _vm._v("\n                    Voice Call\n                "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                { staticClass: "btn", attrs: { type: "button" } },
                [
                  _c("app-icon", {
                    staticClass: "size-17 mr-2",
                    attrs: { name: "video" },
                  }),
                  _vm._v("\n                    Video Chat\n                "),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "chat-conversation-action" }, [
              _c(
                "div",
                { staticClass: "search-conversation" },
                [
                  _c("app-icon", {
                    staticClass: "size-15 search-icon",
                    attrs: { name: "search" },
                  }),
                  _vm._v(" "),
                  _c("input", {
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      placeholder: "Search in conversation",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "btn-group dropdown-emoji" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn",
                    attrs: {
                      type: "button",
                      "data-toggle": "dropdown",
                      "aria-haspopup": "true",
                      "aria-expanded": "false",
                    },
                  },
                  [
                    _vm._v(
                      "\n                        Change Emoji\n                        "
                    ),
                    _c("span", {
                      staticClass: "text-size-20",
                      domProps: { innerHTML: _vm._s(_vm.activeEmoji) },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "dropdown-menu dropdown-menu-right" },
                  _vm._l(_vm.emojiList, function (emoji) {
                    return _c("a", {
                      staticClass: "emoji",
                      attrs: { href: "#" },
                      domProps: { innerHTML: _vm._s(emoji.code) },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.setEmoji(emoji.code)
                        },
                      },
                    })
                  }),
                  0
                ),
              ]),
            ]),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }