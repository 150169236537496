var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-fluid p-0" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12 col-sm-12 col-md-6 col-lg-6 col-xl-8" },
        [
          _c("div", {
            staticClass: "back-image",
            style:
              "background-image: url(" +
              _vm.urlGenerator(_vm.configData.company_banner) +
              ")",
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 pl-md-0" },
        [
          _c("div", { staticClass: "login-form d-flex align-items-center" }, [
            _c(
              "form",
              {
                ref: "form",
                staticClass: "sign-in-sign-up-form w-100",
                attrs: { "data-url": "/admin/users/login", action: "store" },
              },
              [
                _c("div", { staticClass: "text-center mb-4" }, [
                  _c("img", {
                    staticClass: "img-fluid logo",
                    attrs: {
                      src: _vm.urlGenerator(_vm.configData.company_logo),
                      alt: "",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "form-group col-12 px-0" }, [
                    _c("h6", { staticClass: "text-center mb-0" }, [
                      _vm._v(_vm._s(_vm.$t("hi_there"))),
                    ]),
                    _vm._v(" "),
                    _c("label", { staticClass: "text-center d-block" }, [
                      _vm._v(_vm._s(_vm.$t("log_in_to_your_dashboard"))),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-row" }, [
                  _c(
                    "div",
                    { staticClass: "form-group col-12 px-0" },
                    [
                      _c("label", { attrs: { for: "login_email" } }, [
                        _vm._v(_vm._s(_vm.$t("email"))),
                      ]),
                      _vm._v(" "),
                      _c("app-input", {
                        attrs: {
                          type: "email",
                          placeholder: _vm.$t("enter_your_email"),
                          required: true,
                        },
                        model: {
                          value: _vm.login.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.login, "email", $$v)
                          },
                          expression: "login.email",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-row" }, [
                  _c(
                    "div",
                    { staticClass: "form-group col-12 px-0" },
                    [
                      _c("label", { attrs: { for: "login_password" } }, [
                        _vm._v(_vm._s(_vm.$t("password"))),
                      ]),
                      _vm._v(" "),
                      _c("app-input", {
                        attrs: {
                          type: "password",
                          "show-password": true,
                          placeholder: _vm.$t("enter_your_password"),
                          required: true,
                        },
                        model: {
                          value: _vm.login.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.login, "password", $$v)
                          },
                          expression: "login.password",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.recaptchaEnable == 1
                  ? _c("div", { staticClass: "form-row" }, [
                      _c(
                        "div",
                        { staticClass: "form-group col-12 px-0" },
                        [
                          _c("re-captcha", {
                            attrs: { "site-key": _vm.siteKey },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "form-row" }, [
                  _c(
                    "div",
                    { staticClass: "form-group col-12 px-0" },
                    [
                      _c("app-load-more", {
                        attrs: {
                          preloader: _vm.preloader,
                          label: _vm.$t("login"),
                          type: "submit",
                          "class-name": "btn btn-primary btn-block text-center",
                        },
                        on: { submit: _vm.submit },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "form-row form-row flex-column flex-md-row justify-content-center justify-content-md-between justify-content-lg-between",
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "bluish-text d-flex align-items-center justify-content-center justify-content-lg-end",
                        attrs: { href: _vm.urlGenerator("/forget-password") },
                      },
                      [
                        _c("app-icon", {
                          staticClass: "pr-2",
                          attrs: { name: "lock" },
                        }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("forgot_password")) +
                            "\n                        "
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.configData.registration === "on"
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "bluish-text d-flex align-items-center justify-content-center justify-content-lg-end",
                            attrs: { href: _vm.urlGenerator("/user/register") },
                          },
                          [
                            _c("app-icon", {
                              staticClass: "pr-2",
                              attrs: { name: "user" },
                            }),
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("register")) +
                                "\n                        "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("p", { staticClass: "text-center mt-5" }, [
                      _vm._v(
                        "\n                                " +
                          _vm._s(
                            _vm.$t("copyright_text") +
                              _vm.configData.company_name
                          ) +
                          "\n                            "
                      ),
                    ]),
                  ]),
                ]),
              ]
            ),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }