<template>
    <div>Pos View</div>
</template>

<script>
export default {
name: "PosView"
}
</script>

<style scoped>

</style>