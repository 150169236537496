var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("app-breadcrumb", {
        attrs: {
          "page-title": _vm.$t("cards"),
          directory: _vm.$t("ui_elements"),
          icon: "trello",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "row mb-primary" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            { staticClass: "d-flex justify-content-between mb-3" },
            [
              _c("h5", [_vm._v(_vm._s(_vm.$t("basic")))]),
              _vm._v(" "),
              _c("copy-button", {
                attrs: { id: "basic-card", value: _vm.basicCardValue },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "card card-with-search border-0 card-with-shadow" },
            [
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "text-justify" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.$t("card_content")) +
                      "\n                    "
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row mb-primary" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            { staticClass: "d-flex justify-content-between mb-3" },
            [
              _c("h5", [_vm._v(_vm._s(_vm.$t("titled")))]),
              _vm._v(" "),
              _c("copy-button", {
                attrs: { id: "titled-card", value: _vm.titledCardValue },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "card card-with-search border-0 card-with-shadow" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "card-header d-flex align-items-center p-primary bg-transparent",
                },
                [
                  _c("h5", { staticClass: "card-title d-inline-block mb-0" }, [
                    _vm._v(_vm._s(_vm.$t("title"))),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "text-justify" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.$t("card_content")) +
                      "\n                    "
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row mb-primary" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            { staticClass: "d-flex justify-content-between mb-3" },
            [
              _c("h5", [_vm._v(_vm._s(_vm.$t("searchable")))]),
              _vm._v(" "),
              _c("copy-button", {
                attrs: {
                  id: "searchable-card",
                  value: _vm.searchableCardValue,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "card card-with-search border-0 card-with-shadow" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "card-header d-flex align-items-center p-primary bg-transparent",
                },
                [
                  _c("h5", { staticClass: "card-title d-inline-block mb-0" }, [
                    _vm._v(_vm._s(_vm.$t("title"))),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "form-group form-group-with-search d-flex align-items-center mb-0 ml-auto",
                    },
                    [_c("app-search", { on: { input: _vm.getSearchValue } })],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "text-justify" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.$t("card_content")) +
                      "\n                    "
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row mb-primary" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            { staticClass: "d-flex justify-content-between mb-3" },
            [
              _c("h5", [_vm._v(_vm._s(_vm.$t("group_by")))]),
              _vm._v(" "),
              _c("copy-button", {
                attrs: { id: "group-by-card", value: _vm.groupByCardValue },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "card card-with-search border-0 card-with-shadow" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "card-header d-flex justify-content-between align-items-center p-primary bg-transparent",
                },
                [
                  _c("h5", { staticClass: "card-title d-inline-block mb-0" }, [
                    _vm._v(_vm._s(_vm.$t("title"))),
                  ]),
                  _vm._v(" "),
                  _c(
                    "ul",
                    {
                      staticClass:
                        "nav tab-filter-menu justify-content-flex-end",
                    },
                    _vm._l(_vm.chartFilterOptions, function (item, index) {
                      return _c("li", { key: index, staticClass: "nav-item" }, [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link py-0",
                            class: [
                              _vm.value == item.id
                                ? "active"
                                : index === 0 && _vm.value === ""
                                ? "active"
                                : "",
                            ],
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                return _vm.getFilterValue(item.id)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(item.value) +
                                "\n                            "
                            ),
                          ]
                        ),
                      ])
                    }),
                    0
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "text-justify" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.$t("card_content")) +
                      "\n                    "
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-6 col-xl-3" }, [
          _c(
            "div",
            { staticClass: "d-flex justify-content-between mb-3" },
            [
              _c("h5", [_vm._v(_vm._s(_vm.$t("card") + _vm.$t("num_one")))]),
              _vm._v(" "),
              _c("copy-button", {
                attrs: {
                  id: "special-card-one",
                  value: _vm.specialOneCardValue,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "card card-with-search border-0 card-with-shadow mb-primary",
            },
            [
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c("app-media-object", {
                    attrs: {
                      "img-url": _vm.imgUrl,
                      "media-title": _vm.$t("media_title"),
                      "media-subtitle": _vm.$t("media_subtitle"),
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-sm-6 col-xl-3" },
          [
            _c(
              "div",
              { staticClass: "d-flex justify-content-between mb-3" },
              [
                _c("h5", [_vm._v(_vm._s(_vm.$t("card") + _vm.$t("num_two")))]),
                _vm._v(" "),
                _c("copy-button", {
                  attrs: {
                    id: "special-card-two",
                    value: _vm.specialTwoCardValue,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("app-widget", {
              staticClass: "mb-primary",
              attrs: {
                type: "app-widget-with-icon",
                label: _vm.$t("total_sales"),
                number: 70,
                icon: "layers",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-sm-6 col-xl-3" },
          [
            _c(
              "div",
              { staticClass: "d-flex justify-content-between mb-3" },
              [
                _c("h5", [
                  _vm._v(_vm._s(_vm.$t("card") + _vm.$t("num_three"))),
                ]),
                _vm._v(" "),
                _c("copy-button", {
                  attrs: {
                    id: "special-card-three",
                    value: _vm.specialThreeCardValue,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("app-widget", {
              staticClass: "mb-primary",
              attrs: {
                type: "app-widget-without-icon",
                label: _vm.$t("total_loss"),
                number: 10,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-sm-6 col-xl-3" },
          [
            _c(
              "div",
              { staticClass: "d-flex justify-content-between mb-3" },
              [
                _c("h5", [_vm._v(_vm._s(_vm.$t("card") + _vm.$t("num_four")))]),
                _vm._v(" "),
                _c("copy-button", {
                  attrs: {
                    id: "special-card-four",
                    value: _vm.specialFourCardValue,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("app-widget", {
              staticClass: "mb-primary",
              attrs: {
                type: "app-widget-with-circle",
                label: _vm.$t("total_profit"),
                number: 60,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }