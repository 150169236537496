var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card card-with-shadow border-0 mb-primary" },
    [
      _c("div", { staticClass: "card-header p-primary bg-transparent" }, [
        _c("h5", { staticClass: "card-title m-0" }, [
          _vm._v(_vm._s(_vm.$t("multiple_files_upload"))),
        ]),
      ]),
      _vm._v(" "),
      _vm.preloader ? _c("app-overlay-loader") : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c(
          "form",
          {
            ref: "form",
            staticClass: "mb-0",
            class: { "loading-opacity": _vm.preloader },
            attrs: { "data-url": "/form/multiple-files" },
          },
          [
            _c("div", { staticClass: "form-group row mb-0" }, [
              _c("label", { staticClass: "col-sm-2" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.$t("dropzone")) +
                    "\n                "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-sm-8" },
                [
                  _c("app-input", {
                    attrs: { type: "dropzone" },
                    model: {
                      value: _vm.dropzone_files,
                      callback: function ($$v) {
                        _vm.dropzone_files = $$v
                      },
                      expression: "dropzone_files",
                    },
                  }),
                  _vm._v(" "),
                  _c("small", { staticClass: "text-muted font-italic" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("recommended_dropzone_file_validation")) +
                        "\n                    "
                    ),
                  ]),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mt-5 action-buttons" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary mr-2",
                  attrs: { type: "submit" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("upload")) +
                      "\n                "
                  ),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }