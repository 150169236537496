var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "col-12 mb-primary" }, [
    _c(
      "div",
      { staticClass: "card card-with-shadow border-0 h-100" },
      [
        _c("div", { staticClass: "card-header p-primary bg-transparent" }, [
          _c("h5", { staticClass: "card-title m-0" }, [
            _vm._v(_vm._s(_vm.$t("form_grid"))),
          ]),
        ]),
        _vm._v(" "),
        _vm.preloader ? _c("app-overlay-loader") : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c(
            "form",
            {
              ref: "form",
              staticClass: "mb-0",
              class: { "loading-opacity": _vm.preloader },
              attrs: { "data-url": "/crud" },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-6" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", [_vm._v(_vm._s(_vm.$t("name")))]),
                      _vm._v(" "),
                      _c("app-input", {
                        attrs: {
                          type: "text",
                          required: true,
                          placeholder: _vm.$t("type_name"),
                        },
                        model: {
                          value: _vm.inputs.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputs, "name", $$v)
                          },
                          expression: "inputs.name",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-6" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", [_vm._v(_vm._s(_vm.$t("email")))]),
                      _vm._v(" "),
                      _c("app-input", {
                        attrs: {
                          type: "email",
                          required: true,
                          placeholder: _vm.$t("type_your_email"),
                        },
                        model: {
                          value: _vm.inputs.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputs, "email", $$v)
                          },
                          expression: "inputs.email",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-6" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", [_vm._v(_vm._s(_vm.$t("contact_number")))]),
                      _vm._v(" "),
                      _c("app-input", {
                        attrs: {
                          type: "tel-input",
                          placeholder: _vm.$t("type_contact_number"),
                        },
                        model: {
                          value: _vm.inputs.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputs, "phone", $$v)
                          },
                          expression: "inputs.phone",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-6" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", [_vm._v(_vm._s(_vm.$t("age")))]),
                      _vm._v(" "),
                      _c("app-input", {
                        attrs: {
                          type: "number",
                          "max-number": 100,
                          placeholder: _vm.$t("type_age"),
                        },
                        model: {
                          value: _vm.inputs.age,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputs, "age", $$v)
                          },
                          expression: "inputs.age",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-6" }, [
                  _c(
                    "div",
                    { staticClass: "form-group mb-2 mb-md-0" },
                    [
                      _c("label", [_vm._v(_vm._s(_vm.$t("status")))]),
                      _vm._v(" "),
                      _c("app-input", {
                        attrs: {
                          type: "select",
                          required: true,
                          list: _vm.statusLists,
                        },
                        model: {
                          value: _vm.inputs.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputs, "status", $$v)
                          },
                          expression: "inputs.status",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-6" }, [
                  _c(
                    "div",
                    { staticClass: "form-group mb-0" },
                    [
                      _c("label", [_vm._v(_vm._s(_vm.$t("gender")))]),
                      _vm._v(" "),
                      _c("app-input", {
                        staticClass: "mt-2 pt-1",
                        attrs: {
                          type: "radio",
                          list: _vm.genderLists,
                          required: true,
                        },
                        model: {
                          value: _vm.inputs.gender,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputs, "gender", $$v)
                          },
                          expression: "inputs.gender",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mt-5 action-buttons" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary mr-2",
                    attrs: { type: "submit" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.submit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("submit")) +
                        "\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "submit" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.clear.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("clear")) +
                        "\n                    "
                    ),
                  ]
                ),
              ]),
            ]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }