var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {}, [
    _c("a", { attrs: { href: _vm.document, target: "_blank" } }, [
      _vm._v(_vm._s(_vm.truncateedText(_vm.document))),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }