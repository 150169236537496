var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c("form", { ref: "form" }, [
        _vm.template
          ? _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", [_vm._v(_vm._s(_vm.$t("contents")))]),
                _vm._v(" "),
                _c("app-input", {
                  attrs: { id: "database-template-title", required: true },
                  model: {
                    value: _vm.template.content,
                    callback: function ($$v) {
                      _vm.$set(_vm.template, "content", $$v)
                    },
                    expression: "template.content",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group text-center" },
          _vm._l(Object.keys(_vm.all_tags), function (t) {
            return _c(
              "button",
              {
                staticClass:
                  "btn btn-sm btn-primary px-3 py-1 margin-left-2 mt-2",
                attrs: {
                  type: "button",
                  "data-toggle": "tooltip",
                  "data-placement": "top",
                  title: _vm.tags[t],
                },
                on: {
                  click: function ($event) {
                    return _vm.insertAtCaret("database-template-title", t)
                  },
                },
              },
              [_vm._v(_vm._s(t))]
            )
          }),
          0
        ),
      ])
    : _c("div", [_c("app-pre-loader")], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }