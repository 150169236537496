var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("app-avatars-group", {
    attrs: {
      "avatars-group-class": _vm.avatarsGroupClass,
      name: _vm.avatarsGroupName + _vm.index,
      shadow: false,
      border: true,
      avatars: _vm.users,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }