var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.preloader
    ? _c("app-overlay-loader")
    : _c(
        "form",
        {
          ref: "form",
          staticClass: "mb-0",
          class: { "loading-opacity": _vm.preloader },
          attrs: { "data-url": "/sms-settings" },
        },
        [
          _c("div", { staticClass: "form-group row align-items-center" }, [
            _c(
              "label",
              {
                staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                attrs: { for: "smsSettingsSmsDriver" },
              },
              [
                _vm._v(
                  "\n            " + _vm._s(_vm.$t("sms_driver")) + "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-lg-8 col-xl-8" },
              [
                _c("app-input", {
                  attrs: {
                    id: "smsSettingsSmsDriver",
                    type: "select",
                    required: true,
                    list: _vm.driverList,
                  },
                  model: {
                    value: _vm.smsSettings.sms_driver,
                    callback: function ($$v) {
                      _vm.$set(_vm.smsSettings, "sms_driver", $$v)
                    },
                    expression: "smsSettings.sms_driver",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.smsSettings.sms_driver === "vonage"
            ? _c("fieldset", [
                _c(
                  "div",
                  { staticClass: "form-group row align-items-center" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                        attrs: { for: "smsSettingsSmsSentFrom" },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.$t("sms_send_from_name_or_phone_number")
                            ) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-lg-8 col-xl-8" },
                      [
                        _c("app-input", {
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t(
                              "type_a_name_or_number_you_want_to_sent_sms_from"
                            ),
                            required: true,
                          },
                          model: {
                            value: _vm.smsSettings.sms_sent_from,
                            callback: function ($$v) {
                              _vm.$set(_vm.smsSettings, "sms_sent_from", $$v)
                            },
                            expression: "smsSettings.sms_sent_from",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group row align-items-center" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                        attrs: { for: "smsSettingsKey" },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("api_key")) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-lg-8 col-xl-8" },
                      [
                        _c("app-input", {
                          attrs: {
                            id: "smsSettingsKey",
                            type: "text",
                            placeholder: _vm.$t("enter_vonage_api_key"),
                            required: true,
                          },
                          model: {
                            value: _vm.smsSettings.api_key,
                            callback: function ($$v) {
                              _vm.$set(_vm.smsSettings, "api_key", $$v)
                            },
                            expression: "smsSettings.api_key",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group row align-items-center" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                        attrs: { for: "smsSettingsSecretKey" },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("api_secret")) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-lg-8 col-xl-8" },
                      [
                        _c("app-input", {
                          attrs: {
                            id: "smsSettingsSecretKey",
                            type: "text",
                            placeholder: _vm.$t("enter_vonage_api_secret"),
                            required: true,
                          },
                          model: {
                            value: _vm.smsSettings.api_secret,
                            callback: function ($$v) {
                              _vm.$set(_vm.smsSettings, "api_secret", $$v)
                            },
                            expression: "smsSettings.api_secret",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group row align-items-center" },
                  [
                    _c("label", { staticClass: "col-lg-3 col-xl-3 mb-lg-0" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("test_number")) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-lg-8 col-xl-8" },
                      [
                        _c("app-input", {
                          attrs: {
                            type: "tel-input",
                            placeholder: _vm.$t("type_test_number"),
                          },
                          model: {
                            value: _vm.smsSettings.test_number,
                            callback: function ($$v) {
                              _vm.$set(_vm.smsSettings, "test_number", $$v)
                            },
                            expression: "smsSettings.test_number",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.smsSettings.sms_driver === "twilio"
            ? _c("fieldset", [
                _c(
                  "div",
                  { staticClass: "form-group row align-items-center" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                        attrs: { for: "smsSettingsKey" },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("account_sid")) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-lg-8 col-xl-8" },
                      [
                        _c("app-input", {
                          attrs: {
                            id: "smsSettingsKey",
                            type: "text",
                            placeholder: _vm.$t("enter_account_sid"),
                            required: true,
                          },
                          model: {
                            value: _vm.smsSettings.account_sid,
                            callback: function ($$v) {
                              _vm.$set(_vm.smsSettings, "account_sid", $$v)
                            },
                            expression: "smsSettings.account_sid",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group row align-items-center" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                        attrs: { for: "smsSettingsSecretKey" },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("auth_token")) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-lg-8 col-xl-8" },
                      [
                        _c("app-input", {
                          attrs: {
                            id: "smsSettingsSecretKey",
                            type: "text",
                            placeholder: _vm.$t("enter_auth_token"),
                            required: true,
                          },
                          model: {
                            value: _vm.smsSettings.auth_token,
                            callback: function ($$v) {
                              _vm.$set(_vm.smsSettings, "auth_token", $$v)
                            },
                            expression: "smsSettings.auth_token",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group row align-items-center" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                        attrs: { for: "smsSettingsSecretKey" },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("twilio_number")) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-lg-8 col-xl-8" },
                      [
                        _c("app-input", {
                          attrs: {
                            id: "smsSettingsSecretKey",
                            type: "text",
                            placeholder: _vm.$t("enter_twilio_number"),
                            required: true,
                          },
                          model: {
                            value: _vm.smsSettings.twilio_number,
                            callback: function ($$v) {
                              _vm.$set(_vm.smsSettings, "twilio_number", $$v)
                            },
                            expression: "smsSettings.twilio_number",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group row align-items-center" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                        attrs: { for: "smsSettingsSecretKey" },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("twilio_test_number")) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-lg-8 col-xl-8" },
                      [
                        _c("app-input", {
                          attrs: {
                            id: "smsSettingsSecretKey",
                            type: "text",
                            placeholder: _vm.$t("enter_twilio_test_number"),
                            required: true,
                          },
                          model: {
                            value: _vm.smsSettings.twilio_test_number,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.smsSettings,
                                "twilio_test_number",
                                $$v
                              )
                            },
                            expression: "smsSettings.twilio_test_number",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "mt-5 action-buttons" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary mr-2",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n            " + _vm._s(_vm.$t("save")) + "\n        ")]
            ),
          ]),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }