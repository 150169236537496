var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("app-breadcrumb", {
        attrs: {
          "page-title": _vm.$t("tabs"),
          directory: _vm.$t("ui_elements"),
          icon: "trello",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb-primary" },
        [
          _c(
            "div",
            { staticClass: "d-flex justify-content-between mb-3" },
            [
              _c("h5", [_vm._v(_vm._s(_vm.$t("tab_horizontal")))]),
              _vm._v(" "),
              _c("copy-button", {
                attrs: { id: "tab-horizontal", value: _vm.tabsHorizontalValue },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("app-tab", {
            attrs: { tabs: _vm.tabsHorizontal, type: "horizontal" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb-primary" },
        [
          _c(
            "div",
            { staticClass: "d-flex justify-content-between mb-3" },
            [
              _c("h5", [_vm._v(_vm._s(_vm.$t("tab_vertical")))]),
              _vm._v(" "),
              _c("copy-button", {
                attrs: { id: "tab-vertical", value: _vm.tabsVerticalValue },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("app-tab", { attrs: { tabs: _vm.tabsVertical, icon: _vm.icon } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb-primary" },
        [
          _c(
            "div",
            { staticClass: "d-flex justify-content-between mb-3" },
            [
              _c("h5", [_vm._v(_vm._s(_vm.$t("group_tab")))]),
              _vm._v(" "),
              _c("copy-button", {
                attrs: { id: "custom-tab", value: _vm.customTabValue },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("app-tab-group", { attrs: { tabs: _vm.customTabs } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }