var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content-wrapper" }, [
    _c("div", { staticClass: "container py-4 bg-white" }, [
      _c("div", { staticClass: "row" }, [
        _vm.jobPostSetting?.style[_vm.mode]?.jobcard.show
          ? _c("div", { staticClass: "col-12 col-md-4" }, [
              _c("div", { staticClass: "card bg-white" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "d-flex justify-content-center" }, [
                    _c("img", {
                      staticClass: "img-80",
                      attrs: { src: _vm.icon, alt: "icon" },
                    }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-primary text-white btn-block my-3",
                      attrs: { href: "#" },
                    },
                    [_vm._v(_vm._s(_vm.$t("apply_now")))]
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _c("div", { staticClass: "my-2 border-bottom" }, [
                      _c("small", { staticClass: "text-muted" }, [
                        _vm._v(_vm._s(_vm.$t("location"))),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.data.is_remote ? _vm.$t("remote") + "," : ""
                          ) +
                            " " +
                            _vm._s(_vm.data.location.address)
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "my-2 border-bottom" }, [
                      _c("small", { staticClass: "text-muted" }, [
                        _vm._v(_vm._s(_vm.$t("salary"))),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm.data.salary) +
                            _vm._s(
                              _vm.data.max_salary
                                ? ` - ${_vm.data.max_salary}`
                                : ""
                            ) +
                            "/" +
                            _vm._s(_vm.$t("year"))
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "my-2 border-bottom" }, [
                      _c("small", { staticClass: "text-muted" }, [
                        _vm._v(_vm._s(_vm.$t("job_type"))),
                      ]),
                      _vm._v(" "),
                      _c("p", [_vm._v(_vm._s(_vm.data.job_type.name))]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "my-2 border-bottom" }, [
                      _c("small", { staticClass: "text-muted" }, [
                        _vm._v(_vm._s(_vm.$t("job_posted"))),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm
                              .moment(_vm.data.created_at)
                              .format("MMMM Do, YYYY")
                          )
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-outline-secondary btn-block",
                        attrs: { href: "#" },
                      },
                      [_vm._v(_vm._s(_vm.$t("view_all_jobs")))]
                    ),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-md-8" }, [
          _c("div", { staticClass: "preview-content" }, [
            _c(
              "div",
              { staticClass: "preview" },
              [
                _vm.jobPostSetting?.style[_vm.mode]?.name.show
                  ? _c("div", {
                      staticClass: "mb-3",
                      style: _vm.jobPostSetting?.style[_vm.mode]?.name,
                      domProps: { innerHTML: _vm._s(_vm.data.name) },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.jobPostSetting?.style[_vm.mode]?.summery.show
                  ? _c(
                      "div",
                      {
                        staticClass: "text-muted d-flex flex-wrap gap-x-3 mb-5",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex gap-x-1 align-items-center" },
                          [
                            _c("app-icon", {
                              staticClass: "size-12",
                              attrs: { name: "map-pin" },
                            }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.data.is_remote
                                    ? _vm.$t("remote") + ","
                                    : ""
                                ) +
                                " " +
                                _vm._s(_vm.data.location.address)
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "d-flex gap-x-1 align-items-center" },
                          [
                            _c("app-icon", {
                              staticClass: "size-12",
                              attrs: { name: "dollar-sign" },
                            }),
                            _vm._v(
                              " " +
                                _vm._s(_vm.data.salary) +
                                _vm._s(
                                  _vm.data.max_salary
                                    ? ` - ${_vm.data.max_salary}`
                                    : ""
                                ) +
                                "/" +
                                _vm._s(_vm.$t("year"))
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "d-flex gap-x-1 align-items-center" },
                          [
                            _c("app-icon", {
                              staticClass: "size-12",
                              attrs: { name: "type" },
                            }),
                            _vm._v(_vm._s(_vm.data.job_type.name)),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "d-flex gap-x-1 align-items-center" },
                          [
                            _c("app-icon", {
                              staticClass: "size-12",
                              attrs: { name: "calendar" },
                            }),
                            _vm._v(
                              _vm._s(
                                _vm
                                  .moment(_vm.data.created_at)
                                  .format("MMMM Do, YYYY")
                              )
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(
                  Object.keys(_vm.data.description),
                  function (key, index) {
                    return [
                      _vm.jobPostSetting?.style[_vm.mode]
                        ? [
                            _vm.jobPostSetting?.style[_vm.mode][key].show
                              ? _c(
                                  "div",
                                  {
                                    key: `description-${index}`,
                                    staticClass: "mb-5",
                                  },
                                  [
                                    _c("h5", [
                                      _vm._v(
                                        _vm._s(_vm.data?.description[key].title)
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.data.description[key].value
                                        ),
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ]
                  }
                ),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 col-md-4" }, [
              _c(
                "a",
                {
                  staticClass: "btn btn-primary text-white btn-block my-3",
                  attrs: { href: "#" },
                },
                [_vm._v(_vm._s(_vm.$t("apply_now")))]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "my-3" }, [
      _c("div", { staticClass: "d-flex gap-x-2" }, [
        _c("a", { attrs: { href: "#", target: "_blank" } }, [
          _c("img", {
            staticClass: "img-20",
            attrs: {
              src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjcWcS7oWyHiB4MkHcm7GcCDRl9bXpoZIcr_Da8iuafov6-VHcGVJRz7kKoquFT1T8hYA&usqp=CAU",
              alt: "",
            },
          }),
        ]),
        _vm._v(" "),
        _c("a", { attrs: { href: "#", target: "_blank" } }, [
          _c("img", {
            staticClass: "img-20",
            attrs: {
              src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjTiK-AtWaobNPgsInlVW4DY03H6Wu4NIybNKRW5KGiRQNWeOAzpp4WtOYWQpzptWIvFw&usqp=CAU",
              alt: "",
            },
          }),
        ]),
        _vm._v(" "),
        _c("a", { attrs: { href: "#", target: "_blank" } }, [
          _c("img", {
            staticClass: "img-20",
            attrs: {
              src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTn8geLtKmZJmBtu0U4FaKjx4jSzVenTQWTkhoHgA81UbtPalcMpcCcIdGeaVsKXclQ6Xs&usqp=CAU",
              alt: "",
            },
          }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }