var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    _vm._l(_vm.data, function (profile, rowIndex) {
      return _vm.data.length > 0
        ? _c(
            "div",
            {
              key: "row" + rowIndex,
              staticClass: "col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-primary",
            },
            [
              _c("profile-card", {
                attrs: { id: _vm.id, profile: profile, actions: _vm.actions },
                on: { "action-grid-view-table": _vm.getAction },
              }),
            ],
            1
          )
        : _vm._e()
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }