var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        "modal-id": _vm.notificationSettings.notificationSettingsUpdateModalId,
        title: _vm.modalTitle,
        preloader: _vm.preloader,
        "modal-scroll": false,
      },
      on: { submit: _vm.submit, "close-modal": _vm.closeModal },
    },
    [
      _c(
        "template",
        { slot: "body" },
        [
          _vm.initLoader
            ? _c("app-overlay-loader")
            : _c(
                "div",
                [
                  _vm.preloader ? _c("app-overlay-loader") : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "form",
                    {
                      ref: "form",
                      class: { "loading-opacity": _vm.preloader },
                      attrs: {
                        method: "post",
                        "data-url": `admin/app/notification-settings/${_vm.notificationSettings.rowData.id}`,
                      },
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("h6", [
                                _vm._v(_vm._s(_vm.$t("notification_channel"))),
                              ]),
                              _vm._v(" "),
                              _c("app-input", {
                                attrs: {
                                  type: "multi-select",
                                  list: _vm.makeChannelArr,
                                  "list-value-field": "name",
                                  required: true,
                                  isAnimatedDropdown: true,
                                },
                                model: {
                                  value: _vm.settings.notify_by,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.settings, "notify_by", $$v)
                                  },
                                  expression: "settings.notify_by",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12" }, [
                          _c("h6", [
                            _vm._v(_vm._s(_vm.$t("notification_audiences"))),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c("label", [_vm._v(_vm._s(_vm.$t("roles")))]),
                            _c("br"),
                            _vm._v(" "),
                            _c("app-input", {
                              attrs: {
                                type: "multi-select",
                                list: _vm.notificationSettings.roles,
                                "list-value-field": "name",
                                "error-message": _vm.errorMessage,
                                isAnimatedDropdown: true,
                              },
                              on: { input: _vm.clearErrorMessage },
                              model: {
                                value: _vm.settings.roles,
                                callback: function ($$v) {
                                  _vm.$set(_vm.settings, "roles", $$v)
                                },
                                expression: "settings.roles",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c("label", [_vm._v(_vm._s(_vm.$t("users")))]),
                            _c("br"),
                            _vm._v(" "),
                            _c("app-input", {
                              attrs: {
                                type: "multi-select",
                                list: _vm.notificationSettings.users,
                                "list-value-field": "full_name",
                                "error-message": _vm.errorMessage,
                                isAnimatedDropdown: true,
                              },
                              on: { input: _vm.clearErrorMessage },
                              model: {
                                value: _vm.settings.users,
                                callback: function ($$v) {
                                  _vm.$set(_vm.settings, "users", $$v)
                                },
                                expression: "settings.users",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }