var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("app-breadcrumb", { attrs: { "page-title": _vm.$t("form_wizard") } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card p-primary border-0" },
        [_c("app-form-wizard", { attrs: { tabs: _vm.tabs } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }