var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("app-table", {
        attrs: { id: _vm.customFieldTableId, options: _vm.customField },
        on: { action: _vm.getAction },
      }),
      _vm._v(" "),
      _vm.isModalActive
        ? _c("app-custom-table-modal", {
            attrs: {
              tableId: _vm.customFieldTableId,
              "selected-url": _vm.customFiledSelectedUrl,
            },
            on: { "close-modal": _vm.closeModal },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.confirmationModalActive
        ? _c("app-confirmation-modal", {
            attrs: { "modal-id": "custom-field-delete-modal" },
            on: { confirmed: _vm.confirmed, cancelled: _vm.cancelled },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }