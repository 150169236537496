var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "app-modal",
    {
      attrs: {
        "modal-alignment": "top",
        "modal-id": `dynamic-tab-add-edit-modal-${_vm.tableData.id}`,
        "modal-size": "large",
      },
      on: { "close-modal": _vm.closeModal },
    },
    [
      _c("template", { slot: "header" }, [
        _vm.selectedUrl
          ? _c("h5", { staticClass: "modal-title" }, [
              _vm._v(_vm._s(_vm.$t("edit") + " " + _vm.$t(_vm.tableData.name))),
            ])
          : _c("h5", { staticClass: "modal-title" }, [
              _vm._v(_vm._s(_vm.$t("add") + " " + _vm.$t(_vm.tableData.name))),
            ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close outline-none",
            attrs: { "aria-label": "Close", type: "button" },
            on: { click: _vm.closeModal },
          },
          [_c("span", [_c("app-icon", { attrs: { name: "x" } })], 1)]
        ),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "body" },
        [
          _vm.preloader ? _c("app-overlay-loader") : _vm._e(),
          _vm._v(" "),
          _vm.formData.fields
            ? _c(
                "form",
                {
                  ref: "form",
                  class: { "loading-opacity": _vm.preloader },
                  attrs: {
                    "data-url": _vm.selectedUrl
                      ? _vm.selectedUrl
                      : "admin/app/custom-table/values",
                  },
                },
                [
                  _vm.validationError
                    ? _c(
                        "div",
                        { staticClass: "alert alert-warning text-dark" },
                        [
                          _c("p", { staticClass: "m-0" }, [
                            _vm._v(_vm._s(_vm.validationError)),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.tableData.fields, function (field, index) {
                    return _c(
                      "div",
                      {
                        key: `custom-form-item-${index}`,
                        staticClass: "form-group row",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass:
                              "mb-0 col-sm-3 d-flex align-items-center",
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(field.name) +
                                " "
                            ),
                            Number(field.required)
                              ? _c("span", { staticClass: "text-danger" }, [
                                  _vm._v("*"),
                                ])
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-sm-9" },
                          [
                            _c("app-input", {
                              attrs: {
                                "field-name": field.name,
                                radioCheckboxName: field.name,
                                placeholder: "Enter " + field.name,
                                list: _vm.genList(field),
                                type: field.custom_field_type.name,
                                required: Number(field.required) ? true : false,
                              },
                              model: {
                                value: _vm.formData.fields[index].value,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData.fields[index],
                                    "value",
                                    $$v
                                  )
                                },
                                expression: "formData.fields[index].value",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("template", { slot: "footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-secondary mr-2",
            attrs: { "data-dismiss": "modal", type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.closeModal.apply(null, arguments)
              },
            },
          },
          [_vm._v("\n            " + _vm._s(_vm.$t("cancel")) + "\n        ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.submit.apply(null, arguments)
              },
            },
          },
          [
            _vm.loading
              ? _c("span", { staticClass: "w-100" }, [
                  _c("span", { staticClass: "spinner-bounce" }, [
                    _c("span", { staticClass: "bounce1 mr-1" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "bounce2 mr-1" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "bounce3 mr-1" }),
                  ]),
                ])
              : [_vm._v(_vm._s(_vm.$t("save")))],
          ],
          2
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }