var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("app-breadcrumb", {
        attrs: {
          "page-title": _vm.$t("avatars"),
          directory: _vm.$t("ui_elements"),
          icon: "trello",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "card card-with-shadow border-0" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header bg-transparent p-primary d-flex justify-content-between align-items-center",
          },
          [
            _c("h5", { staticClass: "card-title mb-0" }, [
              _vm._v(_vm._s(_vm.$t("default"))),
            ]),
            _vm._v(" "),
            _c("copy-button", {
              attrs: { id: "default-avatar", value: _vm.defaultAvatarValue },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-start align-items-center flex-wrap",
            },
            [
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-100",
                  img: _vm.img,
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-90",
                  img: _vm.img,
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-80",
                  img: _vm.img,
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-70",
                  img: _vm.img,
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-60",
                  img: _vm.img,
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-50",
                  img: _vm.img,
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-40",
                  img: _vm.img,
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-30",
                  img: _vm.img,
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-20",
                  img: _vm.img,
                  "alt-text": _vm.$t("not_found"),
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card card-with-shadow border-0 mt-4" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header bg-transparent p-primary d-flex justify-content-between align-items-center",
          },
          [
            _c("h5", { staticClass: "card-title mb-0" }, [
              _vm._v(_vm._s(_vm.$t("default_shadow"))),
            ]),
            _vm._v(" "),
            _c("copy-button", {
              attrs: {
                id: "default-shadow-avatar",
                value: _vm.defaultShadowAvatarValue,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-start align-items-center flex-wrap",
            },
            [
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-100",
                  img: _vm.img,
                  shadow: true,
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-90",
                  img: _vm.img,
                  shadow: true,
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-80",
                  img: _vm.img,
                  shadow: true,
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-70",
                  img: _vm.img,
                  shadow: true,
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-60",
                  img: _vm.img,
                  shadow: true,
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-50",
                  img: _vm.img,
                  shadow: true,
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-40",
                  img: _vm.img,
                  shadow: true,
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-30",
                  img: _vm.img,
                  shadow: true,
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-20",
                  img: _vm.img,
                  shadow: true,
                  "alt-text": _vm.$t("not_found"),
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card card-with-shadow border-0 mt-4" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header bg-transparent p-primary d-flex justify-content-between align-items-center",
          },
          [
            _c("h5", { staticClass: "card-title mb-0" }, [
              _vm._v(_vm._s(_vm.$t("default_shadow_and_border"))),
            ]),
            _vm._v(" "),
            _c("copy-button", {
              attrs: {
                id: "default-shadow-border-avatar",
                value: _vm.defaultShadowBorderAvatarValue,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-start align-items-center flex-wrap",
            },
            [
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-100",
                  img: _vm.img,
                  shadow: true,
                  border: true,
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-90",
                  img: _vm.img,
                  shadow: true,
                  border: true,
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-80",
                  img: _vm.img,
                  shadow: true,
                  border: true,
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-70",
                  img: _vm.img,
                  shadow: true,
                  border: true,
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-60",
                  img: _vm.img,
                  shadow: true,
                  border: true,
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-50",
                  img: _vm.img,
                  shadow: true,
                  border: true,
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-40",
                  img: _vm.img,
                  shadow: true,
                  border: true,
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-30",
                  img: _vm.img,
                  shadow: true,
                  border: true,
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-20",
                  img: _vm.img,
                  shadow: true,
                  border: true,
                  "alt-text": _vm.$t("not_found"),
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card card-with-shadow border-0 mt-4" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header bg-transparent p-primary d-flex justify-content-between align-items-center",
          },
          [
            _c("h5", { staticClass: "card-title mb-0" }, [
              _vm._v(_vm._s(_vm.$t("active"))),
            ]),
            _vm._v(" "),
            _c("copy-button", {
              attrs: { id: "active-avatar", value: _vm.activeAvatarValue },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-start align-items-center flex-wrap",
            },
            [
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-100",
                  img: _vm.img,
                  status: "success",
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-90",
                  img: _vm.img,
                  status: "success",
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-80",
                  img: _vm.img,
                  status: "success",
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-70",
                  img: _vm.img,
                  status: "success",
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-60",
                  img: _vm.img,
                  status: "success",
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-50",
                  img: _vm.img,
                  status: "success",
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-40",
                  img: _vm.img,
                  status: "success",
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-30",
                  img: _vm.img,
                  status: "success",
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-20",
                  img: _vm.img,
                  status: "success",
                  "alt-text": _vm.$t("not_found"),
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card card-with-shadow border-0 mt-4" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header bg-transparent p-primary d-flex justify-content-between align-items-center",
          },
          [
            _c("h5", { staticClass: "card-title mb-0" }, [
              _vm._v(_vm._s(_vm.$t("inactive"))),
            ]),
            _vm._v(" "),
            _c("copy-button", {
              attrs: { id: "inactive-avatar", value: _vm.inactiveAvatarValue },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-start align-items-center flex-wrap",
            },
            [
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-100",
                  img: _vm.img,
                  status: "secondary",
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-90",
                  img: _vm.img,
                  status: "secondary",
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-80",
                  img: _vm.img,
                  status: "secondary",
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-70",
                  img: _vm.img,
                  status: "secondary",
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-60",
                  img: _vm.img,
                  status: "secondary",
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-50",
                  img: _vm.img,
                  status: "secondary",
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-40",
                  img: _vm.img,
                  status: "secondary",
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-30",
                  img: _vm.img,
                  status: "secondary",
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-20",
                  img: _vm.img,
                  status: "secondary",
                  "alt-text": _vm.$t("not_found"),
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card card-with-shadow border-0 mt-4" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header bg-transparent p-primary d-flex justify-content-between align-items-center",
          },
          [
            _c("h5", { staticClass: "card-title mb-0" }, [
              _vm._v(_vm._s(_vm.$t("initials"))),
            ]),
            _vm._v(" "),
            _c("copy-button", {
              attrs: { id: "initial-avatar", value: _vm.initialAvatarValue },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-start align-items-center flex-wrap",
            },
            [
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-100",
                  img: "",
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-90",
                  img: "",
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-80",
                  img: "",
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-70",
                  img: "",
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-60",
                  img: "",
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-50",
                  img: "",
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-40",
                  img: "",
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-30",
                  img: "",
                  "alt-text": _vm.$t("not_found"),
                },
              }),
              _vm._v(" "),
              _c("app-avatar", {
                staticClass: "mr-2",
                attrs: {
                  title: _vm.$t("avatars"),
                  "avatar-class": "avatars-w-20",
                  img: "",
                  "alt-text": _vm.$t("not_found"),
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card card-with-shadow border-0 mt-4" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header bg-transparent p-primary d-flex justify-content-between align-items-center",
          },
          [
            _c("h5", { staticClass: "card-title mb-0" }, [
              _vm._v(_vm._s(_vm.$t("groups"))),
            ]),
            _vm._v(" "),
            _c("copy-button", {
              attrs: { id: "group-avatar", value: _vm.groupAvatarValue },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "mb-primary" }, [
            _c("h6", { staticClass: "pb-2" }, [
              _vm._v(_vm._s(_vm.$t("large"))),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-lg-6" },
                [
                  _c("app-avatars-group", {
                    attrs: {
                      "avatar-group-class": _vm.avatarsGroupClass,
                      avatars: _vm.avatarsGroupTwo,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-lg-6" },
                [
                  _c("app-avatars-group", {
                    attrs: {
                      "avatar-group-class": _vm.avatarsGroupClass,
                      avatars: _vm.avatarsGroupOne,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("h6", { staticClass: "pb-2" }, [
              _vm._v(_vm._s(_vm.$t("small"))),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-lg-6" },
                [
                  _c("app-avatars-group", {
                    attrs: { avatars: _vm.avatarsGroupTwo },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-lg-6" },
                [
                  _c("app-avatars-group", {
                    attrs: { avatars: _vm.avatarsGroupOne },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card card-with-shadow border-0 mt-4" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header bg-transparent p-primary d-flex justify-content-between align-items-center",
          },
          [
            _c("h5", { staticClass: "card-title mb-0" }, [
              _vm._v(_vm._s(_vm.$t("groups_shadow"))),
            ]),
            _vm._v(" "),
            _c("copy-button", {
              attrs: {
                id: "group-shadow-avatar",
                value: _vm.groupShadowAvatarValue,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "mb-primary" }, [
            _c("h6", { staticClass: "pb-2" }, [
              _vm._v(_vm._s(_vm.$t("large"))),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-lg-6" },
                [
                  _c("app-avatars-group", {
                    attrs: {
                      "avatar-group-class": _vm.avatarsGroupClass,
                      avatars: _vm.avatarsGroupTwo,
                      shadow: true,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-lg-6" },
                [
                  _c("app-avatars-group", {
                    attrs: {
                      "avatar-group-class": _vm.avatarsGroupClass,
                      avatars: _vm.avatarsGroupOne,
                      shadow: true,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("h6", { staticClass: "pb-2" }, [
              _vm._v(_vm._s(_vm.$t("small"))),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-lg-6" },
                [
                  _c("app-avatars-group", {
                    attrs: { avatars: _vm.avatarsGroupTwo, shadow: true },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-lg-6" },
                [
                  _c("app-avatars-group", {
                    attrs: { avatars: _vm.avatarsGroupOne, shadow: true },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card card-with-shadow border-0 mt-4" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header bg-transparent p-primary d-flex justify-content-between align-items-center",
          },
          [
            _c("h5", { staticClass: "card-title mb-0" }, [
              _vm._v(_vm._s(_vm.$t("groups_shadow_and_border"))),
            ]),
            _vm._v(" "),
            _c("copy-button", {
              attrs: {
                id: "group-shadow-border-avatar",
                value: _vm.groupShadowBorderAvatarValue,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "mb-primary" }, [
            _c("h6", { staticClass: "pb-2" }, [
              _vm._v(_vm._s(_vm.$t("large"))),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-lg-6" },
                [
                  _c("app-avatars-group", {
                    attrs: {
                      "avatar-group-class": _vm.avatarsGroupClass,
                      avatars: _vm.avatarsGroupTwo,
                      border: true,
                      shadow: true,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-lg-6" },
                [
                  _c("app-avatars-group", {
                    attrs: {
                      "avatar-group-class": _vm.avatarsGroupClass,
                      avatars: _vm.avatarsGroupOne,
                      border: true,
                      shadow: true,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("h6", { staticClass: "pb-2" }, [
              _vm._v(_vm._s(_vm.$t("small"))),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-lg-6" },
                [
                  _c("app-avatars-group", {
                    attrs: {
                      avatars: _vm.avatarsGroupTwo,
                      border: true,
                      shadow: true,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-lg-6" },
                [
                  _c("app-avatars-group", {
                    attrs: {
                      avatars: _vm.avatarsGroupOne,
                      border: true,
                      shadow: true,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }