var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("app-breadcrumb", {
        attrs: { "page-title": _vm.$t("calendar_view") },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.closeAddEditModal,
              expression: "closeAddEditModal",
            },
          ],
        },
        [
          _c(
            "div",
            { staticClass: "card card-with-shadow min-height-400 border-0" },
            [
              _c(
                "div",
                { staticClass: "card-body p-primary" },
                [
                  _c("app-calendar", {
                    attrs: { preloader: _vm.preloader, options: _vm.options },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("event-add-edit-modal", {
            attrs: {
              "event-data": _vm.eventData,
              "selected-url": _vm.selectedUrl,
            },
            on: { reloadCalendar: _vm.initCalendar },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }