var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.icon == "no-sort"
    ? _c(
        "span",
        { key: "no-sort", staticClass: "d-flex flex-column small-sort-icon" },
        [
          _c("app-icon", { attrs: { name: "arrow-up" } }),
          _vm._v(" "),
          _c("app-icon", { attrs: { name: "arrow-down" } }),
        ],
        1
      )
    : _c(
        "span",
        { key: _vm.icon + "-sort" },
        [
          _c("app-icon", {
            key: "arrow-" + _vm.icon,
            attrs: { name: "arrow-" + _vm.icon },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }