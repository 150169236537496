var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("app-breadcrumb", {
        attrs: {
          "page-title": _vm.$t("blank"),
          directory: _vm.$t("sample_pages"),
          icon: "copy",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }