var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("app-breadcrumb", {
        attrs: { "page-title": _vm.pageTitle, icon: _vm.pageIcon },
      }),
      _vm._v(" "),
      _c("app-tab", { attrs: { tabs: _vm.tabs, icon: _vm.pageIcon } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }