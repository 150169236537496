var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "d-flex align-items-center mb-4" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-outline-primary rounded-pill",
          on: {
            click: function ($event) {
              return _vm.$emit("cancel")
            },
          },
        },
        [
          _c("app-icon", {
            staticClass: "size-16",
            attrs: { name: "arrow-left" },
          }),
          _vm._v("\n            " + _vm._s(_vm.$t("back")) + "\n        "),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "h4 my-0 mx-3 text-muted" }, [_vm._v("|")]),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex justify-content-between" }, [
        _vm.selectedTemplate?.id
          ? _c("h5", { staticClass: "m-0" }, [
              _vm._v(_vm._s(_vm.$t("update_template"))),
            ])
          : _c("h5", { staticClass: "m-0" }, [
              _vm._v(_vm._s(_vm.$t("new_template"))),
            ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row" },
      [
        _vm.preloader ? _c("app-overlay-loader") : _vm._e(),
        _vm._v(" "),
        _c(
          "form",
          {
            staticClass: "col-12 col-lg-7",
            class: { "loading-opacity": _vm.preloader },
          },
          [
            _c(
              "div",
              { staticClass: "card card-body card-with-shadow p-3 border-0" },
              [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("label", [
                        _vm._v(" " + _vm._s(_vm.$t("template_name"))),
                      ]),
                      _vm._v(" "),
                      _c("app-input", {
                        model: {
                          value: _vm.formData.template_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "template_name", $$v)
                          },
                          expression: "formData.template_name",
                        },
                      }),
                      _vm._v(" "),
                      _vm.errors.template_name
                        ? _c("small", { staticClass: "text-danger" }, [
                            _vm._v(_vm._s(_vm.errors.template_name[0])),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass: "col-12 p-3 my-3",
                      staticStyle: {
                        "background-color": "var(--default-border-color)",
                      },
                    },
                    [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(" " + _vm._s(_vm.$t("department_details"))),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 mt-3" },
                    [
                      _c("label", [_vm._v(" " + _vm._s(_vm.$t("field_1")))]),
                      _vm._v(" "),
                      _c("app-input", {
                        model: {
                          value: _vm.formData.field1,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "field1", $$v)
                          },
                          expression: "formData.field1",
                        },
                      }),
                      _vm._v(" "),
                      _vm.errors.field1
                        ? _c("small", { staticClass: "text-danger" }, [
                            _vm._v(_vm._s(_vm.errors.field1[0])),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 mt-3" },
                    [
                      _c("label", [_vm._v(" " + _vm._s(_vm.$t("field_2")))]),
                      _vm._v(" "),
                      _c("app-input", {
                        model: {
                          value: _vm.formData.field2,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "field2", $$v)
                          },
                          expression: "formData.field2",
                        },
                      }),
                      _vm._v(" "),
                      _vm.errors.field2
                        ? _c("small", { staticClass: "text-danger" }, [
                            _vm._v(_vm._s(_vm.errors.field2[0])),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 mt-3" },
                    [
                      _c("label", [_vm._v(" " + _vm._s(_vm.$t("field_3")))]),
                      _vm._v(" "),
                      _c("app-input", {
                        model: {
                          value: _vm.formData.field3,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "field3", $$v)
                          },
                          expression: "formData.field3",
                        },
                      }),
                      _vm._v(" "),
                      _vm.errors.field3
                        ? _c("small", { staticClass: "text-danger" }, [
                            _vm._v(_vm._s(_vm.errors.field3[0])),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("hr", { staticClass: "my-3 mx-0" }),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("div", { staticClass: "py-3 d-flex" }, [
                      _vm.selectedTemplate?.id
                        ? _c(
                            "button",
                            {
                              staticClass: "mr-3 btn btn-success",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.update()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.$t("Update")) +
                                  "\n                            "
                              ),
                            ]
                          )
                        : _c(
                            "button",
                            {
                              staticClass: "mr-3 btn btn-success",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.submit()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.$t("save")) +
                                  "\n                            "
                              ),
                            ]
                          ),
                    ]),
                  ]),
                ]),
              ]
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }