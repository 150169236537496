<template>
    <div class="content-wrapper">
        <app-breadcrumb :page-title="$t('blank')" :directory="$t('sample_pages')" :icon="'copy'"/>

        <!--Start main content from here-->
    </div>
</template>

<script>
    export default {
        name: "Blank"
    }
</script>
