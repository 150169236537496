var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav", { attrs: { "aria-label": "breadcrumb" } }, [
    _c(
      "ol",
      { staticClass: "breadcrumb p-0 d-flex align-items-center mb-primary" },
      [
        _c(
          "li",
          {
            staticClass:
              "breadcrumb-item page-header d-flex align-items-center",
          },
          [
            _c("h4", { staticClass: "mb-0" }, [
              _vm._v(_vm._s(_vm.textTruncate(_vm.pageTitle, 40))),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm.button && _vm.isBackAvailable
          ? _c("li", { staticClass: "ml-2" }, [_vm._v("|")])
          : _vm._e(),
        _vm._v(" "),
        _vm.button && _vm.isBackAvailable
          ? _c("li", {}, [
              _c(
                "a",
                {
                  staticClass: "btn btn-link text-primary pl-2",
                  attrs: { href: "" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.goBack.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.button.label ? _vm.button.label : "Back"))]
              ),
            ])
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }