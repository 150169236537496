var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _vm.loader
        ? _c("app-overlay-loader")
        : _c("div", [
            _c("h3", { staticClass: "mb-3" }, [_vm._v(_vm._s(_vm.$t("pos")))]),
            _vm._v(" "),
            _c("div", [
              _c("div", { staticClass: "card mb-primary" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "card-header d-flex justify-content-between align-items-center",
                  },
                  [
                    _c("div", [_vm._v(_vm._s(_vm.$t("highlight")))]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "dropdown d-inline-block btn-dropdown" },
                      [
                        _c(
                          "a",
                          {
                            staticClass:
                              "btn btn-link text-primary shadow-none dropdown-toggle ml-0 mr-2",
                            attrs: {
                              href: "#",
                              role: "button",
                              "data-toggle": "dropdown",
                              "aria-expanded": "false",
                            },
                          },
                          [
                            _c("span", { staticClass: "text-capitalize" }, [
                              _vm._v(_vm._s(_vm.$t(_vm.highlight))),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "dropdown-menu dropdown-menu-right" },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    _vm.highlight = "last_week"
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("last_week")))]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    _vm.highlight = "last_month"
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("last_month")))]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    _vm.highlight = "this_month"
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("this_month")))]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    _vm.highlight = "this_year"
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("this_year")))]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    _vm.highlight = "last_year"
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("last_year")))]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "card-body mb-5" },
                  [
                    _vm.isHighlightLoading
                      ? _c("app-overlay-loader")
                      : _c("div", [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-12 col-sm-6 col-md-3" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid-card d-flex align-items-center",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "icon text-white" },
                                      [
                                        _c("app-icon", {
                                          attrs: { name: "dollar-sign" },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("div", [
                                      _c("h5", [
                                        _vm._v(
                                          "\n                                            " +
                                            _vm._s(
                                              _vm.numberWithCurrencySymbol(
                                                _vm.highlightsData.totalSales
                                              )
                                            ) +
                                            "\n                                        "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "text-muted" }, [
                                        _vm._v(_vm._s(_vm.$t("total_sales"))),
                                      ]),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.userRole.id !== 5
                              ? _c(
                                  "div",
                                  { staticClass: "col-12 col-sm-6 col-md-3" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid-card d-flex align-items-center",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "icon text-white" },
                                          [
                                            _c("app-icon", {
                                              attrs: { name: "activity" },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("div", [
                                          _c("h5", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.numberWithCurrencySymbol(
                                                  _vm.highlightsData
                                                    .totalExpense
                                                )
                                              )
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "text-muted" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("total_expenses"))
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.userRole.id !== 5
                              ? _c(
                                  "div",
                                  { staticClass: "col-12 col-sm-6 col-md-3" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid-card d-flex align-items-center",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "icon text-white" },
                                          [
                                            _c("app-icon", {
                                              attrs: { name: "zap" },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("div", [
                                          _c("h5", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.numberWithCurrencySymbol(
                                                  _vm.highlightsData.netProfit
                                                )
                                              )
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "text-muted" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("net_profit"))
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.userRole.id !== 5
                              ? _c(
                                  "div",
                                  { staticClass: "col-12 col-sm-6 col-md-3" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid-card d-flex align-items-center",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "icon text-white" },
                                          [
                                            _c("app-icon", {
                                              attrs: { name: "credit-card" },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("div", [
                                          _c("h5", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.numberWithCurrencySymbol(
                                                  _vm.highlightsData.totalDue
                                                )
                                              )
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "text-muted" },
                                            [_vm._v(_vm._s(_vm.$t("due")))]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row mb-primary" }, [
                _c("div", { staticClass: "col-md-8" }, [
                  _c(
                    "div",
                    { staticClass: "card card-with-shadow border-0 h-100 t" },
                    [
                      _c(
                        "div",
                        { staticClass: "card-header bg-transparent px-5 py-4" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center text-secondary",
                            },
                            [
                              _c("h4", { staticClass: "m-0 p-0" }, [
                                _vm._v(_vm._s(_vm.$t("sales_and_purchase"))),
                              ]),
                              _vm._v(" "),
                              !_vm.isSalesPurchaseLoading
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "dropdown d-inline-block btn-dropdown",
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "btn btn-link text-primary shadow-none dropdown-toggle ml-0 mr-2",
                                          attrs: {
                                            href: "#",
                                            role: "button",
                                            "data-toggle": "dropdown",
                                            "aria-expanded": "false",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "text-capitalize" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(_vm.salesDropdownMenu)
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "dropdown-menu dropdown-menu-right",
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "dropdown-item",
                                              attrs: { href: "#" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.salesDropdownMenu =
                                                    "last_week"
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("last_week"))
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "dropdown-item",
                                              attrs: { href: "#" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.salesDropdownMenu =
                                                    "this_week"
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("this_week"))
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "dropdown-item",
                                              attrs: { href: "#" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.salesDropdownMenu =
                                                    "last_month"
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("last_month"))
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "dropdown-item",
                                              attrs: { href: "#" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.salesDropdownMenu =
                                                    "this_month"
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("this_month"))
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "dropdown-item",
                                              attrs: { href: "#" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.salesDropdownMenu =
                                                    "this_year"
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("this_year"))
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "dropdown-item",
                                              attrs: { href: "#" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.salesDropdownMenu =
                                                    "last_year"
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("last_year"))
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "card-body" },
                        [
                          _vm.isSalesPurchaseLoading
                            ? _c("app-overlay-loader")
                            : _c("app-chart", {
                                attrs: {
                                  type: "bar-chart",
                                  height: 360,
                                  labels: _vm.salesPurchaseData.labels,
                                  "data-sets": _vm.salesPurchaseData.dataSet,
                                },
                              }),
                          _vm._v(" "),
                          !_vm.isSalesPurchaseLoading
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-center align-items-center mt-2",
                                  staticStyle: { gap: "15px" },
                                },
                                _vm._l(
                                  _vm.salesPurchaseData.dataSet,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      { key: `chart-item-${index}` },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-center",
                                          },
                                          [
                                            _c("span", {
                                              staticStyle: {
                                                width: "10px",
                                                height: "10px",
                                                "border-radius": "50%",
                                                "margin-right": "10px",
                                              },
                                              style: {
                                                backgroundColor:
                                                  item.backgroundColor,
                                              },
                                            }),
                                            _vm._v(
                                              _vm._s(item.label) +
                                                "\n                                    "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-4" }, [
                  _c(
                    "div",
                    { staticClass: "card card-with-shadow border-0 h-100" },
                    [
                      _c(
                        "div",
                        { staticClass: "card-header bg-transparent px-5 py-4" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center text-secondary",
                            },
                            [
                              _c("h4", { staticClass: "m-0 p-0" }, [
                                _vm._v(_vm._s(_vm.$t("top_selling_products"))),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.isTopSellingLoading
                        ? [
                            _c(
                              "div",
                              { staticClass: "card-body" },
                              [_c("app-overlay-loader")],
                              1
                            ),
                          ]
                        : _c(
                            "div",
                            { staticClass: "card-body" },
                            [
                              _c("app-chart", {
                                staticClass: "mb-primary",
                                attrs: {
                                  type: "dough-chart",
                                  height: 360,
                                  labels: _vm.topSellingData.labels,
                                  "data-sets": _vm.topSellingData.dataSet,
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "chart-data-list" }, [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col d-inline-flex flex-wrap",
                                      staticStyle: { gap: "10px" },
                                    },
                                    _vm._l(
                                      _vm.topSellingData.dataSet[0]
                                        .backgroundColor,
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass:
                                              "d-flex align-items-center",
                                          },
                                          [
                                            _c("div", {
                                              staticStyle: {
                                                width: "10px",
                                                height: "10px",
                                                "border-radius": "3px",
                                                "margin-right": "10px",
                                              },
                                              style: { backgroundColor: item },
                                            }),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.topSellingData.labels[
                                                    index
                                                  ]
                                                ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.topSellingData
                                                      .dataSet[0].data[index]
                                                  )
                                              ),
                                            ]),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                    ],
                    2
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row mb-primary" }, [
                _c("div", { staticClass: "col" }, [
                  _c(
                    "div",
                    { staticClass: "card" },
                    [
                      _c(
                        "div",
                        { staticClass: "card-header bg-transparent px-5 py-4" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center text-secondary",
                            },
                            [
                              _c("h4", { staticClass: "m-0 p-0" }, [
                                _vm._v(_vm._s(_vm.$t("recent_sales"))),
                              ]),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "btn btn-link text-primary",
                                  attrs: { href: "javascript:void(0)" },
                                },
                                [_vm._v(_vm._s(_vm.$t("view_all_invoices")))]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.isRecentSalesLoading
                        ? [
                            _c(
                              "div",
                              { staticClass: "card-body datatable" },
                              [_c("app-overlay-loader")],
                              1
                            ),
                          ]
                        : _c(
                            "div",
                            { staticClass: "card-body datatable" },
                            [
                              _c("custom-table", {
                                attrs: {
                                  options: _vm.recentSalesTable.options,
                                  data: _vm.recentSalesTable.data,
                                },
                                on: {
                                  "activate-invoice-view-modal":
                                    _vm.handleInvoiceModalActiveEvent,
                                },
                              }),
                            ],
                            1
                          ),
                    ],
                    2
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-6" }, [
                  _c(
                    "div",
                    { staticClass: "card" },
                    [
                      _c(
                        "div",
                        { staticClass: "card-header bg-transparent px-5 py-4" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center text-secondary",
                            },
                            [
                              _c("h4", { staticClass: "m-0 p-0" }, [
                                _vm._v(_vm._s(_vm.$t("stock_summary"))),
                              ]),
                              _vm._v(
                                " (" +
                                  _vm._s(_vm.$t("all_branch")) +
                                  ")\n                                "
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "btn btn-link text-primary",
                                  attrs: { href: "javascript:void(0)" },
                                },
                                [_vm._v(_vm._s(_vm.$t("view_all_stocks")))]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.isStockSummaryLoading
                        ? [
                            _c(
                              "div",
                              { staticClass: "card-body datatable" },
                              [_c("app-overlay-loader")],
                              1
                            ),
                          ]
                        : _c(
                            "div",
                            { staticClass: "card-body datatable" },
                            [
                              _c("custom-table", {
                                attrs: {
                                  options: _vm.stockSummaryData.options,
                                  data: _vm.stockSummaryData.data,
                                },
                              }),
                            ],
                            1
                          ),
                    ],
                    2
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c(
                    "div",
                    { staticClass: "card" },
                    [
                      _c(
                        "div",
                        { staticClass: "card-header bg-transparent px-5 py-4" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center text-secondary",
                            },
                            [
                              _c("h4", { staticClass: "m-0 p-0" }, [
                                _vm._v(_vm._s(_vm.$t("top_customer"))),
                              ]),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "btn btn-link text-primary",
                                  attrs: { href: "javascript:void(0)" },
                                },
                                [_vm._v(_vm._s(_vm.$t("view_all_customers")))]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.isCustomerLoading
                        ? [
                            _c(
                              "div",
                              { staticClass: "card-body datatable" },
                              [_c("app-overlay-loader")],
                              1
                            ),
                          ]
                        : _c(
                            "div",
                            { staticClass: "card-body datatable" },
                            [
                              _c("custom-table", {
                                attrs: {
                                  options: _vm.topCustomer.options,
                                  data: _vm.topCustomer.data,
                                },
                              }),
                            ],
                            1
                          ),
                    ],
                    2
                  ),
                ]),
              ]),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }