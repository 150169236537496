var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "position-relative" }, [
    _vm.countCreatedResponse < 4
      ? _c(
          "div",
          {
            staticClass:
              "root-preloader position-absolute overlay-loader-wrapper",
          },
          [_vm._m(0)]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content-wrapper" },
      [
        _c("app-breadcrumb", {
          attrs: {
            "page-title": _vm.$t("academy"),
            directory: _vm.$t("dashboard"),
            icon: "pie-chart",
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass:
                "col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9 mb-primary",
            },
            [
              !_vm.isActiveStudentOverview && !_vm.mainPreloader
                ? _c("app-overlay-loader")
                : _vm._e(),
              _vm._v(" "),
              _vm.isActiveStudentOverview
                ? _c(
                    "div",
                    { staticClass: "card card-with-shadow border-0 h-100" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "card-header bg-transparent p-primary d-flex justify-content-between align-items-center",
                        },
                        [
                          _c("h5", { staticClass: "card-title mb-0" }, [
                            _vm._v(_vm._s(_vm.$t("student_overview"))),
                          ]),
                          _vm._v(" "),
                          _c(
                            "ul",
                            {
                              staticClass:
                                "nav tab-filter-menu justify-content-flex-end",
                            },
                            _vm._l(
                              _vm.academydashboard.studentOverview.filters,
                              function (item, index) {
                                return _c(
                                  "li",
                                  { key: index, staticClass: "nav-item" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "nav-link py-0",
                                        class: [
                                          _vm.value == item.id
                                            ? "active"
                                            : index === 0 && _vm.value === ""
                                            ? "active"
                                            : "",
                                        ],
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.getFilterValue(item.id)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(item.value) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "card-body" },
                        [
                          _c("app-chart", {
                            attrs: {
                              type: "horizontal-line-chart",
                              height: 380,
                              labels:
                                _vm.academydashboard.studentOverview.labels,
                              "data-sets":
                                _vm.academydashboard.studentOverview.dataSet,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3 mb-primary",
            },
            [
              !_vm.isActiveSchoolOverview && !_vm.mainPreloader
                ? _c("app-overlay-loader")
                : _vm._e(),
              _vm._v(" "),
              _vm.isActiveSchoolOverview
                ? _c(
                    "div",
                    { staticClass: "card card-with-shadow border-0 h-100" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "card-header bg-transparent p-primary d-flex justify-content-between align-items-center",
                        },
                        [
                          _c("h5", { staticClass: "card-title mb-0" }, [
                            _vm._v(_vm._s(_vm.$t("school_overview"))),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "card-body" },
                        [
                          _c("app-chart", {
                            staticClass: "mb-primary",
                            attrs: {
                              type: "dough-chart",
                              height: 230,
                              labels:
                                _vm.academydashboard.schoolOverview.labels,
                              "data-sets":
                                _vm.academydashboard.schoolOverview.dataSet,
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "chart-data-list" }, [
                            _c(
                              "div",
                              { staticClass: "row" },
                              _vm._l(
                                _vm.academydashboard.schoolOverview
                                  .chartElement,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "col-12" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "data-group-item",
                                          style: item.color,
                                        },
                                        [
                                          _c("span", {
                                            staticClass: "square",
                                            style: item.background_color,
                                          }),
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(item.key) +
                                              "\n                                        "
                                          ),
                                          _c("span", { staticClass: "value" }, [
                                            _vm._v(_vm._s(item.value)),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        !_vm.isActivedDefaultData && !_vm.mainPreloader
          ? _c("app-overlay-loader")
          : _vm._e(),
        _vm._v(" "),
        _vm.isActivedDefaultData
          ? _c(
              "div",
              { staticClass: "row" },
              _vm._l(
                _vm.academydashboard.defaultData.dataSet,
                function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                    },
                    [
                      _c("app-widget", {
                        staticClass: "mb-primary",
                        attrs: {
                          type: "app-widget-with-icon",
                          label: item.label,
                          number: _vm.numberFormat(item.value),
                          icon: item.icon,
                        },
                      }),
                    ],
                    1
                  )
                }
              ),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12 col-sm-12 col-md-6 mb-4 mb-md-0" },
            [
              !_vm.isActiveInstructorStudentOverview && !_vm.mainPreloader
                ? _c("app-overlay-loader")
                : _vm._e(),
              _vm._v(" "),
              _vm.isActiveInstructorStudentOverview
                ? _c(
                    "div",
                    { staticClass: "card card-with-shadow border-0 h-100" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "card-header p-primary bg-transparent d-flex align-items-baseline",
                        },
                        [
                          _c(
                            "h5",
                            { staticClass: "card-title m-0 d-inline-block" },
                            [_vm._v(_vm._s(_vm.$t("instructor_and_student")))]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "card-body" },
                        [
                          _c("app-chart", {
                            attrs: {
                              type: "bar-chart",
                              height: 390,
                              labels:
                                _vm.academydashboard.instructorStudentOverview
                                  .labels,
                              "data-sets":
                                _vm.academydashboard.instructorStudentOverview
                                  .dataSet,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.mainPreloader
            ? _c("div", { staticClass: "col-12 col-sm-12 col-md-6" }, [
                _c("div", { staticClass: "card card-with-shadow border-0" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-header d-flex align-items-center justify-content-between p-primary primary-card-color",
                    },
                    [
                      _c(
                        "h5",
                        { staticClass: "card-title d-inline-block mb-0" },
                        [_vm._v(" " + _vm._s(_vm.$t("available_courses")))]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "card-body p-0" },
                    [
                      _c("app-table", {
                        attrs: {
                          id: "test-table-profile",
                          options: _vm.availableCourses,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "spinner-bounce d-flex align-items-center dashboard-preloader justify-content-center",
      },
      [
        _c("span", { staticClass: "bounce1 mr-1" }),
        _vm._v(" "),
        _c("span", { staticClass: "bounce2 mr-1" }),
        _vm._v(" "),
        _c("span", { staticClass: "bounce3 mr-1" }),
        _vm._v(" "),
        _c("span", { staticClass: "bounce4" }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }