var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "position-relative" }, [
    _vm.countCreatedResponse < 3
      ? _c(
          "div",
          {
            staticClass:
              "root-preloader position-absolute overlay-loader-wrapper",
          },
          [_vm._m(0)]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content-wrapper" },
      [
        _c("app-breadcrumb", {
          attrs: {
            "page-title": _vm.$t("default"),
            directory: _vm.$t("dashboard"),
            icon: "pie-chart",
          },
        }),
        _vm._v(" "),
        !_vm.defaultData && !_vm.mainPreloader
          ? _c("app-overlay-loader")
          : _vm._e(),
        _vm._v(" "),
        _vm.defaultData
          ? _c(
              "div",
              { staticClass: "row" },
              _vm._l(
                _vm.info.getDefaultDashboardInfo.topSectionData,
                function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3",
                    },
                    [
                      _c("app-widget", {
                        staticClass: "mb-primary",
                        attrs: {
                          type: "app-widget-with-icon",
                          label: item.label,
                          number: _vm.numberFormat(item.number),
                          icon: item.icon,
                        },
                      }),
                    ],
                    1
                  )
                }
              ),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row" },
          [
            _c(
              "div",
              { staticClass: "col-12 col-md-9 mb-primary" },
              [
                !_vm.audienceChart && !_vm.mainPreloader
                  ? _c("app-overlay-loader")
                  : _vm._e(),
                _vm._v(" "),
                _vm.audienceChart
                  ? _c(
                      "div",
                      { staticClass: "card card-with-shadow border-0 h-100" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "card-header bg-transparent p-primary d-flex justify-content-between align-items-center",
                          },
                          [
                            _c("h5", { staticClass: "card-title mb-0" }, [
                              _vm._v(_vm._s(_vm.$t("audience_overview"))),
                            ]),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "nav tab-filter-menu justify-content-flex-end",
                              },
                              _vm._l(
                                _vm.info.audienceOverviewChart
                                  .chartFilterOptions,
                                function (item, index) {
                                  return _c(
                                    "li",
                                    { key: index, staticClass: "nav-item" },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "nav-link py-0",
                                          class: [
                                            _vm.audienceFilter == item.id
                                              ? "active"
                                              : index === 0 &&
                                                _vm.audienceFilter === ""
                                              ? "active"
                                              : "",
                                          ],
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.audienceOverviewCartFilterValue(
                                                item.id
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(item.value) +
                                              "\n                                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "card-body" },
                          [
                            _c("app-chart", {
                              staticClass: "mb-primary",
                              attrs: {
                                type: "custom-line-chart",
                                height: 170,
                                labels: _vm.info.audienceOverviewChart.labels,
                                "data-sets":
                                  _vm.info.audienceOverviewChart.chartData,
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "chart-data-list d-flex flex-wrap justify-content-center",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "data-group-item",
                                    staticStyle: { color: "#368cd5" },
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "square",
                                      staticStyle: {
                                        "background-color": "#368cd5",
                                      },
                                    }),
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(_vm.$t("users")) +
                                        "\n\n                                "
                                    ),
                                    _c("span", { staticClass: "value" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.info.audienceOverviewChart.user
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "data-group-item",
                                    staticStyle: { color: "#f7531e" },
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "square",
                                      staticStyle: {
                                        "background-color": "#f7531e",
                                      },
                                    }),
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(_vm.$t("visitors")) +
                                        "\n\n                                "
                                    ),
                                    _c("span", { staticClass: "value" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.info.audienceOverviewChart.visitor
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            !_vm.defaultData && !_vm.mainPreloader
              ? _c("app-overlay-loader")
              : _vm._e(),
            _vm._v(" "),
            _vm.defaultData
              ? _c(
                  "div",
                  { staticClass: "col-12 col-md-3 mb-4 mb-md-0" },
                  _vm._l(
                    _vm.info.getDefaultDashboardInfo.circleTopSection,
                    function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          class: {
                            "mb-primary":
                              index !==
                              _vm.info.getDefaultDashboardInfo.circleTopSection
                                .length -
                                1,
                          },
                        },
                        [
                          _c("app-widget", {
                            attrs: {
                              type: "app-widget-with-circle",
                              label: item.label,
                              number: _vm.numberFormat(item.number),
                            },
                          }),
                        ],
                        1
                      )
                    }
                  ),
                  0
                )
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12 col-md-6" },
            [
              !_vm.defaultData && !_vm.mainPreloader
                ? _c("app-overlay-loader")
                : _vm._e(),
              _vm._v(" "),
              _vm.defaultData
                ? _c(
                    "div",
                    { staticClass: "row" },
                    _vm._l(
                      _vm.info.getDefaultDashboardInfo.bottomSectionData,
                      function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "col-12 col-sm-6 col-md-6",
                          },
                          [
                            _c("app-widget", {
                              staticClass: "mb-primary",
                              attrs: {
                                type: "app-widget-with-icon",
                                label: item.label,
                                number: _vm.numberFormat(item.number),
                                icon: item.icon,
                              },
                            }),
                          ],
                          1
                        )
                      }
                    ),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.defaultData
                ? _c(
                    "div",
                    { staticClass: "row" },
                    _vm._l(
                      _vm.info.getDefaultDashboardInfo.circleBottomSection,
                      function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "col-12 col-md-6 mb-4 mb-md-0",
                          },
                          [
                            _c("app-widget", {
                              attrs: {
                                type: "app-widget-with-circle",
                                label: item.label,
                                number: _vm.numberFormat(item.number),
                              },
                            }),
                          ],
                          1
                        )
                      }
                    ),
                    0
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12 col-md-6" },
            [
              !_vm.browserChart && !_vm.mainPreloader
                ? _c("app-overlay-loader")
                : _vm._e(),
              _vm._v(" "),
              _vm.browserChart
                ? _c(
                    "div",
                    { staticClass: "card card-with-shadow border-0 h-100" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "card-header bg-transparent p-primary d-flex justify-content-between align-items-center",
                        },
                        [
                          _c("h5", { staticClass: "card-title mb-0" }, [
                            _vm._v(_vm._s(_vm.$t("browser_overview"))),
                          ]),
                          _vm._v(" "),
                          _c(
                            "ul",
                            {
                              staticClass:
                                "nav tab-filter-menu justify-content-flex-end",
                            },
                            _vm._l(
                              _vm.info.browserOverviewChart.chartFilterOptions,
                              function (item, index) {
                                return _c(
                                  "li",
                                  { key: index, staticClass: "nav-item" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "nav-link py-0",
                                        class: [
                                          _vm.browserFilter == item.id
                                            ? "active"
                                            : index === 0 &&
                                              _vm.browserFilter === ""
                                            ? "active"
                                            : "",
                                        ],
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.browserOverviewChartFilterValue(
                                              item.id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(item.value) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "card-body" },
                        [
                          _c("app-chart", {
                            staticClass: "mb-primary",
                            attrs: {
                              type: "line-chart",
                              height: 210,
                              labels: _vm.info.browserOverviewChart.labels,
                              "data-sets":
                                _vm.info.browserOverviewChart.chartData,
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "chart-data-list d-flex flex-wrap justify-content-center",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "data-group-item",
                                  staticStyle: { color: "rgb(3, 90, 166)" },
                                },
                                [
                                  _c("span", {
                                    staticClass: "square",
                                    staticStyle: {
                                      "background-color": "rgb(3, 90, 166)",
                                    },
                                  }),
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.$t("chrome")) +
                                      "\n                                "
                                  ),
                                  _c("span", { staticClass: "value" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.info.browserOverviewChart.chrome
                                      ) + " "
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "data-group-item",
                                  staticStyle: { color: "rgb(64, 186, 213)" },
                                },
                                [
                                  _c("span", {
                                    staticClass: "square",
                                    staticStyle: {
                                      "background-color": "rgb(64, 186, 213)",
                                    },
                                  }),
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.$t("firefox")) +
                                      "\n                                "
                                  ),
                                  _c("span", { staticClass: "value" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.info.browserOverviewChart.firefox
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "data-group-item",
                                  staticStyle: {
                                    color: "rgba(41, 199, 172, 0.7)",
                                  },
                                },
                                [
                                  _c("span", {
                                    staticClass: "square",
                                    staticStyle: {
                                      "background-color":
                                        "rgba(41, 199, 172, 0.7)",
                                    },
                                  }),
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.$t("opera")) +
                                      "\n                                "
                                  ),
                                  _c("span", { staticClass: "value" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.info.browserOverviewChart.opera
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "spinner-bounce d-flex align-items-center dashboard-preloader justify-content-center",
      },
      [
        _c("span", { staticClass: "bounce1 mr-1" }),
        _vm._v(" "),
        _c("span", { staticClass: "bounce2 mr-1" }),
        _vm._v(" "),
        _c("span", { staticClass: "bounce3 mr-1" }),
        _vm._v(" "),
        _c("span", { staticClass: "bounce4" }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }