var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card card-with-shadow border-0 mb-primary" },
    [
      _c("div", { staticClass: "card-header p-primary bg-transparent" }, [
        _c("h5", { staticClass: "card-title m-0" }, [
          _vm._v(_vm._s(_vm.$t("basic_elements"))),
        ]),
      ]),
      _vm._v(" "),
      _vm.preloader ? _c("app-overlay-loader") : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c(
          "form",
          {
            ref: "form",
            staticClass: "mb-0",
            class: { "loading-opacity": _vm.preloader },
            attrs: { "data-url": "/form/input" },
          },
          [
            _c(
              "div",
              { staticClass: "form-group row align-items-center" },
              [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 mb-sm-0",
                    attrs: { for: "text_input" },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$t("text")) +
                        "\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("app-input", {
                  staticClass: "col-sm-8",
                  attrs: {
                    id: "text_input",
                    type: "text",
                    placeholder: _vm.$t("text_type_input"),
                  },
                  model: {
                    value: _vm.inputs.text_input,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputs, "text_input", $$v)
                    },
                    expression: "inputs.text_input",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group row align-items-center" },
              [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 mb-sm-0",
                    attrs: { for: "email_input" },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$t("email")) +
                        "\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("app-input", {
                  staticClass: "col-sm-8",
                  attrs: {
                    id: "email_input",
                    type: "email",
                    placeholder: _vm.$t("email_type_input"),
                  },
                  model: {
                    value: _vm.inputs.email_input,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputs, "email_input", $$v)
                    },
                    expression: "inputs.email_input",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group row align-items-center" },
              [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 mb-sm-0",
                    attrs: { for: "password_input" },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$t("password")) +
                        "\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("app-input", {
                  staticClass: "col-sm-8",
                  attrs: {
                    id: "password_input",
                    type: "password",
                    specialValidation: true,
                    placeholder: _vm.$t("password_type_input"),
                  },
                  model: {
                    value: _vm.inputs.password_input,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputs, "password_input", $$v)
                    },
                    expression: "inputs.password_input",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group row align-items-center" },
              [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 mb-sm-0",
                    attrs: { for: "confirm_password" },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$t("confirm_password")) +
                        "\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("app-input", {
                  staticClass: "col-sm-8",
                  attrs: {
                    id: "confirm_password",
                    type: "password",
                    "same-as": "inputs_password_input",
                    placeholder: _vm.$t("confirm_password"),
                  },
                  model: {
                    value: _vm.inputs.confirm_password,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputs, "confirm_password", $$v)
                    },
                    expression: "inputs.confirm_password",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group row align-items-center" },
              [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 mb-sm-0",
                    attrs: { for: "decimal_input" },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$t("decimal_input")) +
                        "\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("app-input", {
                  staticClass: "col-sm-8",
                  attrs: {
                    id: "decimal_input",
                    type: "decimal",
                    placeholder: _vm.$t("decimal_type_input"),
                  },
                  model: {
                    value: _vm.inputs.decimal_input,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputs, "decimal_input", $$v)
                    },
                    expression: "inputs.decimal_input",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group row align-items-center" },
              [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 mb-sm-0",
                    attrs: { for: "number_input" },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$t("number_input")) +
                        "\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("app-input", {
                  staticClass: "col-sm-8",
                  attrs: {
                    id: "number_input",
                    type: "number",
                    placeholder: _vm.$t("number_type_input"),
                  },
                  model: {
                    value: _vm.inputs.number_input,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputs, "number_input", $$v)
                    },
                    expression: "inputs.number_input",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group row align-items-center" },
              [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 mb-sm-0",
                    attrs: { for: "readonly_input" },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$t("readonly")) +
                        "\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("app-input", {
                  staticClass: "col-sm-8",
                  attrs: {
                    id: "readonly_input",
                    type: "text",
                    "read-only": true,
                    placeholder: _vm.$t("readonly_input"),
                  },
                  model: {
                    value: _vm.inputs.readonly_input,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputs, "readonly_input", $$v)
                    },
                    expression: "inputs.readonly_input",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group row align-items-center" },
              [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 mb-sm-0",
                    attrs: { for: "disabled_input" },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$t("disabled")) +
                        "\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("app-input", {
                  staticClass: "col-sm-8",
                  attrs: {
                    id: "disabled_input",
                    type: "text",
                    disabled: true,
                    placeholder: _vm.$t("disabled_input"),
                  },
                  model: {
                    value: _vm.inputs.disabled_input,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputs, "disabled_input", $$v)
                    },
                    expression: "inputs.disabled_input",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group row align-items-center" },
              [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-2 mb-sm-0",
                    attrs: { for: "textarea_field" },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$t("textarea")) +
                        "\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("app-input", {
                  staticClass: "col-sm-8",
                  attrs: {
                    id: "textarea_field",
                    type: "textarea",
                    placeholder: _vm.$t("textarea_field"),
                  },
                  model: {
                    value: _vm.inputs.textarea,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputs, "textarea", $$v)
                    },
                    expression: "inputs.textarea",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "mt-5 action-buttons" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary mr-2",
                  attrs: { type: "submit" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("submit")) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  attrs: { type: "submit" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.clear.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("clear")) +
                      "\n                "
                  ),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }