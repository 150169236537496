var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("app-table", {
        attrs: {
          id: "all-payment-methods-table",
          showFilter: "",
          options: _vm.options,
        },
        on: { action: _vm.triggerActions },
      }),
      _vm._v(" "),
      _vm.isModalActive
        ? _c("payment-method-create-edit-modal", {
            attrs: {
              "modal-id": "payment-method-create-edit-modal",
              "selected-url": _vm.selectedUrl,
            },
            on: {
              closeModal: function ($event) {
                _vm.isModalActive = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.confirmationModalActive
        ? _c("app-confirmation-modal", {
            attrs: {
              firstButtonName: _vm.$t("yes"),
              "modal-class": "warning",
              "modal-id": "app-confirmation-modal",
            },
            on: {
              confirmed: function ($event) {
                return _vm.confirmed("all-payment-methods-table")
              },
              cancelled: _vm.cancelled,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }