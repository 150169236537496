var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "position-relative" }, [
    _vm.countCreatedResponse < 4
      ? _c(
          "div",
          {
            staticClass:
              "root-preloader position-absolute overlay-loader-wrapper",
          },
          [_vm._m(0)]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content-wrapper" },
      [
        _c("app-breadcrumb", {
          attrs: {
            "page-title": _vm.$t("hrm"),
            directory: _vm.$t("dashboard"),
            icon: "pie-chart",
          },
        }),
        _vm._v(" "),
        !_vm.isActiveBasic && !_vm.mainPreloader
          ? _c("app-overlay-loader")
          : _vm._e(),
        _vm._v(" "),
        _vm.isActiveBasic
          ? _c(
              "div",
              { staticClass: "row" },
              _vm._l(_vm.hrm.basicData.topSectionData, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "col-12 col-sm-6 col-md-3" },
                  [
                    _c("app-widget", {
                      staticClass: "mb-primary",
                      attrs: {
                        type: "app-widget-with-icon",
                        label: item.label,
                        number: _vm.numberFormat(item.number),
                        icon: item.icon,
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12 col-md-8 col-xl-9 mb-primary" },
            [
              !_vm.isActiveProjectOverview && !_vm.mainPreloader
                ? _c("app-overlay-loader")
                : _vm._e(),
              _vm._v(" "),
              _vm.isActiveProjectOverview
                ? _c(
                    "div",
                    { staticClass: "card card-with-shadow border-0 h-100" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "card-header bg-transparent p-primary d-flex justify-content-between align-items-center",
                        },
                        [
                          _c("h5", { staticClass: "card-title mb-0" }, [
                            _vm._v(_vm._s(_vm.$t("project_overview"))),
                          ]),
                          _vm._v(" "),
                          _c(
                            "ul",
                            {
                              staticClass:
                                "nav tab-filter-menu justify-content-flex-end",
                            },
                            _vm._l(
                              _vm.hrm.projectOverview.chartFilterOptions,
                              function (item, index) {
                                return _c(
                                  "li",
                                  { key: index, staticClass: "nav-item" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "nav-link py-0",
                                        class: [
                                          _vm.projectFilter == item.id
                                            ? "active"
                                            : index === 0 &&
                                              _vm.projectFilter === ""
                                            ? "active"
                                            : "",
                                        ],
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.getProjectFilterValue(
                                              item.id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(item.value) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "card-body" },
                        [
                          _c("app-chart", {
                            staticClass: "mb-primary",
                            attrs: {
                              type: "custom-line-chart",
                              height: 380,
                              labels: _vm.hrm.projectOverview.labels,
                              "data-sets": _vm.hrm.projectOverview.chartData,
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "chart-data-list d-flex flex-wrap justify-content-center",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "data-group-item",
                                  staticStyle: { color: "rgb(81, 194, 213)" },
                                },
                                [
                                  _c("span", {
                                    staticClass: "square",
                                    staticStyle: {
                                      "background-color": "rgb(81, 194, 213)",
                                    },
                                  }),
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.$t("users")) +
                                      "\n                                "
                                  ),
                                  _c("span", { staticClass: "value" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.hrm.projectOverview.users)
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "data-group-item",
                                  staticStyle: { color: "rgb(235, 89, 110)" },
                                },
                                [
                                  _c("span", {
                                    staticClass: "square",
                                    staticStyle: {
                                      "background-color": "rgb(235, 89, 110)",
                                    },
                                  }),
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.$t("visitors")) +
                                      "\n                                "
                                  ),
                                  _c("span", { staticClass: "value" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.hrm.projectOverview.visitors)
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-12 col-md-4 col-xl-3 mb-primary" },
            [
              !_vm.isActiveYearlyHoliday && !_vm.mainPreloader
                ? _c("app-overlay-loader")
                : _vm._e(),
              _vm._v(" "),
              _vm.isActiveYearlyHoliday
                ? _c(
                    "div",
                    { staticClass: "card card-with-shadow border-0 h-100" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "card-header bg-transparent p-primary d-flex justify-content-between align-items-center",
                        },
                        [
                          _c("h5", { staticClass: "card-title mb-0" }, [
                            _vm._v(_vm._s(_vm.$t("yearly_holiday"))),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "card-body" },
                        [
                          _c("app-chart", {
                            staticClass: "mb-primary",
                            attrs: {
                              type: "dough-chart",
                              height: 230,
                              labels: _vm.hrm.yearlyHoliday.labels,
                              "data-sets": _vm.hrm.yearlyHoliday.dataSet,
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "chart-data-list" }, [
                            _c(
                              "div",
                              { staticClass: "row" },
                              _vm._l(
                                _vm.hrm.yearlyHoliday.chartElement,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "col-12" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "data-group-item",
                                          style: item.color,
                                        },
                                        [
                                          _c("span", {
                                            staticClass: "square",
                                            style: item.background_color,
                                          }),
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(item.key) +
                                              "\n                                        "
                                          ),
                                          _c("span", { staticClass: "value" }, [
                                            _vm._v(_vm._s(item.value)),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row" },
          [
            !_vm.isActiveBasic && !_vm.mainPreloader
              ? _c("app-overlay-loader")
              : _vm._e(),
            _vm._v(" "),
            _vm.isActiveBasic
              ? _c(
                  "div",
                  {
                    staticClass: "col-12 col-sm-12 col-md-5 col-lg-5 col-xl-4",
                  },
                  _vm._l(
                    _vm.hrm.basicData.middleSectionData,
                    function (item, index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          _c("app-widget", {
                            class: {
                              "mb-primary":
                                index !==
                                _vm.hrm.basicData.middleSectionData.length - 1,
                            },
                            attrs: {
                              type: "app-widget-with-icon",
                              label: item.label,
                              number: item.number,
                              icon: item.icon,
                            },
                          }),
                        ],
                        1
                      )
                    }
                  ),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-12 col-sm-12 col-md-7 col-lg-7 col-xl-8" },
              [
                !_vm.isActiveEmployeeOverview && !_vm.mainPreloader
                  ? _c("app-overlay-loader")
                  : _vm._e(),
                _vm._v(" "),
                _vm.isActiveEmployeeOverview
                  ? _c(
                      "div",
                      { staticClass: "card card-with-shadow border-0 h-100" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "card-header bg-transparent p-primary d-flex justify-content-between align-items-center",
                          },
                          [
                            _c("h5", { staticClass: "card-title mb-0" }, [
                              _vm._v(_vm._s(_vm.$t("employee_overview"))),
                            ]),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "nav tab-filter-menu justify-content-flex-end",
                              },
                              _vm._l(
                                _vm.hrm.employeeOverview.filters,
                                function (item, index) {
                                  return _c(
                                    "li",
                                    { key: index, staticClass: "nav-item" },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "nav-link py-0",
                                          class: [
                                            _vm.employeeFilter == item.id
                                              ? "active"
                                              : index === 0 &&
                                                _vm.employeeFilter === ""
                                              ? "active"
                                              : "",
                                          ],
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.getEmployeeFilterValue(
                                                item.id
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(item.value) +
                                              "\n                                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "card-body" },
                          [
                            _c("app-chart", {
                              attrs: {
                                type: "horizontal-line-chart",
                                height: 240,
                                labels: _vm.hrm.employeeOverview.labels,
                                "data-sets": _vm.hrm.employeeOverview.dataSet,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "spinner-bounce d-flex align-items-center dashboard-preloader justify-content-center",
      },
      [
        _c("span", { staticClass: "bounce1 mr-1" }),
        _vm._v(" "),
        _c("span", { staticClass: "bounce2 mr-1" }),
        _vm._v(" "),
        _c("span", { staticClass: "bounce3 mr-1" }),
        _vm._v(" "),
        _c("span", { staticClass: "bounce4" }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }