var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card card-with-shadow border-0 mb-primary" },
    [
      _c("div", { staticClass: "card-header p-primary bg-transparent" }, [
        _c("h5", { staticClass: "card-title m-0" }, [
          _vm._v(_vm._s(_vm.$t("files_upload"))),
        ]),
      ]),
      _vm._v(" "),
      _vm.preloader ? _c("app-overlay-loader") : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-sm-10" },
            [
              _c("app-note", {
                attrs: {
                  title: _vm.$t("note"),
                  notes: _vm.$t("note_for_file_upload"),
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "form",
          {
            ref: "form",
            staticClass: "mb-0 mt-primary",
            class: { "loading-opacity": _vm.preloader },
            attrs: { "data-url": "/form/files" },
          },
          [
            _c(
              "div",
              { staticClass: "form-group mb-primary row align-items-center" },
              [
                _c("label", { staticClass: "col-sm-2 mb-sm-0" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("default_upload")) +
                      "\n                "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-sm-8 default-file" },
                  [
                    _c("app-input", {
                      attrs: {
                        type: "file",
                        label: _vm.$t("upload_your_file"),
                      },
                      model: {
                        value: _vm.inputs.default_file,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputs, "default_file", $$v)
                        },
                        expression: "inputs.default_file",
                      },
                    }),
                    _vm._v(" "),
                    _c("small", { staticClass: "text-muted font-italic" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.$t("recommended_default_file_validation")
                          ) +
                          "\n                    "
                      ),
                    ]),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "form-group row mb-0" }, [
              _c("label", { staticClass: "col-sm-2 mb-sm-0" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.$t("upload_with_preview")) +
                    "\n                "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-sm-8" },
                [
                  _c("app-input", {
                    attrs: {
                      type: "custom-file-upload",
                      label: _vm.$t("upload_your_file"),
                    },
                    model: {
                      value: _vm.inputs.custom_file,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "custom_file", $$v)
                      },
                      expression: "inputs.custom_file",
                    },
                  }),
                  _vm._v(" "),
                  _c("small", { staticClass: "text-muted font-italic" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("recommended_custom_file_validation")) +
                        "\n                    "
                    ),
                  ]),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mt-5 action-buttons" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary mr-2",
                  attrs: { type: "submit" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("upload")) +
                      "\n                "
                  ),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }