var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeAllFilterDropdown,
          expression: "closeAllFilterDropdown",
        },
      ],
      staticClass: "filters-loop-wrapper",
    },
    [
      _vm._l(_vm.filterComputed, function (filter, index) {
        return [
          filter.type === "range-picker"
            ? _c("date-range-filter", {
                key: "range-picker" + index,
                attrs: {
                  "table-id": _vm.tableId,
                  "filter-key": filter.key,
                  label: filter.title,
                  "visible-value": false,
                  "preset-ranges": filter.option,
                  active: filter.active,
                  default: filter.default,
                },
                on: { "get-value": _vm.store },
              })
            : _vm._e(),
          _vm._v(" "),
          filter.type === "range-filter"
            ? _c("range-filter", {
                key: "range-filter" + index,
                attrs: {
                  "filter-key": filter.key,
                  "table-id": _vm.tableId,
                  label: filter.title,
                  "max-range": filter.maxRange,
                  "min-range": filter.minRange,
                  "max-title": filter.maxTitle,
                  "min-title": filter.minTitle,
                  sign: filter.sign,
                  active: filter.active,
                  default: filter.default,
                },
                on: { "get-value": _vm.store },
              })
            : _vm._e(),
          _vm._v(" "),
          filter.type === "checkbox"
            ? _c("checkbox-filter", {
                key: "checkbox" + index,
                attrs: {
                  "filter-key": filter.key,
                  "table-id": _vm.tableId,
                  label: filter.title,
                  "filter-option": filter.option,
                  "list-value-field": filter.listValueField,
                  active: filter.active,
                  default: filter.default,
                },
                on: { "get-value": _vm.store },
              })
            : _vm._e(),
          _vm._v(" "),
          filter.type === "radio"
            ? _c("radio-filter", {
                key: "radio" + index,
                attrs: {
                  "filter-key": filter.key,
                  "table-id": _vm.tableId,
                  label: filter.title,
                  "filter-option": filter.option,
                  "list-value-field": filter.listValueField,
                  active: filter.active,
                  default: filter.default,
                  header: filter.header,
                },
                on: { "get-value": _vm.store },
              })
            : _vm._e(),
          _vm._v(" "),
          filter.type === "drop-down-filter"
            ? _c("drop-down-filter", {
                key: "drop-down-filter" + index,
                attrs: {
                  "filter-key": filter.key,
                  id: `${filter.key}-${_vm.tableId}`,
                  "table-id": _vm.tableId,
                  list: filter.option,
                  label: filter.title,
                  "list-value-field": filter.listValueField,
                  active: filter.active,
                  default: filter.default,
                },
                on: { "get-value": _vm.store },
              })
            : _vm._e(),
          _vm._v(" "),
          filter.type === "dropdown-menu-filter"
            ? _c("dropdown-menu-filter", {
                key: "dropdown-menu-filter" + index,
                attrs: {
                  "filter-key": filter.key,
                  "table-id": _vm.tableId,
                  list: filter.option,
                  label: filter.title,
                  active: filter.active,
                  default: filter.default,
                  "list-value-field": filter.listValueField,
                },
                on: { "get-value": _vm.store },
              })
            : _vm._e(),
          _vm._v(" "),
          filter.type === "search-and-select-filter"
            ? _c("search-and-select-filter", {
                key: "search-and-select-filter" + index,
                attrs: {
                  "filter-key": filter.key,
                  "table-id": _vm.tableId,
                  list: filter.option,
                  label: filter.title,
                  active: filter.active,
                  default: filter.default,
                  "list-value-field": filter.listValueField,
                  settings: filter.settings,
                },
                on: { "get-value": _vm.store },
              })
            : _vm._e(),
          _vm._v(" "),
          filter.type === "multi-select-filter"
            ? _c("multi-select-filter", {
                key: "multi-select-filter" + index,
                attrs: {
                  "filter-key": filter.key,
                  "table-id": _vm.tableId,
                  list: filter.option,
                  label: filter.title,
                  active: filter.active,
                  default: filter.default,
                  "list-value-field": filter.listValueField,
                },
                on: { "get-value": _vm.store },
              })
            : _vm._e(),
          _vm._v(" "),
          filter.type === "date"
            ? _c("date-filter", {
                key: "date-filter" + index,
                attrs: {
                  "table-id": _vm.tableId,
                  label: filter.title,
                  "filter-key": filter.key,
                  active: filter.active,
                  default: filter.default,
                },
                on: { "get-value": _vm.store },
              })
            : _vm._e(),
          _vm._v(" "),
          filter.type === "tab-filter"
            ? _c("app-tab-filter", {
                key: "tab-filter" + index,
                attrs: {
                  "table-id": _vm.tableId,
                  "filter-key": filter.key,
                  "filter-option": filter.option,
                },
                on: { "get-value": _vm.store },
              })
            : _vm._e(),
          _vm._v(" "),
          filter.type === "avatar-filter"
            ? _c("avatar-filter", {
                key: "avatar-filter" + index,
                attrs: {
                  "table-id": _vm.tableId,
                  "filter-key": filter.key,
                  "img-relationship": filter.imgRelationship,
                  "img-key": filter.imgKey,
                  list: filter.option,
                  label: filter.title,
                  "list-value-field": filter.listValueField,
                  active: filter.active,
                  default: filter.default,
                },
                on: { "get-value": _vm.store },
              })
            : _vm._e(),
          _vm._v(" "),
          filter.type === "toggle-filter"
            ? _c("toggle-filter", {
                key: "toggle-filter" + index,
                attrs: {
                  "table-id": _vm.tableId,
                  "filter-key": filter.key,
                  label: filter.title,
                  header: filter.header,
                  active: filter.active,
                  default: filter.default,
                  "button-label": filter.buttonLabel,
                },
                on: { "get-value": _vm.store },
              })
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }