var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "input",
    _vm._g(
      {
        class: "form-control " + _vm.data.inputClass,
        attrs: {
          type: _vm.data.type,
          name: _vm.name,
          id: _vm.inputFieldId,
          required: _vm.data.required,
          maxlength: _vm.data.maxLength,
          minlength: _vm.data.minlength,
          alphanumeric: _vm.data.alphanumeric,
          placeholder: _vm.data.placeholder,
          readonly: _vm.data.readOnly,
          disabled: _vm.data.disabled,
          autocomplete: _vm.data.autocomplete,
        },
        domProps: { value: _vm.value },
      },
      _vm.listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }