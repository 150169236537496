var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "app-modal",
    {
      attrs: {
        "modal-alignment": "top",
        "modal-id": "custom-field-modal",
        "modal-size": "large",
      },
      on: { "close-modal": _vm.closeModal },
    },
    [
      _c("template", { slot: "header" }, [
        _c("h5", { staticClass: "modal-title" }, [
          _vm._v(_vm._s(_vm.selectedUrl ? _vm.$t("edit") : _vm.$t("add"))),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close outline-none",
            attrs: {
              "aria-label": "Close",
              "data-dismiss": "modal",
              type: "button",
            },
          },
          [_c("span", [_c("app-icon", { attrs: { name: "x" } })], 1)]
        ),
      ]),
      _vm._v(" "),
      _vm.preloader ? _c("app-overlay-loader") : _vm._e(),
      _vm._v(" "),
      _c("template", { slot: "body" }, [
        _c(
          "form",
          {
            ref: "form",
            class: { "loading-opacity": _vm.preloader },
            attrs: {
              "data-url": _vm.selectedUrl ? _vm.selectedUrl : _vm.CUSTOM_TABLE,
            },
          },
          [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", [
                  _vm._v(_vm._s(_vm.$t("name"))),
                  _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                ]),
                _vm._v(" "),
                _c("app-input", {
                  attrs: {
                    placeholder: _vm.$t("enter_custom_table_name"),
                    "error-message": _vm.$errorMessage(_vm.errors, "name"),
                  },
                  model: {
                    value: _vm.formData.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "name", $$v)
                    },
                    expression: "formData.name",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", [
                  _vm._v(_vm._s(_vm.$t("context"))),
                  _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                ]),
                _vm._v(" "),
                _c("app-input", {
                  attrs: {
                    list: [
                      {
                        id: "",
                        value: _vm.$t("chose_context"),
                        disabled: true,
                      },
                      { id: "datatable", value: _vm.$t("datatable") },
                    ],
                    type: "select",
                    "error-message": _vm.$errorMessage(_vm.errors, "context"),
                  },
                  model: {
                    value: _vm.formData.context,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "context", $$v)
                    },
                    expression: "formData.context",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.customErrorMessage
              ? _c("div", { staticClass: "mb-3" }, [
                  _c("span", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(_vm.customErrorMessage)),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-4 form-group" },
                [
                  _c("label", [
                    _vm._v(_vm._s(_vm.$t("column_name"))),
                    _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                  ]),
                  _vm._v(" "),
                  _c("app-input", {
                    attrs: { placeholder: _vm.$t("enter_column_name") },
                    model: {
                      value: _vm.customField.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.customField, "name", $$v)
                      },
                      expression: "customField.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-4 form-group" },
                [
                  _c("label", [
                    _vm._v(_vm._s(_vm.$t("custom_field_type"))),
                    _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                  ]),
                  _vm._v(" "),
                  _c("app-input", {
                    attrs: {
                      list: _vm.customFieldTypes,
                      "list-value-field": "translated_name",
                      type: "select",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.setCustomFieldType($event)
                      },
                    },
                    model: {
                      value: _vm.customField.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.customField, "type", $$v)
                      },
                      expression: "customField.type",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.custom_field_type.choose
                ? _c(
                    "div",
                    { staticClass: "col-4 d-flex align-items-center" },
                    [
                      _c(
                        "span",
                        { on: { click: _vm.addToTable } },
                        [
                          _c("app-icon", {
                            staticClass: "size-20 mr-2",
                            attrs: { name: "plus" },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _vm.custom_field_type.choose
              ? _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-8 form-group" },
                    [
                      _c("label", { staticClass: "d-block" }, [
                        _vm._v(_vm._s(_vm.$t("options"))),
                        _c("span", { staticClass: "text-danger" }, [
                          _vm._v("*"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("small", { staticClass: "text-muted" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.$t(
                                "use_comma_separated_values_as_options_of_the_field"
                              )
                            ) +
                            "\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("app-input", {
                        attrs: {
                          placeholder: _vm.$t("custom_field_options"),
                          type: "textarea",
                        },
                        model: {
                          value: _vm.customField.meta,
                          callback: function ($$v) {
                            _vm.$set(_vm.customField, "meta", $$v)
                          },
                          expression: "customField.meta",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-4 d-flex align-items-center" },
                    [
                      _c(
                        "span",
                        { on: { click: _vm.addToTable } },
                        [
                          _c("app-icon", {
                            staticClass: "size-20 mr-2",
                            attrs: { name: "plus" },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.formData.customFieldName.length
              ? _c("table", { staticClass: "table" }, [
                  _c("thead", { staticClass: "thead-light" }, [
                    _c("tr", [
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v(_vm._s(_vm.$t("column_name"))),
                      ]),
                      _vm._v(" "),
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v(_vm._s(_vm.$t("field_type"))),
                      ]),
                      _vm._v(" "),
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v(_vm._s(_vm.$t("required"))),
                      ]),
                      _vm._v(" "),
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v(_vm._s(_vm.$t("options"))),
                      ]),
                      _vm._v(" "),
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v(_vm._s(_vm.$t("action"))),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(
                      _vm.formData.customFieldName,
                      function (field, index) {
                        return _c("tr", { key: index }, [
                          _c(
                            "th",
                            { attrs: { scope: "row" } },
                            [
                              _c("app-input", {
                                attrs: {
                                  id: `name${index}`,
                                  placeholder: _vm.$t("enter_column_name"),
                                  required: true,
                                },
                                model: {
                                  value: _vm.formData.customFieldName[index],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData.customFieldName,
                                      index,
                                      $$v
                                    )
                                  },
                                  expression: "formData.customFieldName[index]",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            [
                              _c("app-input", {
                                attrs: {
                                  list: _vm.customFieldTypes,
                                  "list-value-field": "translated_name",
                                  type: "select",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.setTableCustomFieldType(
                                      $event,
                                      index
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.formData.customFieldType[index],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData.customFieldType,
                                      index,
                                      $$v
                                    )
                                  },
                                  expression: "formData.customFieldType[index]",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c("div", { staticClass: "form-check" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.formData.customFieldRequired[index],
                                      expression:
                                        "formData.customFieldRequired[index]",
                                    },
                                  ],
                                  staticClass: "form-check-input",
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.formData.customFieldRequired[index]
                                    )
                                      ? _vm._i(
                                          _vm.formData.customFieldRequired[
                                            index
                                          ],
                                          null
                                        ) > -1
                                      : _vm.formData.customFieldRequired[index],
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a =
                                          _vm.formData.customFieldRequired[
                                            index
                                          ],
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.formData.customFieldRequired,
                                              index,
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.formData.customFieldRequired,
                                              index,
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.formData.customFieldRequired,
                                          index,
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            [
                              _vm.formData.customFieldMetaValue[index]
                                ? _c("app-input", {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "use_comma_separated_values_as_options_of_the_field"
                                      ),
                                      type: "textarea",
                                      required: true,
                                    },
                                    model: {
                                      value:
                                        _vm.formData.customFieldMeta[index],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData.customFieldMeta,
                                          index,
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formData.customFieldMeta[index]",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("td", [
                            _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.removeSelectedField(index)
                                  },
                                },
                              },
                              [
                                _c("app-icon", {
                                  staticClass: "text-primary mt-2",
                                  attrs: { name: "trash", width: "22" },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ])
                      }
                    ),
                    0
                  ),
                ])
              : _vm._e(),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-secondary mr-2",
              attrs: { "data-dismiss": "modal", type: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.closeModal.apply(null, arguments)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("cancel")) + "\n        ")]
          ),
          _vm._v(" "),
          _c("app-submit-button", {
            attrs: { loading: _vm.loading },
            on: { click: _vm.submitData },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }