var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.preloader
    ? _c("app-overlay-loader")
    : _c(
        "form",
        {
          ref: "form",
          staticClass: "mb-0",
          class: { "loading-opacity": _vm.preloader },
          attrs: { "data-url": "/admin/app/settings/delivery-settings" },
        },
        [
          _c("div", { staticClass: "form-group row align-items-center" }, [
            _c(
              "label",
              {
                staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                attrs: { for: "emailSettingsProvider" },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("supported_mail_services")) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-lg-8 col-xl-8" },
              [
                _c("app-input", {
                  attrs: {
                    id: "emailSettingsProvider",
                    type: "select",
                    list: _vm.providerList,
                    required: true,
                  },
                  model: {
                    value: _vm.emailSettings.provider,
                    callback: function ($$v) {
                      _vm.$set(_vm.emailSettings, "provider", $$v)
                    },
                    expression: "emailSettings.provider",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group row align-items-center" }, [
            _c(
              "label",
              {
                staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                attrs: { for: "emailSettingsFromName" },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("email_sent_from_name")) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-lg-8 col-xl-8" },
              [
                _c("app-input", {
                  attrs: {
                    id: "emailSettingsFromName",
                    type: "text",
                    placeholder: _vm.$t("type_email_sent_from_name"),
                    required: true,
                  },
                  model: {
                    value: _vm.emailSettings.from_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.emailSettings, "from_name", $$v)
                    },
                    expression: "emailSettings.from_name",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group row align-items-center" }, [
            _c(
              "label",
              {
                staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                attrs: { for: "emailSettingsFromEmail" },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("email_sent_from_email")) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-lg-8 col-xl-8" },
              [
                _c("app-input", {
                  attrs: {
                    id: "emailSettingsFromEmail",
                    type: "email",
                    placeholder: _vm.$t("type_email_sent_from_email"),
                    required: true,
                  },
                  model: {
                    value: _vm.emailSettings.from_email,
                    callback: function ($$v) {
                      _vm.$set(_vm.emailSettings, "from_email", $$v)
                    },
                    expression: "emailSettings.from_email",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.emailSettings.provider === "ses"
            ? _c("fieldset", [
                _c(
                  "div",
                  { staticClass: "form-group row align-items-center" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                        attrs: { for: "emailSettingsHostname" },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("hostname")) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-lg-8 col-xl-8" },
                      [
                        _c("app-input", {
                          attrs: {
                            id: "emailSettingsHostname",
                            type: "text",
                            placeholder: _vm.$t("type_host_name"),
                            required: true,
                          },
                          model: {
                            value: _vm.emailSettings.hostname,
                            callback: function ($$v) {
                              _vm.$set(_vm.emailSettings, "hostname", $$v)
                            },
                            expression: "emailSettings.hostname",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group row align-items-center" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                        attrs: { for: "emailSettingsAccessKeyId" },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("access_key_id")) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-lg-8 col-xl-8" },
                      [
                        _c("app-input", {
                          attrs: {
                            id: "emailSettingsAccessKeyId",
                            type: "text",
                            placeholder: _vm.$t("type_access_key_id"),
                            required: true,
                          },
                          model: {
                            value: _vm.emailSettings.access_key_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.emailSettings, "access_key_id", $$v)
                            },
                            expression: "emailSettings.access_key_id",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group row align-items-center" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                        attrs: { for: "emailSettingsSecretAccessKey" },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("secret_access_key")) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-lg-8 col-xl-8" },
                      [
                        _c("app-input", {
                          attrs: {
                            id: "emailSettingsSecretAccessKey",
                            type: "text",
                            placeholder: _vm.$t("type_secret_access_key"),
                            required: true,
                          },
                          model: {
                            value: _vm.emailSettings.secret_access_key,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.emailSettings,
                                "secret_access_key",
                                $$v
                              )
                            },
                            expression: "emailSettings.secret_access_key",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group row align-items-center" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                        attrs: { for: "emailSettingsRegion" },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("region")) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-lg-8 col-xl-8" },
                      [
                        _c("app-input", {
                          attrs: {
                            id: "emailSettingsRegion",
                            type: "text",
                            placeholder: _vm.$t("region"),
                            required: true,
                          },
                          model: {
                            value: _vm.emailSettings.region,
                            callback: function ($$v) {
                              _vm.$set(_vm.emailSettings, "region", $$v)
                            },
                            expression: "emailSettings.region",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ])
            : _vm.emailSettings.provider === "mailgun"
            ? _c("fieldset", [
                _c(
                  "div",
                  { staticClass: "form-group row align-items-center" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                        attrs: { for: "emailSettingsDomainName" },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("domain_name")) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-lg-8 col-xl-8" },
                      [
                        _c("app-input", {
                          attrs: {
                            id: "emailSettingsDomainName",
                            type: "text",
                            placeholder: _vm.$t("type_domain_name"),
                            required: true,
                          },
                          model: {
                            value: _vm.emailSettings.domain_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.emailSettings, "domain_name", $$v)
                            },
                            expression: "emailSettings.domain_name",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group row align-items-center" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                        attrs: { for: "emailSettingsApiKey" },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("api_key")) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-lg-8 col-xl-8" },
                      [
                        _c("app-input", {
                          attrs: {
                            id: "emailSettingsApiKey",
                            type: "text",
                            placeholder: _vm.$t("type_api_key"),
                            required: true,
                          },
                          model: {
                            value: _vm.emailSettings.api_key,
                            callback: function ($$v) {
                              _vm.$set(_vm.emailSettings, "api_key", $$v)
                            },
                            expression: "emailSettings.api_key",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ])
            : _vm.emailSettings.provider === "smtp"
            ? _c("fieldset", [
                _c(
                  "div",
                  { staticClass: "form-group row align-items-center" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                        attrs: { for: "user_name" },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("user_name")) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-lg-8 col-xl-8" },
                      [
                        _c("app-input", {
                          attrs: {
                            id: "user_name",
                            type: "text",
                            placeholder: _vm.$t("type_user_name"),
                          },
                          model: {
                            value: _vm.emailSettings.smtp_user_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.emailSettings, "smtp_user_name", $$v)
                            },
                            expression: "emailSettings.smtp_user_name",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group row align-items-center" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                        attrs: { for: "emailSettingsSmtpHost" },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("smtp_host")) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-lg-8 col-xl-8" },
                      [
                        _c("app-input", {
                          attrs: {
                            id: "emailSettingsSmtpHost",
                            type: "text",
                            placeholder: _vm.$t("type_smtp_host"),
                            required: true,
                          },
                          model: {
                            value: _vm.emailSettings.smtp_host,
                            callback: function ($$v) {
                              _vm.$set(_vm.emailSettings, "smtp_host", $$v)
                            },
                            expression: "emailSettings.smtp_host",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group row align-items-center" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                        attrs: { for: "emailSettingsSmtpPort" },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("port")) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-lg-8 col-xl-8" },
                      [
                        _c("app-input", {
                          attrs: {
                            id: "emailSettingsSmtpPort",
                            type: "number",
                            placeholder: _vm.$t("type_port_number"),
                            required: true,
                          },
                          model: {
                            value: _vm.emailSettings.smtp_port,
                            callback: function ($$v) {
                              _vm.$set(_vm.emailSettings, "smtp_port", $$v)
                            },
                            expression: "emailSettings.smtp_port",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group row align-items-center" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                        attrs: { for: "emailSettingsEmailPassword" },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("password_to_access")) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-lg-8 col-xl-8" },
                      [
                        _c("app-input", {
                          attrs: {
                            id: "emailSettingsEmailPassword",
                            type: "password",
                            "show-password": true,
                            placeholder: _vm.$t("type_password_to_access"),
                            required: true,
                          },
                          model: {
                            value: _vm.emailSettings.email_password,
                            callback: function ($$v) {
                              _vm.$set(_vm.emailSettings, "email_password", $$v)
                            },
                            expression: "emailSettings.email_password",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group row align-items-center" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                        attrs: { for: "emailSettingsEncryptionType" },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("encryption_type")) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-lg-8 col-xl-8" },
                      [
                        _c("app-input", {
                          attrs: {
                            id: "emailSettingsEncryptionType",
                            type: "select",
                            placeholder: _vm.$t("type_encryption_type"),
                            list: _vm.encryptionType,
                            required: true,
                          },
                          model: {
                            value: _vm.emailSettings.encryption_type,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.emailSettings,
                                "encryption_type",
                                $$v
                              )
                            },
                            expression: "emailSettings.encryption_type",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "mt-5 action-buttons" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary mr-2",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n            " + _vm._s(_vm.$t("save")) + "\n        ")]
            ),
          ]),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }