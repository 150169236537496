var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("app-breadcrumb", {
        attrs: {
          "page-title": _vm.$t("filter_type"),
          directory: _vm.$t("datatables"),
          icon: "grid",
        },
      }),
      _vm._v(" "),
      _c("app-note", {
        staticClass: "mb-primary",
        attrs: {
          title: _vm.$t("note"),
          "content-type": "html",
          notes: _vm.responsiveDatatableInstruction,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row" },
        [_c("default-filter"), _vm._v(" "), _c("tab-filter")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }