var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "editor", class: { disabled: _vm.data.disabled } },
    [_c("textarea", { attrs: { id: _vm.inputFieldId, name: _vm.name } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }