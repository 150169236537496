var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content-wrapper" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12 col-sm-6 col-md-8 col-lg-8 col-xl-9" },
        [
          _c("app-breadcrumb", {
            attrs: {
              "page-title": _vm.$t("icons"),
              directory: _vm.$t("ui_elements"),
              icon: "trello",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3" }, [
        _c("div", { staticClass: "single-search-wrapper" }, [
          _c(
            "div",
            {
              staticClass:
                "form-group form-group-with-search d-flex align-items-center",
            },
            [
              _c(
                "span",
                { staticClass: "form-control-feedback" },
                [_c("app-icon", { attrs: { name: "search" } })],
                1
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchValue,
                    expression: "searchValue",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", placeholder: _vm.$t("search") },
                domProps: { value: _vm.searchValue },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.searchValue = $event.target.value
                  },
                },
              }),
            ]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row" },
      _vm._l(_vm.showIcon, function (icon, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "col-6 col-sm-4 col-md-3 col-xl-2 mb-primary",
          },
          [
            _c("div", { staticClass: "card card-with-shadow border-0 h-100" }, [
              _c(
                "a",
                {
                  staticClass: "default-font-color",
                  attrs: {
                    href: "#",
                    "data-toggle": "tooltip",
                    "data-placement": "top",
                    "data-copy": icon,
                    title: _vm.$t("copy_icon_to_clipboard"),
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.copyIconDom("copy-icon-dom", icon)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "card-body text-center" }, [
                    _c(
                      "span",
                      { key: icon },
                      [_c("app-icon", { attrs: { name: icon } })],
                      1
                    ),
                    _vm._v(" "),
                    _c("h6", { staticClass: "mt-primary text-center" }, [
                      _vm._v(_vm._s(icon)),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "textarea",
      {
        staticClass: "d-none",
        attrs: { type: "text", id: "copy-icon-dom", readonly: "readonly" },
      },
      [_vm._v("        " + _vm._s(_vm.iconDomValue) + "\n    ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }