var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "horizontal-tab" }, [
    _c(
      "div",
      {
        staticClass: "card border-0",
        class: { "card-with-shadow": _vm.elevated },
      },
      [
        _c("nav", { staticClass: "d-flex" }, [
          _vm.isScrollAble
            ? _c("div", { staticClass: "nav" }, [
                _c(
                  "button",
                  {
                    class: `${_vm.tabClass} btn btn-link btn_cus`,
                    on: { mousedown: _vm.scrollLeft, mouseup: _vm.scrollLeft },
                  },
                  [_c("app-icon", { attrs: { name: "chevron-left" } })],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "tabs_wrapper",
              staticClass: "nav-tabs hide-scrollbar w-100",
              attrs: { id: "tabs_wrapper" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "nav flex-nowrap dragable",
                  staticStyle: { "white-space": "nowrap" },
                  attrs: { draggable: "true" },
                  on: {
                    mousedown: _vm.drag,
                    mouseup: _vm.drag,
                    mouseleave: _vm.drag,
                  },
                },
                _vm._l(_vm.filteredTab, function (tab, index) {
                  return _c(
                    "a",
                    {
                      key: index,
                      staticClass: "nav-item text-capitalize",
                      class: `${_vm.tabClass} ${
                        _vm.currentIndex == index ? "active" : ""
                      }`,
                      attrs: {
                        "data-toggle": "tab",
                        href: "#" + tab.name + "-" + index,
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          if (
                            $event.ctrlKey ||
                            $event.shiftKey ||
                            $event.altKey ||
                            $event.metaKey
                          )
                            return null
                          return _vm.loadComponent(tab, index)
                        },
                      },
                    },
                    [
                      tab.icon
                        ? _c(
                            "span",
                            { staticClass: "mr-2" },
                            [_c("app-icon", { attrs: { name: tab.icon } })],
                            1
                          )
                        : _vm._e(),
                      _vm._v(_vm._s(tab.name) + "\n                    "),
                    ]
                  )
                }),
                0
              ),
            ]
          ),
          _vm._v(" "),
          _vm.isScrollAble
            ? _c("div", { staticClass: "nav" }, [
                _c(
                  "button",
                  {
                    class: `${_vm.tabClass} btn btn-link btn_cus`,
                    on: {
                      mousedown: _vm.scrollRight,
                      mouseup: _vm.scrollRight,
                    },
                  },
                  [_c("app-icon", { attrs: { name: "chevron-right" } })],
                  1
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "tab-content",
            class: _vm.componentClass || _vm.bodyClass,
          },
          [
            _c(
              "div",
              {
                staticClass: "tab-pane fade show active",
                attrs: { id: _vm.componentId },
              },
              [
                _c(_vm.componentName, {
                  key: `dynamic-component-${
                    _vm.componentProps ? _vm.componentProps.id : ""
                  }`,
                  tag: "component",
                  attrs: { props: _vm.componentProps },
                }),
              ],
              1
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }