var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c(
        "div",
        { staticClass: "d-flex justify-content-between" },
        [
          _c("app-breadcrumb", {
            attrs: { "page-title": _vm.$t("kanban_view") },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "card card-with-shadow border-0" }, [
        _c(
          "div",
          {
            staticClass: "card-body position-relative",
            class: { "loading-opacity": _vm.kanbanViewLoader },
            staticStyle: { padding: "1rem", "min-height": "400px" },
          },
          [
            _vm.kanbanViewLoader
              ? _c("app-overlay-loader")
              : _c(
                  "div",
                  {
                    staticClass:
                      "kanban-wrapper custom-scrollbar overflow-auto",
                    class: {
                      "justify-content-center align-items-center":
                        !_vm.kanbanViewData.length,
                    },
                    staticStyle: { "min-height": "400px" },
                  },
                  [
                    _vm.kanbanViewData.length
                      ? _vm._l(_vm.kanbanViewData, function (stage, index) {
                          return _c(
                            "div",
                            {
                              key: "kanban-view-" + index,
                              staticClass: "kanban-column",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "p-3 mb-2 stage-header" },
                                [
                                  _c("h6", [_vm._v(_vm._s(stage.name))]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-muted d-flex flex-wrap align-items-center stage-information",
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            stage["tasks"].length +
                                              " " +
                                              _vm.$t("tasks")
                                          ) + " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "draggable",
                                {
                                  staticClass: "kanban-draggable-column",
                                  attrs: {
                                    list: stage["tasks"],
                                    group: "deals",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.changedPosition(
                                        $event,
                                        stage.id
                                      )
                                    },
                                  },
                                },
                                _vm._l(
                                  stage["tasks"],
                                  function (element, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass:
                                          "card card-with-shadow mb-2 border-0 draggable-item",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "card-body font-size-90",
                                          },
                                          [
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "col-10" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "media d-flex align-items-start mb-primary",
                                                    },
                                                    [
                                                      _c("app-avatar", {
                                                        staticClass: "mr-2",
                                                        attrs: {
                                                          title: element.title,
                                                          "avatar-class":
                                                            "avatars-w-20",
                                                          img: element.img
                                                            ? element.img
                                                            : "",
                                                          "alt-text":
                                                            _vm.$t("not_found"),
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "media-body d-flex justify-content-start",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    " +
                                                              _vm._s(
                                                                element.title
                                                              ) +
                                                              "\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col-2" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "dropdown options-dropdown d-inline-block",
                                                    },
                                                    [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn-option btn",
                                                          attrs: {
                                                            type: "button",
                                                            "data-toggle":
                                                              "dropdown",
                                                            title:
                                                              _vm.$t("actions"),
                                                          },
                                                        },
                                                        [
                                                          _c("app-icon", {
                                                            attrs: {
                                                              name: "more-vertical",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "dropdown-menu dropdown-menu-right py-2 mt-1",
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "dropdown-item px-4 py-2",
                                                              attrs: {
                                                                href: "#",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.editItem(
                                                                      element.id
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                        " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "edit"
                                                                    )
                                                                  ) +
                                                                  "\n                                                    "
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "dropdown-item px-4 py-2",
                                                              attrs: {
                                                                href: "#",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.deleteItem(
                                                                      element.id
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                        " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "delete"
                                                                    )
                                                                  ) +
                                                                  "\n                                                    "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text-muted d-flex align-items-center justify-content-between",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "media d-flex align-items-center",
                                                  },
                                                  [
                                                    _c("app-avatar", {
                                                      staticClass: "mr-2",
                                                      attrs: {
                                                        "avatar-class":
                                                          "avatars-w-20",
                                                        img: _vm.getAppUrl(
                                                          "images/avatar-demo.jpg"
                                                        ),
                                                        "alt-text":
                                                          _vm.$t("not_found"),
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "media-body",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                " +
                                                            _vm._s(
                                                              element.owner_name
                                                            ) +
                                                            "\n                                            "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "draggable-action-wrapper" },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-stage-action shadow",
                                      attrs: {
                                        slot: "footer",
                                        "data-toggle": "modal",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openAddModal(stage.id)
                                        },
                                      },
                                      slot: "footer",
                                    },
                                    [
                                      _c("app-icon", {
                                        staticClass: "mr-1",
                                        attrs: { name: "plus" },
                                      }),
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(_vm.$t("add")) +
                                          "\n                            "
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        })
                      : [
                          _c("app-empty-data-block", {
                            attrs: { message: _vm.$t("nothing_to_show_here") },
                          }),
                        ],
                  ],
                  2
                ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.isTaskModalActive
        ? _c("task-add-edit-modal", {
            attrs: {
              "modal-id": "kanban-view-add-edit-modal",
              "stage-id": _vm.selectedStageId,
              "selected-url": _vm.selectedUrl,
            },
            on: {
              afterSubmitDone: _vm.reloadAllState,
              "close-modal": function ($event) {
                _vm.isTaskModalActive = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.deleteConfirmationModalActive
        ? _c("app-delete-modal", {
            attrs: { preloader: _vm.deleteLoader, "modal-id": "task-delete" },
            on: { confirmed: _vm.confirmed, cancelled: _vm.canceled },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }