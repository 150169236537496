var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("app-navbar", {
    attrs: {
      "logo-url": _vm.urlGenerator(_vm.logo),
      user: _vm.user.loggedInUser,
      "selected-language": _vm.userLanguage,
      "language-data": _vm.languageList,
      notificationData: _vm.notificationList,
      "profile-data": _vm.profileData,
      showIdentifier: _vm.showIdentifier,
      "all-notification-url": _vm.urlGenerator("/all-notifications"),
    },
    on: { clicked: _vm.readNotification },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }