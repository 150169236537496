var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("app-note", {
    staticClass: "mt-2",
    attrs: {
      "note-type": "note-warning",
      "show-title": false,
      notes: [_vm.$t("password_hint_note")],
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }