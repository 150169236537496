var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        "modal-id": _vm.userAndRoles.users.userModalId,
        title: _vm.modalTitle,
        preloader: _vm.preloader,
      },
      on: { submit: _vm.submit, "close-modal": _vm.closeModal },
    },
    [
      _c(
        "template",
        { slot: "body" },
        [
          _vm.preloader ? _c("app-overlay-loader") : _vm._e(),
          _vm._v(" "),
          _c(
            "form",
            {
              ref: "form",
              staticClass: "mb-0",
              class: { "loading-opacity": _vm.preloader },
              attrs: { "data-url": `/update-user-name/${_vm.inputs.id}` },
            },
            [
              _c(
                "div",
                { staticClass: "form-group row align-items-center" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 mb-0",
                      attrs: { for: "inputs_name" },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("first_name")) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("app-input", {
                    staticClass: "col-sm-9",
                    attrs: {
                      id: "inputs_name",
                      type: "text",
                      placeholder: _vm.$t("enter_first_name"),
                      required: true,
                    },
                    model: {
                      value: _vm.inputs.first_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "first_name", $$v)
                      },
                      expression: "inputs.first_name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group row align-items-center" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 mb-0",
                      attrs: { for: "inputs_email" },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("last_name")) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("app-input", {
                    staticClass: "col-sm-9",
                    attrs: {
                      id: "inputs_email",
                      type: "text",
                      placeholder: _vm.$t("enter_last_name"),
                      required: true,
                    },
                    model: {
                      value: _vm.inputs.last_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "last_name", $$v)
                      },
                      expression: "inputs.last_name",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }