var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "content-wrapper" },
      [
        _c(
          "div",
          { staticClass: "d-flex align-items-center justify-content-between" },
          [
            _c("app-breadcrumb", {
              attrs: { "page-title": _vm.$t("invoice_view") },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "mb-4" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary btn-with-shadow mr-1",
                  attrs: { type: "button" },
                  on: { click: _vm.downloadInvoice },
                },
                [
                  _c("app-icon", {
                    staticClass: "size-20 mr-2",
                    attrs: { name: "download" },
                  }),
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("action_invoice_download")) +
                      "\n        "
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-success btn-with-shadow",
                  attrs: { type: "button" },
                  on: { click: _vm.openSendInvoiceModal },
                },
                [
                  _c("app-icon", {
                    staticClass: "size-20 mr-2",
                    attrs: { name: "send" },
                  }),
                  _vm._v(
                    "\n          " + _vm._s(_vm.$t("send")) + "\n        "
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row justify-content-center" },
          [
            _vm.dataLoaded
              ? _c(
                  "div",
                  { staticClass: "invoice_preview primary-card-color" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "cus-invoice_container",
                        attrs: { id: "print-invoice" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "cus-invoice_container__item cus-px-5 cus-mt-5",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "cus-w-25 cus-f-left cus-p-2" },
                              [
                                _c("div", [
                                  _c("img", {
                                    staticClass: "cus-logo",
                                    attrs: { src: _vm.logo, alt: "NF" },
                                  }),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "cus-w-50 cus-f-right cus-text-right",
                              },
                              [
                                _c(
                                  "h1",
                                  { staticClass: "cus-text-capital cus-bold" },
                                  [_vm._v("Invoice")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "table",
                                  {
                                    staticClass:
                                      "cus-f-right cus-w-75 cus-font-xm cus-mt-3",
                                  },
                                  [
                                    _c("tr", [
                                      _c("td", { staticClass: "cus-bold" }, [
                                        _vm._v(_vm._s(_vm.$t("invoice_no"))),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [_vm._v(":")]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { staticClass: "cus-text-right" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formData.is_from_estimate
                                                ? "est-"
                                                : ""
                                            ) +
                                              _vm._s(
                                                _vm.formData.invoice_number
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("td", { staticClass: "cus-bold" }, [
                                        _vm._v(_vm._s(_vm.$t("date"))),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [_vm._v(":")]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { staticClass: "cus-text-right" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatDateToLocal(
                                                _vm.formData.date
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("td", { staticClass: "cus-bold" }, [
                                        _vm._v(_vm._s(_vm.$t("due_date"))),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [_vm._v(":")]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { staticClass: "cus-text-right" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatDateToLocal(
                                                _vm.formData.due_date
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("td", { staticClass: "cus-bold" }, [
                                        _vm._v(_vm._s(_vm.$t("tax_no"))),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [_vm._v(":")]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { staticClass: "cus-text-right" },
                                        [_vm._v("#" + _vm._s(44))]
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "cus-f-clear" }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "cus-f-clear" }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "cus-invoice_container__item cus-p-5",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "cus-w-50 cus-f-left cus-font-xm",
                              },
                              [
                                _vm.formData.created_by
                                  ? _c("p", { staticClass: "cus-bold" }, [
                                      _vm._v(_vm._s(_vm.$t("from"))),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    _vm._s(_vm.formData.created_by.full_name)
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm.formData.created_by.profile
                                  ? [
                                      _vm.formData.created_by.profile.contact
                                        ? _c("small", [
                                            _vm._v(
                                              _vm._s(_vm.$t("contact")) +
                                                ":\n                  " +
                                                _vm._s(
                                                  _vm.formData.created_by
                                                    .profile.contact
                                                )
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(" "),
                                      _vm.formData.created_by.profile.address
                                        ? _c("small", [
                                            _vm._v(
                                              _vm._s(_vm.$t("address")) +
                                                ":\n                  " +
                                                _vm._s(
                                                  _vm.formData.created_by
                                                    .profile.address
                                                )
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "cus-w-50 cus-f-right cus-font-xm",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "cus-w-75 cus-f-right" },
                                  [
                                    _vm.formData.client
                                      ? _c("p", { staticClass: "cus-bold" }, [
                                          _vm._v(_vm._s(_vm.$t("to"))),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(_vm.formData.client.full_name)
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _vm.formData.client.profile
                                      ? [
                                          _vm.formData.client.profile.contact
                                            ? _c("small", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("contact")) +
                                                    ":\n                    " +
                                                    _vm._s(
                                                      _vm.formData.client
                                                        .profile.contact
                                                    )
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("br"),
                                          _vm._v(" "),
                                          _vm.formData.client.profile.address
                                            ? _c("small", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("address")) +
                                                    ":\n                    " +
                                                    _vm._s(
                                                      _vm.formData.client
                                                        .profile.address
                                                    )
                                                ),
                                              ])
                                            : _vm._e(),
                                          _c("br"),
                                          _vm._v(" "),
                                          _vm.formData.client.profile.address
                                            ? _c("small", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("vat")) +
                                                    ":\n                    " +
                                                    _vm._s(
                                                      _vm.formData.client
                                                        .profile.vat_number
                                                    )
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "cus-f-clear" }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "cus-f-clear" }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "cus-invoice_container__item cus-px-5",
                          },
                          [
                            _c(
                              "table",
                              {
                                staticClass:
                                  "cus-w-100 cus-font-xm cus-table-strip",
                                attrs: {
                                  border: "0",
                                  cellspacing: "0",
                                  cellpadding: "0",
                                },
                              },
                              [
                                _c("thead", [
                                  _c(
                                    "tr",
                                    {
                                      staticClass: "cus-bg-dark cus-text-light",
                                    },
                                    [
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "cus-w-45 cus-p-1 cus-text-left",
                                        },
                                        [_vm._v(_vm._s(_vm.$t("product")))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "cus-w-10 cus-p-1 cus-text-right",
                                        },
                                        [_vm._v(_vm._s(_vm.$t("quantity")))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "cus-w-15 cus-p-1 cus-text-right",
                                        },
                                        [_vm._v(_vm._s(_vm.$t("unit_price")))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "cus-w-15 cus-p-1 cus-text-right",
                                        },
                                        [_vm._v(_vm._s(_vm.$t("tax")))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "cus-w-15 cus-p-1 cus-text-right",
                                        },
                                        [_vm._v(_vm._s(_vm.$t("total")))]
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "tbody",
                                  [
                                    _vm._l(
                                      _vm.formData.invoice_details,
                                      function (invoiceProduct, index) {
                                        return _c(
                                          "tr",
                                          { key: `invoice-item-${index}` },
                                          [
                                            _c(
                                              "td",
                                              { staticClass: "cus-p-1" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    invoiceProduct.product.name
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "cus-p-1 cus-text-right",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    invoiceProduct.quantity
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "cus-p-1 cus-text-right",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.numberWithCurrencySymbol(
                                                      invoiceProduct.price
                                                    )
                                                  ) + "\n                "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "cus-p-1 cus-text-right",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      invoiceProduct.tax
                                                        ? invoiceProduct.tax
                                                            .value + "%"
                                                        : "N/A"
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "cus-p-1 cus-text-right",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.numberWithCurrencySymbol(
                                                        _vm.calculateProductTax(
                                                          invoiceProduct.tax
                                                            ? invoiceProduct.tax
                                                                .value
                                                            : 0,
                                                          invoiceProduct.quantity *
                                                            invoiceProduct.price
                                                        ) +
                                                          invoiceProduct.quantity *
                                                            invoiceProduct.price
                                                      )
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    _vm._v(" "),
                                    _vm._m(0),
                                    _vm._v(" "),
                                    _c(
                                      "tr",
                                      { staticClass: "cus-bg-transparent" },
                                      [
                                        _c("td", { attrs: { colspan: "2" } }),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "cus-bold cus-p-1",
                                            attrs: { colspan: "2" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("sub_total")) + " :"
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "cus-text-right cus-p-1",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.numberWithCurrencySymbol(
                                                  _vm.formData.sub_total
                                                )
                                              ) + "\n                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "tr",
                                      { staticClass: "cus-bg-transparent" },
                                      [
                                        _c("td", { attrs: { colspan: "2" } }),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "cus-bold p-1",
                                            attrs: { colspan: "2" },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("tax")) + " :")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "cus-text-right cus-p-1",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.numberWithCurrencySymbol(
                                                  _vm.totalTax
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "tr",
                                      { staticClass: "cus-bg-transparent" },
                                      [
                                        _c("td", { attrs: { colspan: "2" } }),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "cus-bold cus-p-1",
                                            attrs: { colspan: "2" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("discount")) +
                                                " :\n                  "
                                            ),
                                            _vm.formData.discount_type ===
                                            "percentage"
                                              ? [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.formData.discount
                                                      ) +
                                                      " %\n                  "
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "cus-text-right cus-p-1",
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.numberWithCurrencySymbol(
                                                    _vm.formData.discount_amount
                                                  )
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "tr",
                                      { staticClass: "cus-bg-transparent" },
                                      [
                                        _c("td", { attrs: { colspan: "2" } }),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "cus-bold p-1",
                                            attrs: { colspan: "2" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("total")) + " :"
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "cus-text-right cus-p-1",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.numberWithCurrencySymbol(
                                                  _vm.formData.total
                                                )
                                              ) + "\n                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "tr",
                                      { staticClass: "cus-bg-transparent" },
                                      [
                                        _c("td", { attrs: { colspan: "2" } }),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "cus-bold p-1",
                                            attrs: { colspan: "2" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("paid")) + " :"
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "cus-text-right p-1",
                                            attrs: { colspan: "2" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.numberWithCurrencySymbol(
                                                    _vm.formData.received_amount
                                                  )
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm._m(1),
                                    _vm._v(" "),
                                    _c(
                                      "tr",
                                      {
                                        staticClass:
                                          "cus-bg-transparent cus-bold",
                                      },
                                      [
                                        _c("td", { attrs: { colspan: "2" } }),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "cus-p-1",
                                            attrs: { colspan: "2" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("due_amount")) +
                                                " :"
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "cus-text-right cus-p-1",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.numberWithCurrencySymbol(
                                                  _vm.formData.due_amount
                                                )
                                              ) + "\n                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "cus-invoice_container__item cus-p-5 cus-font-xm",
                          },
                          [
                            _c(
                              "div",
                              [
                                _vm.formData.notes
                                  ? [
                                      _c("div", { staticClass: "cus-bold" }, [
                                        _vm._v(_vm._s(_vm.$t("notes"))),
                                      ]),
                                      _vm._v(" "),
                                      _c("p", {
                                        domProps: {
                                          innerHTML: _vm._s(_vm.formData.notes),
                                        },
                                      }),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.formData.terms
                                  ? [
                                      _c(
                                        "div",
                                        { staticClass: "cus-bold cus-mt-3" },
                                        [_vm._v(_vm._s(_vm.$t("terms")))]
                                      ),
                                      _vm._v(" "),
                                      _c("p", {
                                        domProps: {
                                          innerHTML: _vm._s(_vm.formData.terms),
                                        },
                                      }),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                )
              : _c("app-overlay-loader"),
          ],
          1
        ),
        _vm._v(" "),
        _vm.isSendInvoiceModalActive
          ? _c("send-invoice-modal", {
              attrs: {
                email: _vm.formData.client.email,
                "invoice-information": _vm.formData,
              },
              on: { closeModal: _vm.closeSendInvoiceModal },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "cus-bg-transparent" }, [
      _c("td", { attrs: { colspan: "5" } }, [
        _c("div", { staticClass: "cus-hr cus-mt-2" }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "cus-bg-transparent" }, [
      _c("td", { staticClass: "cus-bg-transparent", attrs: { colspan: "2" } }),
      _vm._v(" "),
      _c("td", { staticClass: "cus-bg-transparent", attrs: { colspan: "3" } }, [
        _c("div", { staticClass: "cus-hr cus-mt-2" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }