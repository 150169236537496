var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "d-inline-block align-items-center d-flex position-relative",
      class: _vm.grid
        ? "preview preview--grid"
        : _vm.index === 0
        ? "preview"
        : "preview preview--sm",
      attrs: {
        href: _vm.item,
        "data-pswp-width": _vm.w,
        "data-pswp-height": _vm.h,
        target: "_blank",
        rel: "noreferrer",
      },
    },
    [
      _vm.type === "image"
        ? _c("img", {
            staticStyle: {
              "max-width": "100%",
              width: "100%",
              height: "100%",
              "max-height": "100%",
              "object-fit": "cover",
            },
            attrs: { src: _vm.item, alt: "" },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.type === "video"
        ? _c("video", { attrs: { height: "100%" } }, [
            _c("source", { attrs: { src: _vm.item, type: "video/mp4" } }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }