var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card card-with-shadow border-0" },
    [
      _c("div", { staticClass: "card-header p-primary bg-transparent" }, [
        _c("h5", { staticClass: "card-title m-0" }, [
          _vm._v(_vm._s(_vm.$t("range_validation"))),
        ]),
      ]),
      _vm._v(" "),
      _vm.preloader ? _c("app-overlay-loader") : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c(
          "form",
          {
            ref: "form",
            staticClass: "mb-0",
            class: { "loading-opacity": _vm.preloader },
            attrs: { "data-url": "/form/range-validation" },
          },
          [
            _c("div", { staticClass: "form-group row align-items-center" }, [
              _c("label", { staticClass: "col-md-2 mb-md-0" }, [
                _vm._v(_vm._s(_vm.$t("min_length"))),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-8" },
                [
                  _c("app-input", {
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t("min_chars"),
                      "min-length": 6,
                      required: true,
                    },
                    model: {
                      value: _vm.inputs.min_range,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "min_range", $$v)
                      },
                      expression: "inputs.min_range",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group row align-items-center" }, [
              _c("label", { staticClass: "col-md-2 mb-md-0" }, [
                _vm._v(_vm._s(_vm.$t("max_length"))),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-8" },
                [
                  _c("app-input", {
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t("max_chars"),
                      "max-length": 6,
                      required: true,
                    },
                    model: {
                      value: _vm.inputs.max_range,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "max_range", $$v)
                      },
                      expression: "inputs.max_range",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group row align-items-center" }, [
              _c("label", { staticClass: "col-md-2 mb-md-0" }, [
                _vm._v(_vm._s(_vm.$t("range_length"))),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-8" },
                [
                  _c("app-input", {
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t("range_length_placeholder"),
                      "min-length": 5,
                      "max-length": 10,
                      required: true,
                    },
                    model: {
                      value: _vm.inputs.length,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "length", $$v)
                      },
                      expression: "inputs.length",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group row align-items-center" }, [
              _c("label", { staticClass: "col-md-2 mb-md-0" }, [
                _vm._v(_vm._s(_vm.$t("alphanumeric"))),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-8" },
                [
                  _c("app-input", {
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t("must_be_alphanumaric"),
                      alphanumeric: true,
                      required: true,
                    },
                    model: {
                      value: _vm.inputs.alphanumaric,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "alphanumaric", $$v)
                      },
                      expression: "inputs.alphanumaric",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mt-5 action-buttons" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary mr-2",
                  attrs: { type: "submit" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("submit")) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  attrs: { type: "submit" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.clear.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("clear")) +
                      "\n                "
                  ),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }