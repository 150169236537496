var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "single-filter search-filter-dropdown" }, [
    _c(
      "div",
      {
        staticClass: "dropdown dropdown-with-animation",
        class: { disabled: _vm.data.disabled },
      },
      [
        _c(
          "div",
          {
            attrs: {
              id: _vm.inputFieldId,
              "data-toggle": "dropdown",
              "aria-haspopup": "true",
              "aria-expanded": "false",
            },
          },
          [
            _c("input", {
              staticClass: "form-control cursor-pointer",
              attrs: {
                type: "text",
                placeholder: _vm.data.placeholder,
                disabled: _vm.data.disabled,
              },
              domProps: { value: _vm.showValue },
              on: { click: _vm.startNavigation },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "dropdown-menu py-0 my-1",
            class: _vm.data.listClass,
            attrs: { "aria-labelledby": _vm.inputFieldId },
          },
          [
            _c("div", { staticClass: "form-group form-group-with-search" }, [
              _c(
                "span",
                { staticClass: "form-control-feedback" },
                [_c("app-icon", { attrs: { name: "search" } })],
                1
              ),
              _vm._v(" "),
              _c("input", {
                ref: "searchInput",
                class: "form-control " + _vm.data.listItemInputClass,
                attrs: {
                  type: "text",
                  placeholder: _vm.$t("search"),
                  autofocus: _vm.startNavigation,
                },
                domProps: { value: _vm.searchValue },
                on: {
                  input: function ($event) {
                    return _vm.getSearchValue($event)
                  },
                  keydown: [
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "down", 40, $event.key, [
                          "Down",
                          "ArrowDown",
                        ])
                      )
                        return null
                      return _vm.navigateDown.apply(null, arguments)
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "up", 38, $event.key, [
                          "Up",
                          "ArrowUp",
                        ])
                      )
                        return null
                      return _vm.navigateUp.apply(null, arguments)
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      $event.preventDefault()
                      return _vm.enterSelectedValue.apply(null, arguments)
                    },
                  ],
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "dropdown-divider my-0" }),
            _vm._v(" "),
            _c(
              "div",
              {
                ref: "optionList",
                staticClass: "dropdown-search-result-wrapper custom-scrollbar",
              },
              [
                _vm._l(_vm.options, function (item, index) {
                  return _c(
                    "a",
                    {
                      key: `${_vm.inputFieldId}-${index}`,
                      staticClass: "dropdown-item",
                      class: {
                        active: index === _vm.activeIndex,
                        [_vm.data.listItemClass]: !_vm.isUndefined(
                          _vm.data.listItemClass
                        ),
                        selected: item.id === _vm.value,
                        disabled: item.disabled,
                      },
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.changeSelectedValue(item)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(item[_vm.data.listValueField]) +
                          "\n                    "
                      ),
                      _c(
                        "span",
                        { staticClass: "check-sign float-right" },
                        [
                          _c("app-icon", {
                            staticClass: "menu-icon",
                            attrs: { name: "check" },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                _vm._v(" "),
                !_vm.options.length
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "text-center text-muted text-size-13 py-primary",
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.hintText) +
                            "\n                "
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _vm.searchValue === "" && _vm.data.list.length > _vm.loadedIndex
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-light btn-sm btn-block",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.loadMoreTags($event)
                      },
                    },
                  },
                  [
                    _vm.moreTagLoading
                      ? _c("span", [
                          _c("span", {
                            staticClass:
                              "spinner-border spinner-border-sm mr-2",
                            attrs: { role: "status", "aria-hidden": "true" },
                          }),
                          _vm._v(
                            "\n                        " +
                              _vm._s(`${_vm.$t("loading")}...`) +
                              "\n                    "
                          ),
                        ])
                      : _c("span", [_vm._v(_vm._s(_vm.$t("load_more")))]),
                  ]
                )
              : _vm._e(),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }