var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
    [
      _c("h5", { staticClass: "mb-3" }, [
        _vm._v(_vm._s(_vm.$t("tab_filter")) + " "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card card-with-shadow border-0 bg-transparent" },
        [
          _c(
            "div",
            {
              staticClass:
                "card-header d-flex align-items-center p-primary primary-card-color",
            },
            [
              _c("h5", { staticClass: "card-title d-inline-block mb-0" }, [
                _vm._v(_vm._s(_vm.$t("profile_view"))),
              ]),
              _vm._v(" "),
              _c("app-search", { on: { input: _vm.getSearchValue } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "p-primary d-flex align-items-center primary-card-color border-bottom",
            },
            [
              _c(
                "ul",
                { staticClass: "nav tab-filter-menu justify-content-flex-end" },
                _vm._l(_vm.userFilterOptions, function (item, index) {
                  return _c("li", { key: index, staticClass: "nav-item" }, [
                    _c(
                      "a",
                      {
                        staticClass: "nav-link font-size-default py-0",
                        class: [
                          _vm.filteredData.status == item.id
                            ? "active"
                            : index === 0 && _vm.filteredData.status === ""
                            ? "active"
                            : "",
                        ],
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.getFilterValue(item.id)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(item.value) +
                            "\n                    "
                        ),
                      ]
                    ),
                  ])
                }),
                0
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "card-body p-0" },
            [
              _c("app-table", {
                attrs: {
                  id: _vm.tableId,
                  options: _vm.options,
                  "filtered-data": _vm.filteredData,
                  search: _vm.search,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }