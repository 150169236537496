var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("app-breadcrumb", {
        attrs: {
          "page-title": _vm.$t("text_editor"),
          directory: _vm.$t("forms_and_fields"),
          icon: "sidebar",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card card-with-shadow border-0" },
        [
          _c("div", { staticClass: "card-header p-primary bg-transparent" }, [
            _c("h5", { staticClass: "card-title m-0" }, [
              _vm._v(_vm._s(_vm.$t("text_editor"))),
            ]),
          ]),
          _vm._v(" "),
          _vm.preloader ? _c("app-overlay-loader") : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c(
              "form",
              {
                ref: "form",
                staticClass: "mb-0",
                class: { "loading-opacity": _vm.preloader },
                attrs: { "data-url": "/form/editor-input" },
              },
              [
                _c("div", { staticClass: "form-group row" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 h-fit-content",
                      attrs: { for: "inputs_message" },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.$t("subject")) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-sm-8" },
                    [
                      _c("app-input", {
                        attrs: {
                          type: "text",
                          required: true,
                          "min-length": 2,
                          "max-length": 20,
                          label: _vm.$t("message_placeholder"),
                        },
                        model: {
                          value: _vm.inputs.subject,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputs, "subject", $$v)
                          },
                          expression: "inputs.subject",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group row" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 h-fit-content",
                      attrs: { for: "inputs_editor" },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.$t("content")) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-sm-8" },
                    [
                      _c("app-input", {
                        attrs: {
                          type: "text-editor",
                          required: true,
                          id: "text-editor-id",
                          label: _vm.$t("message_placeholder"),
                        },
                        model: {
                          value: _vm.inputs.editor,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputs, "editor", $$v)
                          },
                          expression: "inputs.editor",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group text-center" },
                        _vm._l(_vm.all_tags, function (tag) {
                          return _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-sm btn-primary px-3 py-1 mr-2 mt-4",
                              attrs: {
                                type: "button",
                                "data-toggle": "tooltip",
                                "data-placement": "top",
                                title: tag.description,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.addTag(tag.tag)
                                },
                              },
                            },
                            [_vm._v(_vm._s(tag.tag))]
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mt-5 action-buttons" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary mr-2",
                      attrs: { type: "submit" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.submit.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.$t("submit")) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "submit" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.clear.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.$t("clear")) +
                          "\n                    "
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }