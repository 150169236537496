var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "root", staticClass: "card rounded-lg position-relative" },
    [
      _c("div", { staticClass: "card-header bg-transparent p-3" }, [
        _c(
          "div",
          { staticClass: "d-flex justify-content-between align-items-center" },
          [
            _c("div", { staticClass: "d-flex align-items-center gap-x-1" }, [
              _c("img", {
                staticClass: "rounded-circle",
                attrs: {
                  width: "40",
                  height: "40",
                  src:
                    _vm.post.user.profile_picture?.full_url ||
                    _vm.urlGenerator("/images/avatar-demo.jpg"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "text-left" }, [
                _c(
                  "a",
                  {
                    attrs: {
                      href: _vm.urlGenerator("social-profile/"),
                      target: "_blank",
                    },
                  },
                  [_vm._v(_vm._s(_vm.post.user.full_name))]
                ),
                _vm._v(" "),
                _c("div", [
                  _c("small", { staticClass: "text-muted" }, [
                    _vm._v(
                      _vm._s(_vm.dateTimeToLocalWithFormat(_vm.post.created_at))
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "div",
                { staticClass: "dropdown options-dropdown d-inline-block" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn-option btn",
                      attrs: {
                        type: "button",
                        "data-toggle": "dropdown",
                        title: "Actions",
                        "aria-expanded": "false",
                      },
                    },
                    [_c("app-icon", { attrs: { name: "more-vertical" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "dropdown-menu py-2 mt-1",
                      class: _vm.isRtl
                        ? "dropdown-menu-left"
                        : "dropdown-menu-right",
                    },
                    [
                      _vm.post.user.id !== _vm.user.id
                        ? _c("app-load-more", {
                            attrs: {
                              preloader: _vm.preloader,
                              label: _vm.$t("report"),
                              type: "submit",
                              "class-name":
                                "dropdown-item px-4 text-primary py-2",
                            },
                            on: { submit: _vm.report },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.post.user.id === _vm.user.id
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "dropdown-item px-4 text-primary py-2",
                              on: { click: _vm.edit },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("edit")) +
                                  "\n                        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.post.user.id === _vm.user.id
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "dropdown-item px-4 text-danger py-2",
                              on: {
                                click: function ($event) {
                                  _vm.wannaDel = true
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("delete")) +
                                  "\n                        "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card-body p-3" }, [
        _c("div", {
          staticClass: "text-left",
          domProps: { innerHTML: _vm._s(_vm.getTextWithHtml(_vm.post.body)) },
        }),
        _vm._v(" "),
        _vm.post.images.length ? _c("div", { staticClass: "mt-4" }) : _vm._e(),
        _vm._v(" "),
        _vm.post.documents.length
          ? _c("div", { staticClass: "mt-4" })
          : _vm._e(),
        _vm._v(" "),
        _vm.post.poll
          ? _c("div", { staticClass: "mt-3" }, [
              !!_vm.post.poll.multiple_answerable
                ? _c("form", [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-column gap-y-1" },
                      _vm._l(_vm.post.poll.options, function (option, index) {
                        return _c(
                          "div",
                          {
                            key: `poll-option-${_vm.post.poll.id}-${index}`,
                            staticClass: "d-flex align-items-center gap-x-2",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "d-flex form-control",
                                staticStyle: { padding: "0px !important" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "bg-light p-2 d-flex justify-content-center align-items-center",
                                  },
                                  [
                                    _c("input", {
                                      staticStyle: { transform: "scale(1.3)" },
                                      attrs: {
                                        type: "checkbox",
                                        name: _vm.post.poll.id,
                                      },
                                      domProps: {
                                        value: option.body,
                                        checked: option.votes.some(
                                          (i) => i.poll_answer_id === option.id
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.submitPoll(
                                            option.id,
                                            $event.target.checked
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "w-100 position-relative",
                                    staticStyle: { "min-height": "30px" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "position-absolute",
                                        staticStyle: {
                                          top: "0",
                                          bottom: "0",
                                          left: "0",
                                          right: "0",
                                        },
                                      },
                                      [
                                        _c("div", {
                                          staticClass: "h-100 bg-primary",
                                          staticStyle: {
                                            opacity: "10%",
                                            transition: ".3s ease-in-out",
                                          },
                                          style: {
                                            width: `${_vm.votePercentage(
                                              option.votes.length
                                            )}%`,
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-between align-items-center h-100 px-3",
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "m-0",
                                            staticStyle: { "z-index": "10" },
                                          },
                                          [_vm._v(_vm._s(option.body))]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticStyle: { "z-index": "10" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.votePercentage(
                                                  option.votes.length
                                                )
                                              ) + "%"
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ])
                : _c("form", [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-column gap-y-1" },
                      _vm._l(_vm.post.poll.options, function (option, index) {
                        return _c(
                          "div",
                          {
                            key: `poll-option-${_vm.post.poll.id}-${index}`,
                            staticClass: "d-flex align-items-center gap-x-2",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "d-flex form-control",
                                staticStyle: { padding: "0px !important" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "bg-light p-2 d-flex justify-content-center align-items-center",
                                  },
                                  [
                                    _c("input", {
                                      staticStyle: { transform: "scale(1.3)" },
                                      attrs: {
                                        type: "radio",
                                        name: _vm.post.id,
                                      },
                                      domProps: {
                                        value: option.body,
                                        checked: option.votes.some(
                                          (i) => i.poll_answer_id === option.id
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.submitPoll(option.id, 1)
                                        },
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "w-100 position-relative",
                                    staticStyle: { "min-height": "30px" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "position-absolute",
                                        staticStyle: {
                                          top: "0",
                                          bottom: "0",
                                          left: "0",
                                          right: "0",
                                        },
                                      },
                                      [
                                        _c("div", {
                                          staticClass: "h-100 bg-primary",
                                          staticStyle: {
                                            opacity: "10%",
                                            transition: ".3s ease-in-out",
                                          },
                                          style: {
                                            width: `${_vm.votePercentage(
                                              option.votes.length
                                            )}%`,
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-between align-items-center h-100 px-3",
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "m-0",
                                            staticStyle: { "z-index": "10" },
                                          },
                                          [_vm._v(_vm._s(option.body))]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticStyle: { "z-index": "10" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.votePercentage(
                                                  option.votes.length
                                                )
                                              ) + "%"
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
              _vm._v(" "),
              _c("div", { staticClass: "mt-2" }, [
                !!_vm.post.poll.is_addable && !_vm.showAddOption
                  ? _c("div", [
                      _c(
                        "button",
                        {
                          staticClass: "btn text-primary px-0 btn-sm",
                          staticStyle: { "box-shadow": "none !important" },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.showAddOption = true
                            },
                          },
                        },
                        [
                          _c("app-icon", { attrs: { name: "plus", size: 16 } }),
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$t("add_option")) +
                              "\n                    "
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !!_vm.post.poll.is_addable && _vm.showAddOption
                  ? _c("div", { staticClass: "d-flex gap-x-1" }, [
                      _c("div", { staticClass: "flex-fill" }, [
                        _c("input", {
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t("enter_to_create"),
                          },
                          on: {
                            keypress: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.addOption($event.target.value)
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn p-0 btn-clear text-danger",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.showAddOption = false
                            },
                          },
                        },
                        [_c("app-icon", { attrs: { name: "x", size: 16 } })],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card-footer p-0 bg-transparent" }, [
        _c("div", { staticClass: "px-3 py-2 border-bottom" }, [
          _c(
            "div",
            {
              staticClass: "d-flex align-items-center justify-content-between",
            },
            [
              _c("div", [
                _c(
                  "div",
                  { staticClass: "d-flex align-items-center gap-x-1" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "reaction rounded-circle",
                        class: _vm.reacted ? "text-primary" : "",
                        staticStyle: { width: "30px", height: "30px" },
                        on: { click: _vm.reaction },
                      },
                      [
                        _c("app-icon", {
                          attrs: { size: 16, name: "thumbs-up" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", [_vm._v(_vm._s(_vm.reactions_count))]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "reaction rounded-pill",
                    staticStyle: { padding: "3px 15px" },
                    on: { click: _vm.commentExpand },
                  },
                  [
                    _c("small", [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.comments_count) +
                          "\n                            " +
                          _vm._s(_vm.$t("comments")) +
                          "\n                        "
                      ),
                    ]),
                  ]
                ),
              ]),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }