var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "position-relative min-height-400" },
      [
        _vm.preloader ? _c("app-overlay-loader") : _vm._e(),
        _vm._v(" "),
        _vm.cards.length > 0
          ? _c(
              "div",
              {
                staticClass: "row",
                class: { "loading-opacity": _vm.preloader },
              },
              _vm._l(_vm.cards, function (card, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3",
                  },
                  [
                    _c("app-template-preview-card", {
                      attrs: {
                        "preview-image-key": _vm.properties.previewImageKey,
                        "preview-type": _vm.properties.previewType,
                        id: _vm.id,
                        card: card,
                        "default-content-key": _vm.properties.defaultContentKey,
                        "custom-content-key": _vm.properties.customContentKey,
                        "subject-key": _vm.properties.subjectKey,
                        "show-action": _vm.properties.showAction,
                        actions: _vm.properties.actions,
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.cards.length === 0 && !_vm.preloader
          ? _c(
              "div",
              {
                staticClass: "card card-with-search border-0 card-with-shadow",
              },
              [
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _c("app-empty-data-block", {
                      attrs: { message: _vm.$t("nothing_to_show_here") },
                    }),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _vm.totalRow > 0 && _vm.paginationCardLimit > 0 && _vm.cards.length > 0
      ? _c("div", { staticClass: "mt-primary" }, [
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _vm.totalRow > _vm.cardOffset
                ? _c("app-load-more", {
                    attrs: {
                      preloader: _vm.loadMorePreloader,
                      disabled: _vm.loadMoreDisabled,
                    },
                    on: { submit: _vm.loadMoreCard },
                  })
                : _vm._e(),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }