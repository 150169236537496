var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "p-3" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col d-flex justify-content-end" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary btn-with-shadow",
              attrs: { type: "button", "data-toggle": "modal" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.openModal()
                },
              },
            },
            [
              _vm._v(
                "\n        Add " + _vm._s(_vm.customTable.name) + "\n      "
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row pt-3" }, [
        _c(
          "div",
          { staticClass: "col" },
          [
            _c("app-table", {
              attrs: { id: _vm.tableId, options: _vm.options },
              on: { action: _vm.getAction, getRows: _vm.getSelectedRows },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.isAddEditModal
        ? _c("add-edit-modal", {
            attrs: {
              "selected-url": _vm.selectedUrl,
              "table-data": _vm.customTable,
              "table-id": _vm.tableId,
            },
            on: { "close-modal": _vm.closeModal },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.confirmationModalActive
        ? _c("app-confirmation-modal", {
            attrs: {
              "modal-id": "table-row-delete-modal",
              loading: _vm.loading,
            },
            on: { confirmed: _vm.confirmed, cancelled: _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }