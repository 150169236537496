var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.componentLoader
    ? _c(
        "div",
        { staticClass: "position-relative h-100" },
        [_c("app-overlay-loader")],
        1
      )
    : _c(
        "div",
        [
          _c(
            "draggable",
            _vm._b(
              {
                attrs: { tag: "div" },
                on: {
                  start: function ($event) {
                    return _vm.dragstart($event)
                  },
                  end: _vm.dragend,
                  change: _vm.change,
                },
                model: {
                  value: _vm.socialLinks,
                  callback: function ($$v) {
                    _vm.socialLinks = $$v
                  },
                  expression: "socialLinks",
                },
              },
              "draggable",
              _vm.dragOptions,
              false
            ),
            [
              !_vm.isActive
                ? _c("app-pre-loader")
                : _vm._l(_vm.socialLinks, function (socialLink, index) {
                    return _c(
                      "div",
                      {
                        key: "social-link" + index,
                        staticClass: "row py-half-primary",
                      },
                      [
                        _c("div", { staticClass: "col-4 col-lg-4" }, [
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c(
                                "div",
                                { staticClass: "cursor-grab mr-3" },
                                [
                                  _c("app-icon", {
                                    staticClass: "text-muted",
                                    attrs: { name: "align-justify" },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", [
                                _c(
                                  "div",
                                  {
                                    key: socialLink.icon,
                                    staticClass: "icon-box mr-2",
                                  },
                                  [
                                    _c("app-icon", {
                                      attrs: { name: socialLink.icon },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.$t(socialLink.name)) +
                                  "\n                "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-7 col-lg-7 mt-2 mt-lg-0" },
                          [
                            socialLink.link && !socialLink.edit
                              ? _c(
                                  "div",
                                  {
                                    key: "with-link-" + index,
                                    staticClass:
                                      "h-100 d-flex align-items-center justify-content-between",
                                  },
                                  [
                                    _c("p", { staticClass: "mb-0 mr-2" }, [
                                      _vm._v(_vm._s(socialLink.link)),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "btn-group btn-group-action",
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn",
                                            attrs: {
                                              type: "button",
                                              "data-toggle": "tooltip",
                                              "data-placement": "top",
                                              title: _vm.$t("edit"),
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.editToggle(index)
                                              },
                                            },
                                          },
                                          [
                                            _c("app-icon", {
                                              attrs: { name: "edit" },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn",
                                            attrs: {
                                              type: "button",
                                              "data-toggle": "tooltip",
                                              "data-placement": "top",
                                              title: _vm.$t("remove_link"),
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.removeSocialLink(
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("app-icon", {
                                              attrs: { name: "trash" },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    key: "without-link-" + index,
                                    staticClass: "h-100",
                                  },
                                  [
                                    socialLink.edit
                                      ? _c(
                                          "div",
                                          {
                                            key: "without-link-edit-" + index,
                                            staticClass:
                                              "d-flex align-items-center h-100",
                                          },
                                          [
                                            _c("app-input", {
                                              staticClass: "mr-2 flex-grow-1",
                                              attrs: {
                                                id: "social-link-" + index,
                                                type: "text",
                                              },
                                              model: {
                                                value: socialLink.link,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    socialLink,
                                                    "link",
                                                    $$v
                                                  )
                                                },
                                                expression: "socialLink.link",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-primary mr-2",
                                                attrs: { type: "submit" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.addSocialLink(
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                            " +
                                                    _vm._s(_vm.$t("save")) +
                                                    "\n                        "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass: "text-muted",
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.editToggle(index)
                                                  },
                                                },
                                              },
                                              [
                                                _c("app-icon", {
                                                  attrs: { name: "x" },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "div",
                                          {
                                            key: "without-link-show-" + index,
                                            staticClass:
                                              "d-flex align-items-center justify-content-end h-100",
                                          },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass: "btn btn-primary",
                                                attrs: { type: "submit" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.editToggle(index)
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                            " +
                                                    _vm._s(_vm.$t("add")) +
                                                    "\n                        "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                  ]
                                ),
                          ]
                        ),
                      ]
                    )
                  }),
            ],
            2
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }