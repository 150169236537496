var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dropdown-item" }, [
    _c("div", { staticClass: "row filter-action-button-wrapper" }, [
      _c("div", { staticClass: "col-12 d-flex justify-content-between" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-clear pl-sm-0",
            attrs: { type: "button", disabled: _vm.isClearDisabled },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.clear($event)
              },
            },
          },
          [
            _vm._v(
              "\n                " + _vm._s(_vm.$t("clear")) + "\n            "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "submit" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.apply.apply(null, arguments)
              },
            },
          },
          [
            _vm._v(
              "\n                " + _vm._s(_vm.$t("apply")) + "\n            "
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }