<template>
    <div>Inner Tab</div>
</template>

<script>
export default {
name: "InnerTab"
}
</script>

<style scoped>

</style>