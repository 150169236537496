var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    {
      staticClass: "custom-control d-inline border-switch mb-0 mr-3",
      class: { disabled: _vm.data.disabled },
    },
    [
      _c(
        "input",
        _vm._g(
          {
            staticClass: "border-switch-control-input",
            attrs: {
              type: "checkbox",
              name: _vm.name,
              id: _vm.inputFieldId,
              disabled: _vm.data.disabled,
            },
            domProps: { checked: _vm.value, value: _vm.value },
          },
          _vm.listeners
        )
      ),
      _vm._v(" "),
      _c("span", { staticClass: "border-switch-control-indicator" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }