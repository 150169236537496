var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("app-breadcrumb", {
        attrs: {
          "page-title": _vm.$t("buttons"),
          directory: _vm.$t("ui_elements"),
          icon: "trello",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "card card-with-shadow border-0" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header d-flex justify-content-between bg-transparent p-primary align-items-center",
          },
          [
            _c("h5", { staticClass: "card-title mb-0" }, [
              _vm._v(_vm._s(_vm.$t("solid_buttons_default"))),
            ]),
            _vm._v(" "),
            _c("copy-button", {
              attrs: {
                id: "solid-button-default",
                value: _vm.solidDefaultValue,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "mb-primary" }, [
            _c("h6", [_vm._v(_vm._s(_vm.$t("enabled")))]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ui-elements-container" },
              [
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("primary"),
                    "class-name": "btn-primary mr-2 mb-2 mb-sm-0",
                    "is-disabled": false,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("secondary"),
                    "class-name": "btn-secondary mr-2 mb-2 mb-sm-0",
                    "is-disabled": false,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("success"),
                    "class-name": "btn-success mr-2 mb-2 mb-sm-0",
                    "is-disabled": false,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("danger"),
                    "class-name": "btn-danger mr-2 mb-2 mb-sm-0",
                    "is-disabled": false,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("warning"),
                    "class-name": "btn-warning mr-2 mb-2 mb-sm-0",
                    "is-disabled": false,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("info"),
                    "class-name": "btn-info mr-2 mb-2 mb-sm-0",
                    "is-disabled": false,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("dark"),
                    "class-name": "btn-dark mr-2 mb-2 mb-sm-0",
                    "is-disabled": false,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("light"),
                    "class-name": "btn-light mr-2 mb-2 mb-sm-0",
                    "is-disabled": false,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("link"),
                    "class-name": "btn-link mr-2 mb-2 mb-sm-0",
                    "is-disabled": false,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", {}, [
            _c("h6", [_vm._v(_vm._s(_vm.$t("disabled")))]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ui-elements-container" },
              [
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("primary"),
                    "class-name": "btn-primary mr-2 mb-2 mb-sm-0",
                    "is-disabled": true,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("secondary"),
                    "class-name": "btn-secondary mr-2 mb-2 mb-sm-0",
                    "is-disabled": true,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("success"),
                    "class-name": "btn-success mr-2 mb-2 mb-sm-0",
                    "is-disabled": true,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("danger"),
                    "class-name": "btn-danger mr-2 mb-2 mb-sm-0",
                    "is-disabled": true,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("warning"),
                    "class-name": "btn-warning mr-2 mb-2 mb-sm-0",
                    "is-disabled": true,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("info"),
                    "class-name": "btn-info mr-2 mb-2 mb-sm-0",
                    "is-disabled": true,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("dark"),
                    "class-name": "btn-dark mr-2 mb-2 mb-sm-0",
                    "is-disabled": true,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("light"),
                    "class-name": "btn-light mr-2 mb-2 mb-sm-0",
                    "is-disabled": true,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("link"),
                    "class-name": "btn-link mr-2 mb-2 mb-sm-0",
                    "is-disabled": true,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mt-4 card card-with-shadow border-0" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header d-flex justify-content-between bg-transparent p-primary align-items-center",
          },
          [
            _c("h5", { staticClass: "card-title mb-0" }, [
              _vm._v(_vm._s(_vm.$t("solid_buttons_rounded"))),
            ]),
            _vm._v(" "),
            _c("copy-button", {
              attrs: {
                id: "solid-button-rounded",
                value: _vm.solidRoundedValue,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "mb-primary" }, [
            _c("h6", [_vm._v(_vm._s(_vm.$t("enabled")))]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ui-elements-container" },
              [
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("primary"),
                    "class-name": "btn-primary rounded-pill mr-2 mb-2 mb-sm-0",
                    "is-disabled": false,
                  },
                  on: {
                    submit: function ($event) {
                      return _vm.btnSubmit(this)
                    },
                  },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("secondary"),
                    "class-name":
                      "btn-secondary rounded-pill mr-2 mb-2 mb-sm-0",
                    "is-disabled": false,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("success"),
                    "class-name": "btn-success rounded-pill mr-2 mb-2 mb-sm-0",
                    "is-disabled": false,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("danger"),
                    "class-name": "btn-danger rounded-pill mr-2 mb-2 mb-sm-0",
                    "is-disabled": false,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("warning"),
                    "class-name": "btn-warning rounded-pill mr-2 mb-2 mb-sm-0",
                    "is-disabled": false,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("info"),
                    "class-name": "btn-info rounded-pill mr-2 mb-2 mb-sm-0",
                    "is-disabled": false,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("dark"),
                    "class-name": "btn-dark rounded-pill mr-2 mb-2 mb-sm-0",
                    "is-disabled": false,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("light"),
                    "class-name": "btn-light rounded-pill mr-2 mb-2 mb-sm-0",
                    "is-disabled": false,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("link"),
                    "class-name": "btn-link rounded-pill mr-2 mb-2 mb-sm-0",
                    "is-disabled": false,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", {}, [
            _c("h6", [_vm._v(_vm._s(_vm.$t("disabled")))]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ui-elements-container" },
              [
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("primary"),
                    "class-name": "btn-primary rounded-pill mr-2 mb-2 mb-sm-0",
                    "is-disabled": true,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("secondary"),
                    "class-name":
                      "btn-secondary rounded-pill mr-2 mb-2 mb-sm-0",
                    "is-disabled": true,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("success"),
                    "class-name": "btn-success rounded-pill mr-2 mb-2 mb-sm-0",
                    "is-disabled": true,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("danger"),
                    "class-name": "btn-danger rounded-pill mr-2 mb-2 mb-sm-0",
                    "is-disabled": true,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("warning"),
                    "class-name": "btn-warning rounded-pill mr-2 mb-2 mb-sm-0",
                    "is-disabled": true,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("info"),
                    "class-name": "btn-info rounded-pill mr-2 mb-2 mb-sm-0",
                    "is-disabled": true,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("dark"),
                    "class-name": "btn-dark rounded-pill mr-2 mb-2 mb-sm-0",
                    "is-disabled": true,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("light"),
                    "class-name": "btn-light rounded-pill mr-2 mb-2 mb-sm-0",
                    "is-disabled": true,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("link"),
                    "class-name": "btn-link rounded-pill mr-2 mb-2 mb-sm-0",
                    "is-disabled": true,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mt-4 card card-with-shadow border-0" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header d-flex justify-content-between bg-transparent p-primary align-items-center",
          },
          [
            _c("h5", { staticClass: "card-title mb-0" }, [
              _vm._v(_vm._s(_vm.$t("outline_buttons_rectangular"))),
            ]),
            _vm._v(" "),
            _c("copy-button", {
              attrs: {
                id: "outline-rectangular",
                value: _vm.outlineRectangularValue,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "mb-primary" }, [
            _c("h6", [_vm._v(_vm._s(_vm.$t("enabled")))]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ui-elements-container" },
              [
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("primary"),
                    "class-name": "btn-outline-primary mr-2 mb-2 mb-sm-0",
                    "is-disabled": false,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("secondary"),
                    "class-name": "btn-outline-secondary mr-2 mb-2 mb-sm-0",
                    "is-disabled": false,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("success"),
                    "class-name": "btn-outline-success mr-2 mb-2 mb-sm-0",
                    "is-disabled": false,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("danger"),
                    "class-name": "btn-outline-danger mr-2 mb-2 mb-sm-0",
                    "is-disabled": false,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("warning"),
                    "class-name": "btn-outline-warning mr-2 mb-2 mb-sm-0",
                    "is-disabled": false,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("info"),
                    "class-name": "btn-outline-info mr-2 mb-2 mb-sm-0",
                    "is-disabled": false,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("dark"),
                    "class-name": "btn-outline-dark mr-2 mb-2 mb-sm-0",
                    "is-disabled": false,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("light"),
                    "class-name": "btn-outline-light mr-2 mb-2 mb-sm-0",
                    "is-disabled": false,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", {}, [
            _c("h6", [_vm._v(_vm._s(_vm.$t("disabled")))]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ui-elements-container" },
              [
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("primary"),
                    "class-name": "btn-outline-primary mr-2 mb-2 mb-sm-0",
                    "is-disabled": true,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("secondary"),
                    "class-name": "btn-outline-secondary mr-2 mb-2 mb-sm-0",
                    "is-disabled": true,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("success"),
                    "class-name": "btn-outline-success mr-2 mb-2 mb-sm-0",
                    "is-disabled": true,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("danger"),
                    "class-name": "btn-outline-danger mr-2 mb-2 mb-sm-0",
                    "is-disabled": true,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("warning"),
                    "class-name": "btn-outline-warning mr-2 mb-2 mb-sm-0",
                    "is-disabled": true,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("info"),
                    "class-name": "btn-outline-info mr-2 mb-2 mb-sm-0",
                    "is-disabled": true,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("dark"),
                    "class-name": "btn-outline-dark mr-2 mb-2 mb-sm-0",
                    "is-disabled": true,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("light"),
                    "class-name": "btn-outline-light mr-2 mb-2 mb-sm-0",
                    "is-disabled": true,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mt-4 card card-with-shadow border-0" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header d-flex justify-content-between bg-transparent p-primary align-items-center",
          },
          [
            _c("h5", { staticClass: "card-title mb-0" }, [
              _vm._v(_vm._s(_vm.$t("outline_buttons_rounded"))),
            ]),
            _vm._v(" "),
            _c("copy-button", {
              attrs: { id: "outline-rounded", value: _vm.outlineRoundedValue },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "mb-primary" }, [
            _c("h6", [_vm._v(_vm._s(_vm.$t("enabled")))]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ui-elements-container" },
              [
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("primary"),
                    "class-name":
                      "btn-outline-primary rounded-pill mr-2 mb-2 mb-sm-0",
                    "is-disabled": false,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("secondary"),
                    "class-name":
                      "btn-outline-secondary rounded-pill mr-2 mb-2 mb-sm-0",
                    "is-disabled": false,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("success"),
                    "class-name":
                      "btn-outline-success rounded-pill mr-2 mb-2 mb-sm-0",
                    "is-disabled": false,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("danger"),
                    "class-name":
                      "btn-outline-danger rounded-pill mr-2 mb-2 mb-sm-0",
                    "is-disabled": false,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("warning"),
                    "class-name":
                      "btn-outline-warning rounded-pill mr-2 mb-2 mb-sm-0",
                    "is-disabled": false,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("info"),
                    "class-name":
                      "btn-outline-info rounded-pill mr-2 mb-2 mb-sm-0",
                    "is-disabled": false,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("dark"),
                    "class-name":
                      "btn-outline-dark rounded-pill mr-2 mb-2 mb-sm-0",
                    "is-disabled": false,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("light"),
                    "class-name":
                      "btn-outline-light rounded-pill mr-2 mb-2 mb-sm-0",
                    "is-disabled": false,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", {}, [
            _c("h6", [_vm._v(_vm._s(_vm.$t("disabled")))]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ui-elements-container" },
              [
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("primary"),
                    "class-name":
                      "btn-outline-primary rounded-pill mr-2 mb-2 mb-sm-0",
                    "is-disabled": true,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("secondary"),
                    "class-name":
                      "btn-outline-secondary rounded-pill mr-2 mb-2 mb-sm-0",
                    "is-disabled": true,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("success"),
                    "class-name":
                      "btn-outline-success rounded-pill mr-2 mb-2 mb-sm-0",
                    "is-disabled": true,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("danger"),
                    "class-name":
                      "btn-outline-danger rounded-pill mr-2 mb-2 mb-sm-0",
                    "is-disabled": true,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("warning"),
                    "class-name":
                      "btn-outline-warning rounded-pill mr-2 mb-2 mb-sm-0",
                    "is-disabled": true,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("info"),
                    "class-name":
                      "btn-outline-info rounded-pill mr-2 mb-2 mb-sm-0",
                    "is-disabled": true,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("dark"),
                    "class-name":
                      "btn-outline-dark rounded-pill mr-2 mb-2 mb-sm-0",
                    "is-disabled": true,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
                _vm._v(" "),
                _c("app-button", {
                  attrs: {
                    label: _vm.$t("light"),
                    "class-name":
                      "btn-outline-light rounded-pill mr-2 mb-2 mb-sm-0",
                    "is-disabled": true,
                  },
                  on: { submit: _vm.btnSubmit },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mt-4 card card-with-shadow border-0" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header d-flex justify-content-between bg-transparent p-primary align-items-center",
          },
          [
            _c("h5", { staticClass: "card-title mb-0" }, [
              _vm._v(_vm._s(_vm.$t("block_buttons"))),
            ]),
            _vm._v(" "),
            _c("copy-button", {
              attrs: { id: "block-button", value: _vm.blockButtonValue },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card-body" },
          [
            _c("app-button", {
              attrs: {
                label: _vm.$t("block_button"),
                "class-name": "btn-block btn-primary",
                "is-disabled": false,
              },
              on: { submit: _vm.btnSubmit },
            }),
            _vm._v(" "),
            _c("app-button", {
              attrs: {
                label: _vm.$t("block_button_rounded"),
                "class-name": "btn-block btn-primary rounded-pill",
                "is-disabled": false,
              },
              on: { submit: _vm.btnSubmit },
            }),
            _vm._v(" "),
            _c("app-button", {
              attrs: {
                label: _vm.$t("block_button_outline_rectangular"),
                "class-name": "btn-block btn-outline-primary",
                "is-disabled": false,
              },
              on: { submit: _vm.btnSubmit },
            }),
            _vm._v(" "),
            _c("app-button", {
              attrs: {
                label: _vm.$t("block_button_outline_rounded"),
                "class-name": "btn-block btn-outline-primary rounded-pill",
                "is-disabled": false,
              },
              on: { submit: _vm.btnSubmit },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }