var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content-wrapper" }, [
    _c("div", { staticClass: "row mb-primary" }, [
      _c("div", { staticClass: "col" }, [
        _c(
          "div",
          { staticClass: "d-flex justify-content-between mb-3" },
          [
            _c("h5", [_vm._v(_vm._s(_vm.$t("app_pre_loader")))]),
            _vm._v(" "),
            _c("copy-button", {
              attrs: { id: "app_pre_loader", value: _vm.AppPreLoader },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "card border-0 card-with-shadow position-relative d-flex justify-content-center align-items-center",
            staticStyle: { "min-height": "100px" },
          },
          [_c("app-pre-loader")],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-primary" }, [
      _c("div", { staticClass: "col" }, [
        _c(
          "div",
          { staticClass: "d-flex justify-content-between mb-3" },
          [
            _c("h5", [_vm._v(_vm._s(_vm.$t("app_overlay_loader")))]),
            _vm._v(" "),
            _c("copy-button", {
              attrs: { id: "app-overlay-loader", value: _vm.AppOverlayLoader },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "card border-0 card-with-shadow position-relative",
            staticStyle: { "min-height": "100px" },
          },
          [_c("app-overlay-loader")],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-primary" }, [
      _c("div", { staticClass: "col" }, [
        _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "d-flex justify-content-between mb-3" },
              [
                _c("h5", [_vm._v(_vm._s(_vm.$t("app_skeleton_loade")))]),
                _vm._v(" "),
                _c("copy-button", {
                  attrs: {
                    id: "app-skeleton-loade",
                    value: _vm.AppSkeletonLoader,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("app-skeleton-loader", [
              _c(
                "div",
                {
                  staticClass: "card border-0 card-with-shadow",
                  staticStyle: { "min-height": "100px" },
                },
                [
                  _c("div", { staticClass: "card-body" }, [
                    _c(
                      "h1",
                      {
                        staticClass: "d-inlink-block rounded-pill",
                        staticStyle: { width: "100px" },
                      },
                      [_vm._v("Test")]
                    ),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "Lorem ipsum dolor sit amet consectetur adipisicing elit."
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "Lorem ipsum dolor sit amet consectetur adipisicing elit."
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex non quam dolorem omnis enim cum consequatur saepe sed asperiores nihil. Architecto provident ratione veniam facilis repudiandae optio blanditiis asperiores magnam!"
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }