var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "no-data-found-wrapper text-center p-primary" },
    [
      _c("img", {
        staticClass: "mb-primary",
        attrs: { src: _vm.img, alt: "" },
      }),
      _vm._v(" "),
      _c("p", { staticClass: "mb-0 text-center" }, [
        _vm._v(_vm._s(_vm.$t("nothing_to_show_here"))),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "mb-0 text-center text-secondary font-size-90" }, [
        _vm._v(_vm._s(_vm.message)),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "mb-0 text-center text-secondary font-size-90" }, [
        _vm._v(_vm._s(_vm.$t("thank_you"))),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }