var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "template" },
    [
      _c(
        "button",
        {
          staticClass:
            "d-flex template__item shadow btn btn-primary justify-content-center align-items-center",
          on: { click: _vm.addNew },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-column justify-content-center align-items-center",
            },
            [
              _c("app-icon", { staticClass: "my-2", attrs: { name: "plus" } }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "flex-fill d-flex justify-content-center align-items-center",
                },
                [_vm._v(_vm._s(_vm.$t("new_template")))]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm._l(_vm.list, function (template, index) {
        return _c(
          "div",
          {
            key: `template-${index}`,
            staticClass: "d-flex flex-column template__item shadow",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "flex-fill d-flex justify-content-center align-items-center",
              },
              [_vm._v(_vm._s(template[_vm.nameKey]))]
            ),
            _vm._v(" "),
            template.is_default
              ? _c(
                  "span",
                  {
                    staticClass:
                      "badge badge-pill badge-sm badge-light text-primary",
                    staticStyle: {
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("default")))]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", {}, [
              _c(
                "div",
                { staticClass: "d-flex justify-content-end border-top py-2" },
                [
                  _c("div", { staticClass: "btn-group" }, [
                    _c("button", {
                      staticClass: "btn dropdown-toggle",
                      attrs: {
                        type: "button",
                        "data-toggle": "dropdown",
                        "aria-expanded": "false",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "dropdown-menu dropdown-menu-right" },
                      _vm._l(_vm.actions, function (action, index) {
                        return _c(
                          "button",
                          {
                            key: `action-${index}`,
                            staticClass: "dropdown-item",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return action.method(template)
                              },
                            },
                          },
                          [_vm._v(_vm._s(action.title))]
                        )
                      }),
                      0
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }