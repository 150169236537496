var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "custom-modal shadow d-none" }, [
    _c(
      "form",
      {
        ref: "form",
        staticClass: "mb-0 min-height-100",
        class: { "loading-opacity": _vm.preloader },
        attrs: { "data-url": _vm.selectedUrl ? _vm.selectedUrl : "calendars" },
      },
      [
        _vm.preloader ? _c("app-overlay-loader") : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("app-input", {
              attrs: { placeholder: _vm.$t("enter_title"), required: true },
              model: {
                value: _vm.formData.title,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "title", $$v)
                },
                expression: "formData.title",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("app-input", {
              attrs: {
                placeholder: _vm.$t("enter_description"),
                type: "textarea",
              },
              model: {
                value: _vm.formData.description,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "description", $$v)
                },
                expression: "formData.description",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.selectedUrl
          ? _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("app-input", {
                  attrs: {
                    type: "single-checkbox",
                    "list-value-field": _vm.$t("remove"),
                  },
                  model: {
                    value: _vm.deletedCheckbox,
                    callback: function ($$v) {
                      _vm.deletedCheckbox = $$v
                    },
                    expression: "deletedCheckbox",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex justify-content-end event-modal-footer" },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary mr-2",
                attrs: { type: "button", "data-dismiss": "modal" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.closeAddEditModal.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("cancel")) +
                    "\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _vm.deletedCheckbox
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-danger",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.doDeleteEvent.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("remove")) +
                        "\n            "
                    ),
                  ]
                )
              : _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.submitEvents.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.selectedUrl ? _vm.$t("update") : _vm.$t("save")
                        ) +
                        "\n            "
                    ),
                  ]
                ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }