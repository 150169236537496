import { render, staticRenderFns } from "./UpdateModal.vue?vue&type=template&id=bc771364&"
import script from "./UpdateModal.vue?vue&type=script&lang=js&"
export * from "./UpdateModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("G:\\laragon\\www\\bestpris\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bc771364')) {
      api.createRecord('bc771364', component.options)
    } else {
      api.reload('bc771364', component.options)
    }
    module.hot.accept("./UpdateModal.vue?vue&type=template&id=bc771364&", function () {
      api.rerender('bc771364', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/app/Components/Views/Settings/Notification/Settings/UpdateModal.vue"
export default component.exports