var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      class: "btn btn-primary text-center " + _vm.btnClass,
      attrs: { type: _vm.type, disabled: _vm.loading },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.$emit("click")
        },
      },
    },
    [
      _c(
        "span",
        { staticClass: "w-100" },
        [
          _vm.loading
            ? _c("loader", { attrs: { "loader-color": "white-color" } })
            : _vm._e(),
          _vm._v(" "),
          !_vm.loading
            ? [_vm._v("\n            " + _vm._s(_vm.label) + "\n        ")]
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }