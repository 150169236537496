var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "card card-with-shadow border-0 h-100 employee-preview-card",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "card-body position-relative d-flex flex-column justify-content-between",
        },
        [
          _vm.showAction
            ? _c(
                "div",
                { staticClass: "dropdown options-dropdown position-absolute" },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn-option btn d-flex align-items-center justify-content-center",
                      attrs: { type: "button", "data-toggle": "dropdown" },
                    },
                    [_c("app-icon", { attrs: { name: "more-horizontal" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "dropdown-menu dropdown-menu-right py-2 mt-1",
                    },
                    _vm._l(_vm.actions, function (action, index) {
                      return _c(
                        "a",
                        {
                          key: index,
                          staticClass: "dropdown-item px-4 py-2",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.callAction(action)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(action.title) +
                              "\n                "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex flex-column align-items-center" },
            [
              _c("app-avatar", {
                attrs: {
                  title: _vm.profile.name,
                  "avatar-class": "avatars-w-60 mb-2 mx-auto d-inline-block",
                  img: _vm.getImageUrl(_vm.profile),
                },
              }),
              _vm._v(" "),
              _c(
                "h5",
                {
                  staticClass: "mb-1 mt-3",
                  staticStyle: { "font-size": "1.15rem" },
                },
                [_vm._v(_vm._s(_vm.profile.name))]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "mb-2 text-muted font-size-90" }, [
                _vm._v(_vm._s(_vm.profile.email)),
              ]),
              _vm._v(" "),
              _vm.profile.status
                ? _c("div", { staticClass: "my-3" }, [
                    _c(
                      "span",
                      {
                        class:
                          "badge badge-sm badge-pill badge-" +
                          _vm.statusClass(_vm.profile.status),
                      },
                      [_vm._v(_vm._s(_vm.profile.status))]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.profile.phone
                ? _c("p", { staticClass: "mb-2" }, [
                    _vm._v(_vm._s(_vm.profile.phone)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.profile.gender
                ? _c("p", { staticClass: "mb-2" }, [
                    _vm._v(_vm._s(_vm.$t(_vm.profile.gender))),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.profile.age
                ? _c("p", { staticClass: "mb-2" }, [
                    _vm._v(_vm._s(_vm.$t("age") + ": " + _vm.profile.age)),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }