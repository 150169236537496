var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card rounded-lg", staticStyle: { overflow: "hidden" } },
    [
      _c("div", { staticClass: "card-header" }, [
        _c(
          "div",
          { staticClass: "d-flex justify-content-between align-items-center" },
          [_c("div", [_vm._v(_vm._s(_vm.$t("notifications")))])]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card-body p-0" }, [
        _c(
          "div",
          {
            staticClass: "d-flex flex-column scroll-y custom-scrollbar",
            staticStyle: { "max-height": "380px" },
          },
          [
            _vm._l(_vm.notifications, function (item, index) {
              return _c(
                "div",
                {
                  key: `notification-${index}-${item.id}`,
                  staticClass: "py-2 px-3 notification d-flex gap-x-1",
                },
                [
                  _c("img", {
                    staticClass: "rounded-circle",
                    attrs: {
                      width: "24",
                      height: "24",
                      src:
                        item?.profile_picture?.full_url ||
                        _vm.urlGenerator("/images/avatar-demo.jpg"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-left" }, [
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v(_vm._s(item.data.message)),
                    ]),
                  ]),
                ]
              )
            }),
            _vm._v(" "),
            !_vm.notifications.length
              ? _c(
                  "div",
                  [_c("app-empty-data-block", { attrs: { message: "" } })],
                  1
                )
              : _vm._e(),
          ],
          2
        ),
        _vm._v(" "),
        !_vm.no_more
          ? _c(
              "button",
              {
                staticClass: "btn btn-link w-100",
                on: { click: _vm.getNotification },
              },
              [_vm._v(_vm._s(_vm.$t("more")))]
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }