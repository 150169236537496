var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card card-with-shadow border-0 mb-primary" },
    [
      _c("div", { staticClass: "card-header p-primary bg-transparent" }, [
        _c("h5", { staticClass: "card-title m-0" }, [
          _vm._v(_vm._s(_vm.$t("select_and_multi_select"))),
        ]),
      ]),
      _vm._v(" "),
      _vm.preloader ? _c("app-overlay-loader") : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c(
          "form",
          {
            ref: "form",
            staticClass: "mb-0",
            class: { "loading-opacity": _vm.preloader },
            attrs: { id: "form3", "data-url": "/form/multi-select" },
          },
          [
            _c(
              "div",
              { staticClass: "form-group row align-items-center" },
              [
                _c("label", { staticClass: "col-sm-2 mb-sm-0" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("default_select")) +
                      "\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("app-input", {
                  staticClass: "col-sm-8",
                  attrs: {
                    type: "select",
                    list: _vm.listForSelect,
                    placeholder: _vm.$t("readonly_input"),
                  },
                  model: {
                    value: _vm.inputs.select,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputs, "select", $$v)
                    },
                    expression: "inputs.select",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group form row" },
              [
                _c("label", { staticClass: "col-sm-2 mb-sm-0" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("multi_select")) +
                      "\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("app-input", {
                  staticClass: "col-sm-8",
                  attrs: {
                    type: "multi-select",
                    list: _vm.list,
                    isAnimatedDropdown: true,
                  },
                  model: {
                    value: _vm.multiSelect,
                    callback: function ($$v) {
                      _vm.multiSelect = $$v
                    },
                    expression: "multiSelect",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group row align-items-center" },
              [
                _c("label", { staticClass: "col-sm-2 mb-sm-0" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("search_and_select")) +
                      "\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("app-input", {
                  staticClass: "col-sm-8",
                  attrs: {
                    placeholder: _vm.$t("search_and_select"),
                    type: "search-select",
                    list: _vm.list,
                  },
                  model: {
                    value: _vm.inputs.smart_select,
                    callback: function ($$v) {
                      _vm.$set(_vm.inputs, "smart_select", $$v)
                    },
                    expression: "inputs.smart_select",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "mt-5 action-buttons" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary mr-2",
                  attrs: { type: "submit" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("submit")) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  attrs: { type: "submit" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.clear.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("clear")) +
                      "\n                "
                  ),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }