var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
    [
      _c("h5", { staticClass: "mb-3" }, [
        _vm._v(_vm._s(_vm.$t("profile_view"))),
      ]),
      _vm._v(" "),
      _c("app-table", {
        attrs: { id: "basic-profile-view-table", options: _vm.profileOption },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }