var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: _vm.galleryID } }, [
    _c(
      "div",
      { staticClass: "d-flex flex-wrap", staticStyle: { gap: "5px" } },
      [
        _vm._l(_vm.images, function (item, index) {
          return [
            _vm.showMoreImage || (!_vm.showMoreImage && index < 4) || _vm.grid
              ? _c(
                  "gallery-item",
                  {
                    key: `item-${_vm.galleryID}-${index}`,
                    attrs: {
                      item: item,
                      index: index,
                      type: _vm.getType(item),
                      grid: _vm.grid,
                    },
                  },
                  [
                    !_vm.grid
                      ? [
                          _vm.images.length > 4 &&
                          !_vm.showMoreImage &&
                          index === 3
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-center position-absolute w-100",
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "preview--btn",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          _vm.showMoreImage = !_vm.showMoreImage
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            +" +
                                          _vm._s(_vm.images.length - 4) +
                                          "\n                        "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }