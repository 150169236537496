var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-wrapper error-notes" },
    [
      _c("app-breadcrumb", {
        attrs: {
          "page-title": _vm.$t("notes"),
          directory: _vm.$t("ui_elements"),
          icon: "trello",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb-primary" },
        [
          _c("copy-button", {
            staticClass: "float-right",
            attrs: { id: "noteWarning", value: _vm.noteWarningValue },
          }),
          _vm._v(" "),
          _c("app-note", {
            staticClass: "pt-2 clearfix",
            attrs: {
              title: _vm.$t("note") + " " + _vm.$t("num_one"),
              "note-type": "note-warning",
              notes: [_vm.$t("error_note_warning")],
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb-primary" },
        [
          _c("copy-button", {
            staticClass: "float-right",
            attrs: { id: "noteBlue", value: _vm.noteBlueValue },
          }),
          _vm._v(" "),
          _c("app-note", {
            staticClass: "pt-2 clearfix",
            attrs: {
              title: _vm.$t("note") + " " + _vm.$t("num_two"),
              "content-type": "html",
              "note-type": "note-blue",
              notes: [_vm.$t("error_note_blue")],
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb-primary" },
        [
          _c("copy-button", {
            staticClass: "float-right",
            attrs: { id: "noteInfo", value: _vm.noteInfoValue },
          }),
          _vm._v(" "),
          _c("app-note", {
            staticClass: "pt-2 clearfix",
            attrs: {
              title: _vm.$t("note") + " " + _vm.$t("num_three"),
              "content-type": "html",
              "note-type": "note-info",
              notes: [_vm.$t("error_note_info")],
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb-primary" },
        [
          _c("copy-button", {
            staticClass: "float-right",
            attrs: { id: "noteSuccess", value: _vm.noteSuccessValue },
          }),
          _vm._v(" "),
          _c("app-note", {
            staticClass: "pt-2 clearfix",
            attrs: {
              title: _vm.$t("note") + " " + _vm.$t("num_four"),
              "content-type": "html",
              "note-type": "note-success",
              notes: [_vm.$t("error_note_success")],
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb-primary" },
        [
          _c("copy-button", {
            staticClass: "float-right",
            attrs: { id: "noteRed", value: _vm.noteRedValue },
          }),
          _vm._v(" "),
          _c("app-note", {
            staticClass: "pt-2 clearfix",
            attrs: {
              title: _vm.$t("note") + " " + _vm.$t("num_five"),
              "content-type": "html",
              "note-type": "note-red",
              notes: [_vm.$t("error_note_red")],
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("copy-button", {
            staticClass: "float-right",
            attrs: { id: "notePurple", value: _vm.notePurpleValue },
          }),
          _vm._v(" "),
          _c("app-note", {
            staticClass: "pt-2 clearfix",
            attrs: {
              title: _vm.$t("note") + " " + _vm.$t("num_six"),
              "content-type": "html",
              "note-type": "note-purple",
              notes: [_vm.$t("error_note_purple")],
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }