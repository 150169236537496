var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "vertical-tab d-flex flex-column",
      staticStyle: { "min-height": "100%" },
    },
    [
      _c("div", { staticClass: "row no-gutters flex-fill" }, [
        _c("div", { staticClass: "col-md-3 tab-menu" }, [
          _c(
            "div",
            {
              staticClass: "card border-left-0 border-bottom-0 border-top-0",
              staticStyle: { "border-color": "var(--default-border-color)" },
            },
            [
              _c("div", { staticClass: "px-primary py-primary" }, [
                _c(
                  "div",
                  {
                    staticClass: "nav flex-column nav-pills",
                    attrs: { role: "tablist", "aria-orientation": "vertical" },
                  },
                  _vm._l(_vm.tabs, function (item, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "text-muted text-uppercase font-weight-lighter --font-italic mt-2",
                          },
                          [_vm._v(_vm._s(item.label))]
                        ),
                        _vm._v(" "),
                        _vm._l(item.items, function (tab, index) {
                          return _c(
                            "a",
                            {
                              key: index,
                              staticClass:
                                "text-capitalize tab-item-link d-flex justify-content-between bold my-1 my-sm-2",
                              class: {
                                active:
                                  _vm.selectedtab?.component === tab.component,
                              },
                              attrs: {
                                id: "v-pills-" + tab.name + "-tab",
                                "data-toggle": "pill",
                                href: "#" + tab.name + "-" + index,
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.loadComponent(tab)
                                },
                              },
                            },
                            [
                              _c("span", [_vm._v(_vm._s(tab.name))]),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "active-icon d-flex align-items-center",
                                },
                                [
                                  _c("app-icon", {
                                    attrs: { name: "chevron-right" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    )
                  }),
                  0
                ),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-9 pt-md-0 pt-sm-4 pt-4" }, [
          _c("div", { staticClass: "card border-0 h-100" }, [
            _c("div", { staticClass: "tab-content px-primary" }, [
              _vm.selectedtab
                ? _c("div", { staticClass: "tab-pane fade active show" }, [
                    !_vm.selectedtab.headerHide
                      ? _c(
                          "div",
                          { staticClass: "d-flex justify-content-between" },
                          [
                            _c(
                              "h5",
                              {
                                staticClass:
                                  "d-flex align-items-center text-capitalize mb-0 title tab-content-header",
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.selectedtab.title)
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-row-reverse align-items-center mb-0",
                              },
                              [
                                _vm._l(
                                  _vm.componentButtons,
                                  function (button, index) {
                                    return [
                                      !_vm.isUndefined(button.label)
                                        ? _c(
                                            "button",
                                            {
                                              key: `components-btn-${index}-${_vm.componentId}`,
                                              staticClass: "mr-2",
                                              class: button.class
                                                ? button.class
                                                : "btn btn-primary",
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.headerBtnClicked(
                                                    button
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              button.icon
                                                ? _c("app-icon", {
                                                    staticClass: "size-20 mr-2",
                                                    attrs: {
                                                      name: button.icon,
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(button.label) +
                                                  "\n                                    "
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  }
                                ),
                              ],
                              2
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.selectedtab.headerHide ? _c("hr") : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "content py-primary",
                        staticStyle: { "min-height": "80dvh", height: "100%" },
                      },
                      [
                        _c(_vm.selectedtab.component, {
                          tag: "component",
                          attrs: {
                            props: _vm.componentProps,
                            id: _vm.selectedtab.component,
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }