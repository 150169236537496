var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "Chart" },
    [
      _c("chart-bubble", {
        attrs: {
          data: _vm.data,
          height: _vm.data.height,
          width: _vm.data.width,
          "dark-mode": _vm.darkMode,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }