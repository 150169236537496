var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "card card-with-shadow border-0 p-primary dashboard-widgets",
    },
    [
      _c("div", { staticClass: "widget-data" }, [
        _c("h6", [_vm._v(_vm._s(_vm.data.number))]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.data.label))]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }