var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "general-setting" },
    [
      _vm.preloader
        ? _c("app-overlay-loader")
        : _c(
            "form",
            {
              ref: "form",
              staticClass: "mb-0",
              class: { "loading-opacity": _vm.preloader },
              attrs: {
                "data-url": _vm.STORAGE_SETTING,
                enctype: "multipart/form-data",
              },
            },
            [
              _c("div", { staticClass: "form-group row align-items-center" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                    attrs: { for: "storageSetting" },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("storage_type")) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-lg-8 col-xl-8" },
                  [
                    _c("app-input", {
                      attrs: {
                        id: "storageSetting",
                        type: "select",
                        list: _vm.storageTypes,
                        "error-message": _vm.$errorMessage(
                          _vm.errors,
                          "storage_type"
                        ),
                        required: true,
                      },
                      model: {
                        value: _vm.formData.storage_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "storage_type", $$v)
                        },
                        expression: "formData.storage_type",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.formData.storage_type === "s3"
                ? _c("fieldset", [
                    _c(
                      "div",
                      { staticClass: "form-group row align-items-center" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                            attrs: { for: "aws_access_key" },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.$t("aws_access_key")) +
                                "\n                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-lg-8 col-xl-8" },
                          [
                            _c("app-input", {
                              attrs: {
                                id: "aws_access_key",
                                type: "text",
                                placeholder: _vm.$t("aws_access_key"),
                                "error-message": _vm.$errorMessage(
                                  _vm.errors,
                                  "aws_access_key"
                                ),
                              },
                              model: {
                                value: _vm.formData.aws_access_key,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "aws_access_key", $$v)
                                },
                                expression: "formData.aws_access_key",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group row align-items-center" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                            attrs: { for: "aws_secret_key" },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.$t("aws_secret_key")) +
                                "\n                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-lg-8 col-xl-8" },
                          [
                            _c("app-input", {
                              attrs: {
                                id: "aws_secret_key",
                                type: "text",
                                placeholder: _vm.$t("aws_secret_key"),
                                "error-message": _vm.$errorMessage(
                                  _vm.errors,
                                  "aws_secret_key"
                                ),
                              },
                              model: {
                                value: _vm.formData.aws_secret_key,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "aws_secret_key", $$v)
                                },
                                expression: "formData.aws_secret_key",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group row align-items-center" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                            attrs: { for: "aws_region" },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.$t("aws_region")) +
                                "\n                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-lg-8 col-xl-8" },
                          [
                            _c("app-input", {
                              attrs: {
                                id: "aws_region",
                                type: "text",
                                placeholder: _vm.$t("aws_region"),
                                "error-message": _vm.$errorMessage(
                                  _vm.errors,
                                  "aws_region"
                                ),
                              },
                              model: {
                                value: _vm.formData.aws_region,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "aws_region", $$v)
                                },
                                expression: "formData.aws_region",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group row align-items-center" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "col-lg-3 col-xl-3 mb-lg-0",
                            attrs: { for: "aws_region" },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.$t("aws_bucket_name")) +
                                "\n                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-lg-8 col-xl-8" },
                          [
                            _c("app-input", {
                              attrs: {
                                id: "aws_bucket",
                                type: "text",
                                placeholder: _vm.$t("aws_bucket_name"),
                                "error-message": _vm.$errorMessage(
                                  _vm.errors,
                                  "aws_bucket_name"
                                ),
                              },
                              model: {
                                value: _vm.formData.aws_bucket_name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "aws_bucket_name", $$v)
                                },
                                expression: "formData.aws_bucket_name",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "mt-5 action-buttons" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary mr-2",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.submit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("save")) +
                        "\n            "
                    ),
                  ]
                ),
              ]),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }