var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", { staticClass: "nav-item dropdown" }, [
    _c(
      "a",
      {
        staticClass:
          "d-flex align-items-center nav-link count-indicator dropdown-toggle",
        attrs: {
          href: "#",
          id: "notificationDropdown",
          "data-toggle": "dropdown",
        },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.randomDataGenerate.apply(null, arguments)
          },
        },
      },
      [
        _c("app-icon", { attrs: { name: "bell" } }),
        _vm._v(" "),
        _vm.showIdentifier
          ? _c("span", { staticClass: "count-symbol bg-primary spinner-grow" })
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _vm.data.length !== 0
      ? _c(
          "div",
          {
            staticClass:
              "dropdown-menu dropdown-menu-right navbar-dropdown notification-dropdown",
            attrs: { "aria-labelledby": "notificationDropdown" },
          },
          [
            _c("h6", { staticClass: "p-primary mb-0 primary-text-color" }, [
              _c("a", { attrs: { href: _vm.allNotificationUrl } }, [
                _vm._v(_vm._s(_vm.$t("all_notifications"))),
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass:
                    "badge badge-primary badge-sm badge-circle float-right",
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.data.length) +
                      "\n            "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "dropdown-divider" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "dropdown-items-wrapper custom-scrollbar" },
              _vm._l(_vm.data, function (item, index) {
                return _c(
                  "a",
                  {
                    key: index,
                    staticClass: "dropdown-item",
                    attrs: { href: item.url },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.$emit("clicked", item)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "media align-items-center" }, [
                      _c(
                        "div",
                        { staticClass: "avatars-w-50 mr-3" },
                        [
                          _c("app-avatar", {
                            attrs: {
                              shadow: false,
                              img: item.img,
                              title: item.name,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "media-body" }, [
                        _c("p", {
                          staticClass: "my-0 media-heading",
                          domProps: { innerHTML: _vm._s(item.title) },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "text-muted" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.textTruncate(item.description, 25)) +
                              "\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "primary-text-color link" }, [
                          _c("span", { staticClass: "mr-3" }, [
                            _vm._v(_vm._s(item.date)),
                          ]),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(item.time))]),
                        ]),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]
        )
      : _c(
          "div",
          {
            staticClass:
              "dropdown-menu dropdown-menu-right navbar-dropdown notification-dropdown no-notification-dropdown p-primary",
            attrs: { "aria-labelledby": "notificationDropdown" },
          },
          [
            _c("div", { staticClass: "d-flex justify-content-center" }, [
              _c("img", {
                staticClass: "no-notification-img",
                attrs: { src: _vm.noNotificationImg, alt: "" },
              }),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-center font-size-80 m-0 pt-2 pb-0" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.noNotificationTitle) +
                  "\n        "
              ),
            ]),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }