var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("sidebar", {
    attrs: {
      data: _vm.data,
      "logo-url": _vm.urlGenerator("/"),
      "logo-src": _vm.urlGenerator(_vm.logo),
      "logo-icon-src": _vm.urlGenerator(_vm.logoIcon),
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }