var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "progress-bar-wrapper" }, [
    _vm.type === "horizontal"
      ? _c(
          "div",
          { class: `progress height-15 radius-20 ${_vm.parentClass}` },
          [
            _c(
              "div",
              {
                class: `progress-bar ${_vm.bgClass}`,
                style: `width: ${_vm.percentage}%`,
                attrs: { role: "progressbar" },
              },
              [
                _vm._v(
                  "\n        \t\t" +
                    _vm._s(_vm.showPercentage ? `${_vm.percentage}%` : "") +
                    "\n        \t"
                ),
              ]
            ),
          ]
        )
      : _vm.type === "vertical"
      ? _c(
          "div",
          { class: `vertical-progress-bar-wrapper width-1 ${_vm.parentClass}` },
          [
            _c(
              "div",
              {
                class: `progress-bar ${_vm.bgClass}`,
                style: `height: ${_vm.percentage}%`,
                attrs: { role: "progressbar" },
              },
              [
                _c("span", { staticClass: "percentage" }, [
                  _vm._v(
                    "\n        \t\t\t" +
                      _vm._s(_vm.showPercentage ? `${_vm.percentage}%` : "") +
                      "\n\t\t\t\t"
                  ),
                ]),
              ]
            ),
          ]
        )
      : _c("p", { staticClass: "text-danger" }, [_vm._v("Invalid type")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }