var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "card card-body rounded-corner",
      class: _vm.editData?.id ? "p-0 border-0" : "",
    },
    [
      _c(
        "form",
        {
          ref: "form",
          staticClass: "d-flex flex-column py-2 gap-y-1",
          attrs: { method: "POST", "data-url": "" },
        },
        [
          _c("vue-editor", {
            attrs: {
              id: `post-${_vm.editData?.id}` || "new-post",
              editorToolbar: [],
              placeholder: _vm.$t("share_ideas"),
              disabled: _vm.loadingData,
            },
            on: { "text-change": _vm.typeChange },
            model: {
              value: _vm.formData.body,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "body", $$v)
              },
              expression: "formData.body",
            },
          }),
          _vm._v(" "),
          !!_vm.formData.images.length
            ? _c("div", [
                _c("hr", { staticClass: "post-separator" }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "d-flex flex-wrap",
                    staticStyle: { gap: "5px" },
                  },
                  [
                    _vm._l(_vm.formData.images, function (image, index) {
                      return [
                        _vm.showAllImages || (!_vm.showAllImages && index < 4)
                          ? _c(
                              "div",
                              {
                                key: `image-item-${index}`,
                                staticClass:
                                  "preview d-inline-block align-items-center d-flex position-relative",
                                class: { "preview--sm": index !== 0 },
                              },
                              [
                                _c("div", {
                                  staticClass: "w-100 h-100",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.getImageContent(image)
                                    ),
                                  },
                                }),
                                _vm._v(" "),
                                _vm.formData.images.length > 4 &&
                                !_vm.showAllImages &&
                                index === 3
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-center position-absolute w-100",
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "preview--btn",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                _vm.showAllImages =
                                                  !_vm.showAllImages
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "+" +
                                                _vm._s(
                                                  _vm.formData.images.length - 4
                                                )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "fab fab-red fab-transparent fab-2 position-absolute",
                                    staticStyle: {
                                      right: "10px",
                                      top: "10px",
                                      "z-index": "100",
                                    },
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeImage(index, image)
                                      },
                                    },
                                  },
                                  [
                                    _c("app-icon", {
                                      attrs: { name: "x", size: 16 },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.previews.length > 0
            ? _c("div", [
                _c("hr", { staticClass: "post-separator" }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "d-flex flex-wrap",
                    staticStyle: { gap: "5px" },
                  },
                  [
                    _vm._l(_vm.previews, function (preview, index) {
                      return [
                        _vm.showAllPreviews ||
                        (!_vm.showAllPreviews && index < 1)
                          ? _c(
                              "div",
                              {
                                key: `preview-item-${index}`,
                                staticClass:
                                  "d-inline-block align-items-center d-flex position-relative",
                                class:
                                  index === 0
                                    ? "preview"
                                    : "preview preview--sm",
                              },
                              [
                                _c("div", {
                                  staticClass: "w-100 h-100",
                                  domProps: { innerHTML: _vm._s(preview) },
                                }),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "fab fab-red fab-transparent fab-2 position-absolute",
                                    staticStyle: {
                                      right: "10px",
                                      top: "10px",
                                      "z-index": "100",
                                    },
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removePreviews(
                                          index,
                                          preview
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("app-icon", {
                                      attrs: { name: "x", size: 16 },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.previews.length > 1
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-link text-primary p-0 pt-3",
                        attrs: { type: "button" },
                      },
                      [
                        _c(
                          "span",
                          {
                            key: "show_preview_button_" + _vm.showAllPreviews,
                            staticClass: "d-flex align-items-center gap-x-1",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                _vm.showAllPreviews = !_vm.showAllPreviews
                              },
                            },
                          },
                          [
                            _c("app-icon", {
                              key: "show_preview_button_" + _vm.showAllPreviews,
                              attrs: {
                                name: _vm.showAllPreviews ? "eye-off" : "eye",
                                size: 18,
                              },
                            }),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.showAllPreviews
                                    ? _vm.$t("hide")
                                    : _vm.$t("show_all_previews")
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          !!_vm.formData.documents.length
            ? _c("div", [
                _c("hr", { staticClass: "post-separator" }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "d-flex flex-wrap align-items-center",
                    staticStyle: { gap: "5px" },
                  },
                  [
                    _vm._l(_vm.formData.documents, function (document, index) {
                      return [
                        _vm.showMoreDocument ||
                        (!_vm.showMoreDocument && index < 4)
                          ? _c(
                              "div",
                              {
                                key: `document-item-${index}`,
                                staticClass:
                                  "d-flex align-items-center badge badge-pill gap-x-1 text-primary badge-document",
                              },
                              [
                                _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.getDocumentContent(document)
                                    ),
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "fab fab-red fab-transparent fab-2",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeDocument(
                                          index,
                                          document
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("app-icon", {
                                      attrs: { name: "x", size: 16 },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.formData.documents.length > 4 &&
                        !_vm.showMoreDocument &&
                        index === 3
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-link text-primary",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    _vm.showMoreDocument = !_vm.showMoreDocument
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "+" +
                                    _vm._s(_vm.formData.documents.length - 4) +
                                    " more"
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.showPollLayout
            ? _c("div", [
                _c("hr", { staticClass: "post-separator" }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex flex-column gap-y-1 mb-3" },
                  [
                    _vm.formData.poll?.options?.length
                      ? _c("small", { staticClass: "text-muted" }, [
                          _vm._v(_vm._s(_vm.$t("poll_options"))),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.formData.poll?.options, function (poll, index) {
                      return _c(
                        "div",
                        {
                          key: `poll-options-${index}`,
                          staticClass: "d-flex gap-x-1",
                        },
                        [
                          _c("div", { staticClass: "flex-fill" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: poll.body,
                                  expression: "poll.body",
                                },
                              ],
                              ref: "poll_option_" + index,
                              refInFor: true,
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                placeholder: _vm.$t("poll_option"),
                              },
                              domProps: { value: poll.body },
                              on: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.addOption.apply(null, arguments)
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(poll, "body", $event.target.value)
                                },
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn p-0 btn-clear text-danger",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.removePollOption(index)
                                },
                              },
                            },
                            [
                              _c("app-icon", {
                                attrs: { name: "x", size: 16 },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex gap-x-2 align-items-center" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn text-primary px-0 btn-sm",
                        staticStyle: { "box-shadow": "none !important" },
                        attrs: { type: "button" },
                        on: { click: _vm.addOption },
                      },
                      [
                        _c("app-icon", { attrs: { name: "plus", size: 16 } }),
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("add_option")) +
                            "\n                "
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-center gap-x-1" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formData.poll.multiple_answerable,
                              expression: "formData.poll.multiple_answerable",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.formData.poll.multiple_answerable
                            )
                              ? _vm._i(
                                  _vm.formData.poll.multiple_answerable,
                                  null
                                ) > -1
                              : _vm.formData.poll.multiple_answerable,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.formData.poll.multiple_answerable,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.formData.poll,
                                      "multiple_answerable",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.formData.poll,
                                      "multiple_answerable",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.formData.poll,
                                  "multiple_answerable",
                                  $$c
                                )
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", {}, [
                          _vm._v(_vm._s(_vm.$t("multiple_answerable")) + " "),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-center gap-x-1" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formData.poll.is_addable,
                              expression: "formData.poll.is_addable",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.formData.poll.is_addable)
                              ? _vm._i(_vm.formData.poll.is_addable, null) > -1
                              : _vm.formData.poll.is_addable,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.formData.poll.is_addable,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.formData.poll,
                                      "is_addable",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.formData.poll,
                                      "is_addable",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.formData.poll, "is_addable", $$c)
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", {}, [
                          _vm._v(_vm._s(_vm.$t("option_addable")) + " "),
                        ]),
                      ]
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("hr", { staticClass: "post-separator" }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "d-flex align-items-center justify-content-between gap-x-3",
            },
            [
              _c(
                "div",
                { staticClass: "d-flex gap-x-2 align-items-center flex-fill" },
                [
                  _c("emoji-picker", {
                    attrs: { search: _vm.search },
                    on: { emoji: _vm.insert },
                    scopedSlots: _vm._u([
                      {
                        key: "emoji-invoker",
                        fn: function ({ events: { click: clickEvent } }) {
                          return _c(
                            "div",
                            {
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return clickEvent.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-link text-primary p-0",
                                  attrs: {
                                    type: "button",
                                    disabled: _vm.loadingData,
                                    title: "Emoji",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-flex align-items-center gap-x-1",
                                    },
                                    [
                                      _c("app-icon", {
                                        attrs: { name: "smile", size: 18 },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          )
                        },
                      },
                      {
                        key: "emoji-picker",
                        fn: function ({ emojis, insert }) {
                          return _c(
                            "div",
                            {
                              staticClass:
                                "position-absolute card card-body p-2",
                              staticStyle: { width: "260px", "z-index": "100" },
                            },
                            [
                              _c("div", [
                                _c("div", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.search,
                                        expression: "search",
                                      },
                                    ],
                                    staticClass: "form-control-sm w-100",
                                    attrs: { type: "text" },
                                    domProps: { value: _vm.search },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.search = $event.target.value
                                      },
                                    },
                                  }),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "custom-scrollbar",
                                    staticStyle: {
                                      height: "220px",
                                      "overflow-y": "scroll",
                                    },
                                  },
                                  _vm._l(
                                    emojis,
                                    function (emojiGroup, category) {
                                      return _c("div", { key: category }, [
                                        _c("small", [_vm._v(_vm._s(category))]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          _vm._l(
                                            emojiGroup,
                                            function (emoji, emojiName) {
                                              return _c(
                                                "span",
                                                {
                                                  key: emojiName,
                                                  staticClass: "cursor-pointer",
                                                  staticStyle: {
                                                    "font-size": "20px",
                                                  },
                                                  attrs: { title: emojiName },
                                                  on: {
                                                    click: function ($event) {
                                                      return insert(emoji)
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(emoji) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ]
                          )
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-link text-primary p-0",
                      attrs: {
                        type: "button",
                        title: `File type: ${_vm.imagesType.toString()}, File size: 1MB`,
                        disabled: _vm.loadingData,
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.addImage(_vm.imagesType)
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "d-flex align-items-center gap-x-1" },
                        [
                          _c("app-icon", {
                            attrs: { name: "image", size: 18 },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-link text-primary p-0",
                      attrs: {
                        type: "button",
                        title: `File type: ${_vm.documentsType.toString()}, File size: 1MB`,
                        disabled: _vm.loadingData,
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.addDocument(_vm.documentsType)
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "d-flex align-items-center gap-x-1" },
                        [
                          _c("app-icon", {
                            attrs: { name: "file-plus", size: 18 },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm px-3 rounded-pill",
                      class: {
                        active: _vm.showPollLayout,
                        "btn-outline-danger": _vm.showPollLayout,
                        "btn-outline-primary": !_vm.showPollLayout,
                      },
                      attrs: { type: "button", disabled: _vm.loadingData },
                      on: { click: _vm.pollLayoutTrigger },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "d-flex align-items-center gap-x-1" },
                        [
                          _c("app-icon", { attrs: { name: "list", size: 18 } }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.showPollLayout
                                  ? _vm.$t("cancel_poll")
                                  : _vm.$t("create_poll")
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formData.shared_with,
                        expression: "formData.shared_with",
                      },
                    ],
                    staticClass: "form-control",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.formData,
                          "shared_with",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "public", selected: "" } }, [
                      _vm._v(_vm._s(_vm.$t("public"))),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "team" } }, [
                      _vm._v(_vm._s(_vm.$t("team"))),
                    ]),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm.formData.shared_with === "team"
            ? _c(
                "div",
                [
                  _c("app-input", {
                    attrs: {
                      type: "multi-select",
                      list: _vm.teams,
                      disabled: _vm.loadingData,
                      "list-value-field": "name",
                    },
                    model: {
                      value: _vm.formData.teams,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "teams", $$v)
                      },
                      expression: "formData.teams",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isFocus
            ? _c("div", [
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-primary shadow-none btn-block rounded-pill",
                    staticStyle: { "box-shadow": "none !important" },
                    attrs: { type: "button", disabled: _vm.loadingData },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.submit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("app-icon", {
                      staticClass: "mr-2",
                      attrs: { name: "send", size: 14 },
                    }),
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.editData?.id ? _vm.$t("update") : _vm.$t("post")
                        ) +
                        "\n            "
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }