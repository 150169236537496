var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      attrs: { action: "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submitComment.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "d-flex align-items-center gap-x-1" }, [
        _c("div", { staticClass: "form-group w-100 m-0" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.formData.message,
                expression: "formData.message",
              },
            ],
            staticClass: "form-control form-control-rounded",
            attrs: {
              type: "text",
              name: "content",
              maxlength: "500",
              autocomplete: "off",
              placeholder: _vm.$t("comment"),
              required: "",
            },
            domProps: { value: _vm.formData.message },
            on: {
              focus: function ($event) {
                _vm.focused = true
              },
              blur: function ($event) {
                _vm.focused = false
              },
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.formData, "message", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass:
              "btn btn-primary rounded-circle p-0 d-flex justify-content-center align-items-center",
            staticStyle: { width: "50px", height: "44px" },
            attrs: {
              type: "submit",
              disabled: !_vm.formData.message || _vm.submitting,
            },
          },
          [
            _vm.submitting
              ? _c("app-pre-loader", {
                  attrs: { spinnerClass: "spinner-border-sm" },
                })
              : _c("span", [_c("app-icon", { attrs: { name: "send" } })], 1),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.focused
        ? _c("div", { staticClass: "px-3 text-right text-muted" }, [
            _c("small", [
              _vm._v(
                "Total " + _vm._s(this.formData.message.length) + " out of 500"
              ),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }