var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade custom-scrollbar",
      class: [_vm.modalSize === "fullscreen" ? "border-0 p-0 m-0" : ""],
      style: "cursor: url(" + _vm.cursorImage() + "), auto !important",
      attrs: {
        "data-backdrop": _vm.modalBackdrop ? _vm.modalBackdrop : "static",
        "data-keyboard": `${_vm.modalBackdrop}`,
        id: _vm.modalId,
        tabindex: "-1",
        role: "dialog",
        "aria-hidden": "true",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog",
          class: [
            _vm.modalAlignment === "top"
              ? "modal-dialog-top"
              : "modal-dialog-centered",
            _vm.checkModalSize,
            { "modal-dialog-scrollable": _vm.modalScroll },
          ],
          attrs: { role: "document" },
        },
        [
          _c(
            "div",
            { staticClass: "modal-content" },
            [
              _vm.$slots.header
                ? _c(
                    "div",
                    {
                      key: _vm.modalId + "header",
                      staticClass: "modal-header",
                    },
                    [_vm._t("header")],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$slots.body
                ? _c(
                    "div",
                    {
                      key: _vm.modalId + "body",
                      staticClass: "modal-body custom-scrollbar",
                      class: _vm.modalBodyClass,
                    },
                    [_vm._t("body")],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$slots.footer
                ? _c(
                    "div",
                    {
                      key: _vm.modalId + "footer",
                      staticClass: "modal-footer",
                    },
                    [_vm._t("footer")],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._t("default"),
            ],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }