var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        "modal-id": _vm.modalId,
        title: _vm.generateModalTitle("payment_method"),
        preloader: _vm.preloader,
      },
      on: { submit: _vm.submit, "close-modal": _vm.closeModal },
    },
    [
      _c(
        "template",
        { slot: "body" },
        [
          _vm.preloader ? _c("app-overlay-loader") : _vm._e(),
          _vm._v(" "),
          _c(
            "form",
            {
              ref: "form",
              staticClass: "mb-0",
              class: { "loading-opacity": _vm.preloader },
              attrs: {
                "data-url": _vm.selectedUrl ? _vm.selectedUrl : _vm.url,
              },
            },
            [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", [_vm._v(_vm._s(_vm.$t("name")))]),
                  _vm._v(" "),
                  _c("app-input", {
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t("name"),
                      required: true,
                    },
                    model: {
                      value: _vm.formData.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "name", $$v)
                      },
                      expression: "formData.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", [_vm._v(_vm._s(_vm.$t("type")))]),
                  _vm._v(" "),
                  _c("app-input", {
                    attrs: {
                      type: "select",
                      list: _vm.paymentType,
                      required: true,
                    },
                    model: {
                      value: _vm.formData.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "type", $$v)
                      },
                      expression: "formData.type",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.selectedUrl
                ? _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", [_vm._v(_vm._s(_vm.$t("payment_status")))]),
                      _vm._v(" "),
                      _c("app-input", {
                        attrs: {
                          type: "radio",
                          "radio-checkbox-name": "for-status",
                          "list-value-field": "translated_name",
                          list: _vm.statusList,
                        },
                        model: {
                          value: _vm.formData.status_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "status_id", $$v)
                          },
                          expression: "formData.status_id",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.selectedType
                ? [
                    _vm.formData.type === "stripe"
                      ? _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", [_vm._v(_vm._s(_vm.$t("public_key")))]),
                            _vm._v(" "),
                            _c("app-input", {
                              attrs: {
                                type: "text",
                                placeholder: _vm.$t("public_key"),
                                required: true,
                              },
                              model: {
                                value: _vm.formData.public_key,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "public_key", $$v)
                                },
                                expression: "formData.public_key",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm.formData.type === "razorpay"
                      ? _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", [_vm._v(_vm._s(_vm.$t("key_id")))]),
                            _vm._v(" "),
                            _c("app-input", {
                              attrs: {
                                type: "text",
                                placeholder: _vm.$t("key_id"),
                                required: true,
                              },
                              model: {
                                value: _vm.formData.key_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "key_id", $$v)
                                },
                                expression: "formData.key_id",
                              },
                            }),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", [_vm._v(_vm._s(_vm.$t("client_id")))]),
                            _vm._v(" "),
                            _c("app-input", {
                              attrs: {
                                type: "text",
                                placeholder: _vm.$t("client_id"),
                                required: true,
                              },
                              model: {
                                value: _vm.formData.client_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "client_id", $$v)
                                },
                                expression: "formData.client_id",
                              },
                            }),
                          ],
                          1
                        ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", [_vm._v(_vm._s(_vm.$t("secret_key")))]),
                        _vm._v(" "),
                        _c("app-input", {
                          attrs: {
                            type: "password",
                            placeholder: _vm.$t("secret_key"),
                            required: true,
                          },
                          model: {
                            value: _vm.formData.secret_key,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "secret_key", $$v)
                            },
                            expression: "formData.secret_key",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("app-input", {
                          attrs: {
                            type: "single-checkbox",
                            "list-value-field": _vm.$t("mark_as_default"),
                          },
                          model: {
                            value: _vm.formData.is_default,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "is_default", $$v)
                            },
                            expression: "formData.is_default",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }