var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card card-with-shadow border-0" },
    [
      _c("div", { staticClass: "card-header p-primary bg-transparent" }, [
        _c("h5", { staticClass: "card-title m-0" }, [
          _vm._v(_vm._s(_vm.$t("validation_type"))),
        ]),
      ]),
      _vm._v(" "),
      _vm.preloader ? _c("app-overlay-loader") : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c(
          "form",
          {
            ref: "form",
            staticClass: "mb-sm-0",
            class: { "loading-opacity": _vm.preloader },
            attrs: { "data-url": "/form/validate-fields" },
          },
          [
            _c("div", { staticClass: "form-group row align-items-center" }, [
              _c("label", { staticClass: "col-md-2 mb-md-0" }, [
                _vm._v(_vm._s(_vm.$t("required"))),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-8" },
                [
                  _c("app-input", {
                    attrs: {
                      type: "text",
                      required: true,
                      placeholder: _vm.$t("type_something"),
                    },
                    model: {
                      value: _vm.inputs.text,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "text", $$v)
                      },
                      expression: "inputs.text",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group row align-items-center" }, [
              _c("label", { staticClass: "col-md-2 mb-md-0" }, [
                _vm._v(_vm._s(_vm.$t("email"))),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-8" },
                [
                  _c("app-input", {
                    attrs: {
                      type: "email",
                      required: true,
                      placeholder: _vm.$t("type_valid_email"),
                    },
                    model: {
                      value: _vm.inputs.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "email", $$v)
                      },
                      expression: "inputs.email",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group row align-items-center" }, [
              _c("label", { staticClass: "col-md-2 mb-md-0" }, [
                _vm._v(_vm._s(_vm.$t("number_input"))),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-8" },
                [
                  _c("app-input", {
                    attrs: {
                      type: "number",
                      placeholder: _vm.$t("number_type_input"),
                    },
                    model: {
                      value: _vm.inputs.number,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "number", $$v)
                      },
                      expression: "inputs.number",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-md-2 mb-md-0" }, [
                _vm._v(_vm._s(_vm.$t("input_matching"))),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-8" },
                [
                  _c("app-input", {
                    staticClass: "mb-2",
                    attrs: {
                      type: "password",
                      required: true,
                      specialValidation: true,
                      placeholder: _vm.$t("type_password"),
                    },
                    model: {
                      value: _vm.inputs.password_input,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "password_input", $$v)
                      },
                      expression: "inputs.password_input",
                    },
                  }),
                  _vm._v(" "),
                  _c("app-note", {
                    staticClass: "mb-2",
                    attrs: {
                      "note-type": "note-warning",
                      "show-title": false,
                      notes: [_vm.$t("password_hint_note")],
                    },
                  }),
                  _vm._v(" "),
                  _c("app-input", {
                    attrs: {
                      type: "password",
                      "same-as": "inputs_password_input",
                      placeholder: _vm.$t("confirm_password"),
                    },
                    model: {
                      value: _vm.inputs.confirm_password,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "confirm_password", $$v)
                      },
                      expression: "inputs.confirm_password",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group row align-items-center" }, [
              _c("label", { staticClass: "col-md-2 mb-md-0" }, [
                _vm._v(_vm._s(_vm.$t("datepicker"))),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-8" },
                [
                  _c("app-input", {
                    attrs: {
                      type: "date",
                      placeholder: _vm.$t("select_valid_date"),
                    },
                    model: {
                      value: _vm.inputs.date,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "date", $$v)
                      },
                      expression: "inputs.date",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group row align-items-center" }, [
              _c("label", { staticClass: "col-md-2 mb-md-0" }, [
                _vm._v(_vm._s(_vm.$t("timepicker"))),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-8" },
                [
                  _c("app-input", {
                    attrs: {
                      type: "time",
                      placeholder: _vm.$t("select_valid_time"),
                    },
                    model: {
                      value: _vm.inputs.time,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "time", $$v)
                      },
                      expression: "inputs.time",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group row align-items-center" }, [
              _c("label", { staticClass: "col-md-2 mb-md-0" }, [
                _vm._v(_vm._s(_vm.$t("textarea"))),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-8" },
                [
                  _c("app-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.inputs.text_area,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputs, "text_area", $$v)
                      },
                      expression: "inputs.text_area",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mt-5 action-buttons" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary mr-2",
                  attrs: { type: "submit" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.submitData.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("submit")) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  attrs: { type: "submit" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.clear.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("clear")) +
                      "\n                "
                  ),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }